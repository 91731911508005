import { CdkDrag } from "@angular/cdk/drag-drop";
import { ChangeDetectionStrategy, Component, EventEmitter, Input, Output } from "@angular/core";

@Component({
    selector: 'app-drag-drop',
    templateUrl: './drag-drop.component.html',
    styleUrls: ["./drag-drop.component.scss"],
    changeDetection: ChangeDetectionStrategy.OnPush
})
export class DragDropComponent {
    @Input() name: string;
    @Input() position: number;
    @Input() items;
    @Input() itemName;
    @Input() isComplementValue: number;
    @Output() cdkDropListDropped = new EventEmitter<any>();
    @Output() cdkDropListDroppedType = new EventEmitter<any>();
    @Output() delete = new EventEmitter<any>();


    drop($event, type, position) {
        let body = {
            type,
            $event,
            position
        };
        
        this.cdkDropListDropped.emit($event);
        this.cdkDropListDroppedType.emit(body);
    }

    isComplement(item: CdkDrag<any>) {
        return item.data.type == 1;
    }

    isMeal(item: CdkDrag<any>) {
        return item.data.type != 1;
    }

    deleteElement(type, event, position) {
        let body = {
            type,
            event,
            position
        };

        this.delete.emit(body);
    }
}