<nav class="flex" aria-label="Breadcrumb" [ngClass]="{ 'pt-5': menuItems.length == 0 , 'pt-2.5':
    menuItems.length> 0}">
    <ol class="bg-white rounded-md px-6 flex space-x-4">
        <li class="flex">
            <div class="flex items-center">
                <a href="#" class="text-gray-400 hover:text-gray-500">
                    <svg class="w-6 h-6" fill="none" stroke="currentColor" viewBox="0 0 24 24"
                        xmlns="http://www.w3.org/2000/svg">
                        <path stroke-linecap="round" stroke-linejoin="round" stroke-width="2"
                            d="M3 12l2-2m0 0l7-7 7 7M5 10v10a1 1 0 001 1h3m10-11l2 2m-2-2v10a1 1 0
                            01-1 1h-3m-6 0a1 1 0 001-1v-4a1 1 0 011-1h2a1 1 0 011 1v4a1 1 0 001 1m-6
                            0h6">
                        </path>
                    </svg>
                </a>
                <span class="sr-only">
                    {{ 'breadcumb.home' | translate }}
                </span>
            </div>
        </li>
        <li class="flex" *ngFor="let bread of menuItems">
            <div class="flex items-center space-x-4">
                <svg class="flex-shrink-0 w-6 h-full text-gray-200" viewBox="0 0 24 44"
                    preserveAspectRatio="none"
                    fill="currentColor" xmlns="http://www.w3.org/2000/svg" aria-hidden="true">
                    <path d="M.293 0l22 22-22 22h1.414l22-22-22-22H.293z" />
                    </svg>
                    <a href="#"
                        class="text-sm leading-5 font-medium text-gray-500 hover:text-gray-700
                        transition duration-150 ease-in-out">{{bread.name}}</a>
                </div>
            </li>

        </ol>
    </nav>