import { HttpClientModule, HttpClient } from '@angular/common/http';
import { NgModule } from '@angular/core';
import { CommonModule } from '@angular/common';
import { FormsModule, ReactiveFormsModule } from '@angular/forms';

import { TranslateModule, TranslateLoader } from '@ngx-translate/core';
import { TranslateHttpLoader } from '@ngx-translate/http-loader';
import { ModalInfoComponent } from '../components/modals/info/modal-info.component';
import { ErrorFormComponent } from '../components/form/error/error.component';
import { LabelFormComponent } from '../components/form/label/label.component';
import { NgxPermissionsModule } from 'ngx-permissions';

import { SnackbarComponent } from '../components/snackbar/snackbar.component';
import { HighlightDirective } from '../directives/highlight.directive';
import { TabsComponent } from '../components/tabs/tabs.component';
import { TabComponent } from '../components/tab/tab.component';
import { CalendarModule, DateAdapter } from 'angular-calendar';
import { adapterFactory } from 'angular-calendar/date-adapters/date-fns';
import { BreadcrumbComponent } from '../components/breadcrumb/breadcrumb.component';
import { LoadingComponent } from '../components/loading/loading.component';
import { NgxEchartsModule } from 'ngx-echarts';
import { DragDropModule } from '@angular/cdk/drag-drop';
import { DragDropComponent } from '../components/drag-drop/drag-drop.component';
import { ModalInfoOptionsComponent } from '../components/modals/info-options/modal-info-options.component';
import { CustomerGenericComponent } from '../components/customer-form/customer.component';
import { Ng2FlatpickrModule } from 'ng2-flatpickr';
import { GooglePlaceModule } from 'ngx-google-places-autocomplete';
import { CustomerChatComponent } from '../components/customer-chat/customer-chat.component';
import { FilterComponent } from '../components/filter/filter.component';
import { KbdComponent } from '../components/form/kbd/kbd.component';
import { IngredientComponent } from 'src/app/dashboard/admin/data/ingredient/ingredient.component';
import { IngredientFormComponent } from 'src/app/dashboard/admin/data/ingredient/form/ingredient-form.component';

export function createTranslateLoader(http: HttpClient) {
    return new TranslateHttpLoader(http, './assets/i18n/', '.json');
}

@NgModule({
    declarations: [
        ModalInfoComponent,
        ModalInfoOptionsComponent,
        ErrorFormComponent,
        LabelFormComponent,
        SnackbarComponent,
        HighlightDirective,
        TabsComponent,
        TabComponent,
        BreadcrumbComponent,
        LoadingComponent,
        DragDropComponent,
        FilterComponent,
        CustomerGenericComponent,
        CustomerChatComponent,
        KbdComponent,
        IngredientComponent,
        IngredientFormComponent
    ],
    imports: [
        CommonModule,
        FormsModule,
        ReactiveFormsModule,
        HttpClientModule,
        TranslateModule,
        NgxPermissionsModule.forRoot(),
        CalendarModule.forRoot({ provide: DateAdapter, useFactory: adapterFactory }),
        NgxEchartsModule.forRoot({
            echarts: () => import('echarts')
        }),
        DragDropModule,
        Ng2FlatpickrModule,
        GooglePlaceModule
    ],
    exports: [
        CommonModule,
        FormsModule,
        ReactiveFormsModule,
        TranslateModule,
        NgxPermissionsModule,
        ErrorFormComponent,
        LabelFormComponent,
        SnackbarComponent,
        HighlightDirective,
        TabsComponent,
        TabComponent,
        CalendarModule,
        BreadcrumbComponent,
        LoadingComponent,
        NgxEchartsModule,
        DragDropModule,
        DragDropComponent,
        FilterComponent,
        CustomerGenericComponent,
        Ng2FlatpickrModule,
        GooglePlaceModule,
        CustomerChatComponent,
        KbdComponent,
        IngredientComponent,
        IngredientFormComponent
    ],
    entryComponents: [],
    providers: []
})
export class SharedModule {
    /**/
}
