import { ProviderAst } from "@angular/compiler";
import { Component, OnInit, OnDestroy, Input } from "@angular/core";
import { FormBuilder, Validators } from "@angular/forms";
import { FormGroup } from "@angular/forms";
import Spanish from 'flatpickr/dist/l10n/es.js';
import * as moment from "moment";
import { FlatpickrOptions } from "ng2-flatpickr";
import { Subscription } from "rxjs";
import { SnackbarService } from "src/app/shared/components/snackbar/snackbar.service";
import { Block } from "src/app/shared/interfaces/block.interface";
import { AppointmentService } from "src/app/shared/services/appointment.service";
import { AuthenticationGeneralService } from "src/app/shared/services/auth-general.service";
import { SettingGeneralService } from "src/app/shared/services/settings-general.service";
import { UtilsService } from "src/app/shared/services/util.service";
import { BlockService } from "./block.service";

@Component({
    selector: 'app-block',
    templateUrl: './block-modal.component.html',
})

export class BlockComponent implements OnInit, OnDestroy {

    @Input() appointmentDay;
    @Input() text_button_create: string;

    blockForm: FormGroup;
    submitted = false;
    dateOptions: FlatpickrOptions;
    dateOptionsTime: FlatpickrOptions;


    periodValues = [];
    subscription: Subscription = new Subscription;

    selected: boolean = true;
    finish_date_error: boolean = true;
    settingsValue;

    constructor(
        private builder: FormBuilder,
        private settingGeneralService: SettingGeneralService,
        private utilService: UtilsService,
        private snackbarService: SnackbarService,
        private blockService: BlockService,
        private userService: AuthenticationGeneralService
    ) {
        this.periodValues.push({ id: 1, text: this.settingGeneralService.getLangText('block.period.not_repeat') });
        this.periodValues.push({ id: 2, text: this.settingGeneralService.getLangText('block.period.daily') });
        this.periodValues.push({ id: 3, text: this.settingGeneralService.getLangText('block.period.weekly') });

        this.settingGeneralService.changeCountry$.subscribe(
            () => {
                this.settingsValue = this.settingGeneralService.getSettings();
            }
        );

        this.dateOptions = {
            locale: this.settingsValue.flatpickr,
            dateFormat: this.settingsValue.formatFlatpickr,
            disableMobile: true,
            minuteIncrement: 20
        };

        this.dateOptionsTime = {
            locale: this.settingsValue.flatpickr,
            dateFormat: 'H:i',
            enableTime: true,
            noCalendar: true,
            minuteIncrement: 20
        };
    }

    ngOnInit(): void {
        let day = moment().format('DD-MM-YYYY');
        if (this.appointmentDay) {
            day = moment(this.appointmentDay).format('DD-MM-YYYY');
        }

        this.dateOptions.defaultDate = day;

        this.blockForm = this.builder.group({
            user_id: [this.userService.getUserVariable('id')],
            start_date: [{ 0: day }, [Validators.required]],
            finish_date: [{ 0: day }, [Validators.required]],
            allDay: [this.selected],
            start_hour: [null],
            finish_hour: [null],
            period: [{ value: 1, disabled: false }, [Validators.required]]
        });
        
    }

    changeSelected() {
        this.selected = !this.selected;

        this.blockForm.patchValue({ allDay: this.selected });
        if (!this.selected) {
            this.blockForm.get('period').setValidators([Validators.required]);
            this.blockForm.get('start_hour').setValidators([Validators.required]);
            this.blockForm.get('finish_hour').setValidators([Validators.required]);
        } else {
            this.blockForm.get('period').clearValidators();
            this.blockForm.get('start_hour').clearValidators();
            this.blockForm.get('finish_hour').clearValidators();
        }
    }

    changeDay() {
        const start = moment((this.blockForm.get('start_date').value)[0], 'DD-MM-YYYY').format('YYYY-MM-DD');
        const finish = moment((this.blockForm.get('finish_date').value)[0], 'DD-MM-YYYY').format('YYYY-MM-DD');

        if (start != finish) {
            this.blockForm.get('period').setValue(1);
            this.blockForm.get('period').disable();
        } else {
            this.blockForm.get('period').enable();
        }
    }

    get f() { return this.blockForm.controls; }

    createBlock() {
        this.submitted = true;

        if (this.blockForm.invalid) {
            return;
        }


        let block: Block = this.utilService.clear(this.blockForm.value);
        block.start_date = moment(block.start_date[0], 'DD-MM-YYYY').format('YYYY-MM-DD');
        block.finish_date = moment(block.finish_date[0], 'DD-MM-YYYY').format('YYYY-MM-DD');
        
        if(block.start_hour) {
            block.start_hour = moment(block.start_hour[0], 'DD-MM-YYYY').format('HH:mm');
            block.finish_hour = moment(block.finish_hour[0], 'DD-MM-YYYY').format('HH:mm');
        }
        

        this.finish_date_error = false;
        console.group(moment(block.finish_date), moment(block.start_date));
        if (moment(block.finish_date).isBefore(moment(block.start_date), 'day')) {
            this.finish_date_error = true;
            return;
        }

        this.subscription.add(this.blockService.saveBlock(block).subscribe(
            () => {
                this.snackbarService.show('Block created successfully.', 'success');
                window.location.reload();
            },
            () => {
                this.snackbarService.show('Algo ha pasado ....', 'danger');
            }
        ));
    }

    ngOnDestroy(): void {
        if (this.subscription) {
            this.subscription.unsubscribe();
        }
    }
}