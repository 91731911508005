<app-appointments-header [slug]="slug.toLocaleLowerCase()"></app-appointments-header>

<div id="modal-container" class="hidden"></div>
<div id="overlay" (click)="closeModal()" class="hidden"></div>


<div class="max-w-7xl mx-auto px-4 sm:px-6 lg:px-8" *ngIf="allLoading; else loading;">
    <div class="max-w-3xl mx-auto">
        <div *ngIf="stepNumber <3">
            <div class="flex-1 flex flex-col p-8">
                <img class="w-32 h-32 flex-shrink-0 mx-auto bg-white rounded-full"
                    [src]="srcImage"
                    alt="">

                <div class="text-center">
                    <div class="title" *ngIf="param.name != ''; then userTitle else generalTitle"></div>
                    <ng-template #userTitle>
                        <p class="title">
                            {{'external_appointment_calendar.nutritionist.title' | translate:param}}
                        </p>
                    </ng-template>
                    <ng-template #generalTitle><p class="title">{{ 'external_appointment_calendar.title' | translate }}</p></ng-template>
                    

                    <p class="text">
                        {{ 'external_appointment_calendar.text_1' | translate }}
                    </p>
                    
                    <!-- <dl class="mt-5 grid grid-cols-1 gap-5 sm:grid-cols-3">
                        <div class="flex items-center rounded-l-3xl px-4 py-5 bg-nutriwell-sansivera shadow rounded-lg overflow-hidden sm:p-6">
                            <p class="mt-1 mr-7 text-3xl font-semibold text-white">
                                1
                            </p>
                            <p class="text-sm font-medium text-white">
                                {{ 'external_appointment_calendar.steps.first' | translate }}
                            </p>
                        </div>
                    
                        <div class="flex items-center rounded-l-3xl px-4 py-5 bg-nutriwell-sansivera shadow rounded-lg overflow-hidden sm:p-6">
                            <p class="mt-1 mr-7 text-3xl font-semibold text-white">
                                2
                            </p>
                            <p class="text-sm font-medium text-white">
                                {{ 'external_appointment_calendar.steps.second' | translate }}
                            </p>
                        </div>
                    
                        <div class="flex items-center rounded-l-3xl px-4 py-5 bg-nutriwell-sansivera shadow rounded-lg overflow-hidden sm:p-6">
                            <p class="mt-1 mr-7 text-3xl font-semibold text-white">
                                3
                            </p>
                            <p class="text-sm font-medium text-white">
                                {{ 'external_appointment_calendar.steps.third' | translate }}
                            </p>
                        </div>
                    </dl> -->
                </div>
            </div>
        </div>
        
        <div [formGroup]="customerExternalForm">

            <div class="flex items-center p-3 flex-col sm:flex-row" *ngIf="stepNumber == 1" [@enterAnimation]>

                <div class="flex-1" [ngClass]="{'hidden': isMobile && noShowCalendar}">
                    <ng2-flatpickr #calendar addClass="inputClass hidden"
                        [config]="dateOptions" (change)="changeDay($event.target.value)"
                        formControlName="date">
                    </ng2-flatpickr>
                </div>

                <div class="h-64 overflow-y-auto w-full sm:w-1/2 pt-10 sm:pt-0 custom-scroll
                    pr-2 sm:block"
                    [ngClass]="{'hidden': isMobile && !noShowCalendar}">

                    <ul class="space-y-4"
                        *ngIf="setHours ; else loadingHours;">

                        <span class="underline text-sm cursor-pointer" (click)="comeBack()" *ngIf="isMobile">{{
                            'external_appointment_calendar.edit' | translate
                            }}</span>
                        <li *ngIf="hours.length == 0" id="radiogroup-option-0" tabindex="0" role="radio"
                            aria-checked="true"
                            class="group relative bg-white rounded-lg shadow-sm
                            cursor-pointer focus:outline-none">
                            <div class="rounded-lg border border-gray-300 bg-white px-6 py-4
                                hover:border-gray-400 text-center text-sm">
                                <p class="font-medium text-gray-900">
                                    {{ 'external_appointment_calendar.select' | translate }}
                                </p>
                            </div>
                            
                        </li>
                        <li id="radiogroup-option-0" tabindex="0" role="radio"
                            aria-checked="true"
                            class="group relative bg-white rounded-lg shadow-sm
                            cursor-pointer focus:outline-none"
                            *ngFor="let hour of setHours">
                            <div class="rounded-lg border border-gray-300 bg-white px-6 py-4
                                hover:border-gray-400 text-center text-sm"
                                (click)="setHour(hour)">
                                <p class="font-medium text-gray-900">
                                    {{hour}}
                                </p>
                            </div>
                        </li>
                    </ul>

                    <ng-template #loadingHours>
                        <ul class="space-y-4" role="radiogroup"
                            aria-labelledby="radiogroup-label">
                            <li id="radiogroup-option-0" tabindex="0" role="radio"
                                aria-checked="true"
                                class="group relative bg-white rounded-lg shadow-sm
                                cursor-pointer focus:outline-none">
                                <span
                                    class="text-green-500 opacity-75 top-1/2 my-0 mx-auto
                                    block relative w-0 h-0 right-1/2">
                                    <i class="fas fa-circle-notch fa-spin fa-5x"></i>
                                </span>
                            </li>
                        </ul>
                    </ng-template>
                </div>
            </div>

            <div class="grid grid-cols-2 gap-8 p-3" *ngIf="stepNumber == 2" [@enterAnimation]>

                <div class="col-span-2">
                    <h4 class="text-2xl pb-2.5">{{ 'external_appointment_calendar.confirm' |
                        translate }}</h4>
                    <span class="capitalize">{{ f.date.value[0] | date:'EEEE dd/MM/yyyy' : '' : localeCalendar }} {{f.hour.value}}</span> >
                    <span class="underline text-sm cursor-pointer" (click)="comeBack()">{{
                        'external_appointment_calendar.edit' | translate
                        }}</span>
                    <span class="block">{{'appointment_create.duration' | translate }}: 20 min</span>
                </div>
                <div class="col-span-2">
                    <app-customer-general [zipcodesConfig]=zipcodesConfig [zipCode]=zipCode [id_team]=team_id [term]=true [class]="class" (submit)="onSubmit($event)"></app-customer-general>
                </div>
            </div>

            <div class="flex flex-col text-2xl text-center p-8" *ngIf="stepNumber == 3">
                <img class="w-32 h-32 flex-shrink-0 mx-auto bg-white rounded-full"
                    [src]="srcImage"
                    alt="">

                    <div class="text-center flex flex-col justify-center">
                        <p class="title pb-11" style="color: #5F5E5E !important">
                            {{'external_appointment_calendar.thank_you.text_1' | translate:param}}
                        </p>
                            
                        <img src="assets/images/svg/mail-color.svg" class="mx-auto w-1/5"  />

                        <p class="title py-11" style="color: #5F5E5E !important;">
                            {{ 'external_appointment_calendar.thank_you.text_2' | translate:param }}
                        </p>
                  
                    </div>

                <!-- <a href="https://naturhouse.es" class="inline-flex justify-center w-full rounded-md
                    border
                    border-transparent px-4 py-2 bg-nutriwell text-base leading-6 font-medium
                    text-white shadow-sm focus:outline-none focus:border-nutriwell
                    focus:ring-nutriwell transition ease-in-out duration-150 sm:text-sm
                    sm:leading-5"> Ir a Naturhouse </a> -->
            </div>
        </div>
    </div>
</div>

<ng-template #loading>
    <app-loading></app-loading>
</ng-template>