import { trigger, transition, style, animate } from "@angular/animations";
import { Component, ElementRef, Input, OnDestroy, OnInit, Output, ViewChild, EventEmitter } from "@angular/core";
import { CurrentFilter, Filter } from "src/app/shared/interfaces/filter.interface";
import { Ingredient } from "./ingredient.interace";
import { v4 as uuidv4 } from 'uuid';
import { IngredientService } from "./ingredient.service";
import { Subscription } from "rxjs";
import { IngredientRecipe } from "../recipe/recipe.interface";
import { SettingGeneralService } from "src/app/shared/services/settings-general.service";
import { SnackbarService } from "src/app/shared/components/snackbar/snackbar.service";

@Component({
    selector: 'app-ingredient',
    templateUrl: './ingredient.component.html',
    styleUrls: ['./ingredient.component.scss'],
    animations: [
        trigger(
            'showIngredient', [
                transition(':enter', [
                    style({ transform: 'translateX(100%)', opacity: 0 }),
                    animate('1000ms', style({ transform: 'translateX(0)', opacity: 1 }))
                ])
            ]
        ),
        trigger(
            'hiddenIngredient', [
                transition(':enter', [
                    style({ transform: 'translateX(0%)', opacity: 0 }),
                    animate('1000ms', style({ transform: 'translateX(100%)', opacity: 1 }))
                ])
            ]
        )
    ]
})

export class IngredientComponent implements OnInit, OnDestroy {
    
    @Input() isToCreateRecipe: boolean = false;
    @Output() addIngredientToRecipe = new EventEmitter<IngredientRecipe>();
    
    currentIngredient: Ingredient = null;

    ingredients: Ingredient[];

    filters: Filter[];
    currentFilters: CurrentFilter[] = null;
    
    // pagination
    page: number = 1;
    per_page: number = 15;

    @ViewChild('scroll') private myScrollContainer: ElementRef;
    noMore: boolean = false;
    loadingMore: boolean = false;
    
    showForm: boolean = false;

    private subscription = new Subscription();
    
    saveIngredient = [];

    locale: string;
    constructor(
        private ingredientService: IngredientService,
        private settingGeneralService: SettingGeneralService,
        private snackbarService: SnackbarService
    ) { }

    ngOnInit(): void {
        this.locale = this.settingGeneralService.auth.getUserVariable('teams').config.language;
        this.listIngredients();

        this.ingredientService.listIngredientFilters().subscribe(
            (response: Filter[]) =>  {
                this.filters = response;

                this.filters.forEach((filter) => {
                    filter.name = filter.name;
                    filter.nameTranslate = 'customer_list.table.filters.'+filter.name;
                });
            }
        );
    }

    private listIngredients(currentFilters?: CurrentFilter[], scroll = false) {
        this.subscription.add(
            this.ingredientService.listIngredient(this.page, this.per_page, currentFilters, this.locale).subscribe(
                (response) => {
                    if(!scroll) {
                        this.ingredients = response;
                        
                        if(this.ingredients?.length > 0) {
                            this.noMore = false;
                        } else {
                            this.noMore = true;
                        }
                    } else {
                        const moreIngredients = response;

                        this.loadingMore = false;
                        if(0 === moreIngredients.length) {
                            this.noMore = true;
                            return ;
                        }
                        
                        this.ingredients = this.ingredients.concat(moreIngredients);
                    }
                },
                (error) => this.snackbarService.show('Someting was wrong, retry in a minutes', 'danger')
            )
        );
    }

    onScroll(event) {
        if (event.target.offsetHeight + event.target.scrollTop >= event.target.scrollHeight && !this.noMore) {
            this.loadingMore = true;

            this.page++;
            this.listIngredients(this.currentFilters, true);
        }
    }

    changeView(formIsVisible: boolean, ingredient: Ingredient = null) {
        this.currentIngredient = ingredient;
        this.showForm = formIsVisible;
    }

    clickIngredient(ingredient: Ingredient) {
        if(!this.isToCreateRecipe) {
            this.changeView(true, ingredient);
        }
    }

    addIngredient(ingredient: Ingredient) {
        let nameIngredient: string = ingredient.locales.filter(locale => locale.locale = this.locale).map(locale => locale.value)[0];
        this.addIngredientToRecipe.emit({
            ingredient: ingredient, 
            name: nameIngredient, 
            quantity: this.saveIngredient[ingredient.id],
        });
        this.saveIngredient[ingredient.id] = null;
    }

    private updateIngredientList(newIngredient: Ingredient)
    {
        this.ingredients.map((ingredient, index) => {
            if (ingredient.id == newIngredient.id) {
                this.ingredients[index] = newIngredient;
            } 
        })
    }

    createOrUpdateList(event) {
        let newIngredient: Ingredient = event.ingredient;
        
        if(event.isNew) {
            this.ingredients.push(newIngredient);
        } else {
            this.updateIngredientList(newIngredient);
        }

        this.changeView(false);
    }

    filterChange(currentFilters: CurrentFilter[]) {
        this.currentFilters = currentFilters;
        this.page = 1;
        try {
            this.myScrollContainer.nativeElement.scroll(0,0);
        } catch(err) { } 

        this.listIngredients(this.currentFilters);
    }

    ngOnDestroy(): void {
        if(this.subscription){
            this.subscription.unsubscribe();
        }
    }
}