import { HttpClient } from "@angular/common/http";
import { Injectable } from "@angular/core";
import { map } from "rxjs/operators";
import { environment } from "src/environments/environment";

@Injectable({
    providedIn: 'root'
})
export class TagService {

    constructor(
        private http: HttpClient
    ) { }


    public getTagsByTeamId(team_id: number) {
        return this.http
            .get(`${environment.apiUrl}/tags/${team_id}`)
            .pipe(map((response: any) => {
                return response.data;
            }));
    }
}