import { trigger, transition, style, animate } from "@angular/animations";
import { Component, EventEmitter, Input, Output } from "@angular/core";
import { TranslateService } from "@ngx-translate/core";
import { CurrentFilter, Filter } from "../../interfaces/filter.interface";


@Component({
    selector: 'app-filter',
    templateUrl: './filter.component.html',
    animations: [
        trigger(
            'enterAnimation', [
                transition(':enter', [
                    style({ transform: 'translateY(100%)', opacity: 0 }),
                    animate('500ms', style({ transform: 'translateY(0)', opacity: 1 }))
                ]),
                transition(':leave', [
                    style({ transform: 'translateY(0)', opacity: 1 }),
                    animate('500ms', style({ transform: 'translateY(100%)', opacity: 0 }))
                ])
            ]
        ),
        trigger(
            'enterAnimationValue', [
                transition(':enter', [
                    style({ transform: 'translateX(100%)', opacity: 0 }),
                    animate('500ms', style({ transform: 'translateX(0)', opacity: 1 }))
                ]),
                transition(':leave', [
                    style({ transform: 'translateX(0)', opacity: 1 }),
                    animate('500ms', style({ transform: 'translateX(100%)', opacity: 0 }))
                ])
            ]
        )
    ],
})

export class FilterComponent {
    
    @Input() filters: Filter[];
    @Output() sendFiltersEmitter = new EventEmitter<CurrentFilter[]>();

    filter: Filter = null;
    showFilters: boolean = false;
    showFiltersValue: boolean = false;
    showColumns: boolean = false;
    filterText: string = '';
    
    objectKeys = Object.keys;

    currentFilters: CurrentFilter[] = [];
    
    filterValue(filter: Filter) {
        this.showFilters = false;
        this.showFiltersValue = true;
        this.filter = filter;
    }

    constructor(
        private translateService: TranslateService
    ){
        
    }

    selectFilterValue(option) {
        this.showFilters = false;
        this.showFiltersValue = false;

        const newFilter = {
            value: option,
            name: this.filter.name,
            nameText: this.translateService.instant(this.filter.nameTranslate),
            valueText: this.filter.type !== 'text' ?  this.filter.options[option] : option
        };
        
        if(!this.currentFilters.find(elem => elem.name === newFilter.name)) {
            this.currentFilters.push(newFilter);
        }

        this.filterText = '';
        
        this.sendFilters();
    }

    deleteCurrentFilter(position: number) {
        this.currentFilters.splice(position, 1);
        this.sendFilters();
    }

    sendFilters() {
        this.sendFiltersEmitter.emit(this.currentFilters);
    }
}