import { HttpClient } from '@angular/common/http';
import { Injectable } from '@angular/core';
import { map } from 'rxjs/operators';
import { environment } from 'src/environments/environment';
import { UtilsService } from '../../../shared/services/util.service';


@Injectable({
    providedIn: 'root'
})
export class SignPrivacyService {

    constructor(private http: HttpClient) { }

    getCustomerCrypt(customerId: string) {
        return this.http.get(`${environment.apiUrl}/customers/${customerId}/gdpr`)
            .pipe(map((response: any) => {
                return response.data;
            }));
    }

    signCustomerGdpr(customerId: string, customer) {
        return this.http
            .post(`${environment.apiUrl}/customers/${customerId}/gdpr`, customer)
            .pipe(map((response: any) => {
                response.data;
            }));
    }
}