<div class="fixed z-40 inset-0 overflow-y-auto">
	<div class="flex items-end justify-center min-h-screen pt-4 px-4 pb-20 text-center sm:block
		sm:p-0">

		<div class="fixed inset-0 transition-opacity">
			<div class="absolute inset-0 bg-gray-500 opacity-75"></div>
		</div>

		<!-- This element is to trick the browser into centering the modal contents. -->
		<span class="hidden sm:inline-block sm:align-middle sm:h-screen"></span>&#8203;

		<div class="inline-block align-bottom bg-white rounded-lg text-left overflow-hidden shadow-xl
			transform transition-all sm:my-8 sm:align-middle sm:max-w-lg sm:w-full"
			role="dialog" aria-modal="true" aria-labelledby="modal-headline">
			<div *ngIf="showCloseIcon" class="bg-gray-50 px-4 pt-3 sm:px-6 sm:flex sm:flex-row-reverse">
				<svg class="w-6 h-6 pb-2 cursor-pointer" fill="none" stroke="currentColor" viewBox="0 0 24 24"
					xmlns="http://www.w3.org/2000/svg" (click)="closeModalF()">
					<path stroke-linecap="round" stroke-linejoin="round" stroke-width="2" d="M6 18L18 6M6 6l12 12">
					</path>
				</svg>
			</div>
			<div class="bg-white px-4 pt-5 pb-4 sm:p-6 sm:pb-4">
				<div class="sm:flex sm:items-start">
					<ng-content select="[data]"></ng-content>
				</div>
			</div>
		</div>
	</div>
</div>