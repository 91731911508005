import { HttpParams } from "@angular/common/http";
import { Injectable } from "@angular/core";
import { map } from "rxjs/operators";
import { CurrentFilter } from "src/app/shared/interfaces/filter.interface";
import { NutriwellService } from "src/app/shared/services/nutriwell.service";
import { environment } from "src/environments/environment";
import { Ingredient } from "./ingredient.interace";

@Injectable({
    providedIn: 'root'
})
export class IngredientService extends NutriwellService{

    listIngredient(page: number, per_page: number, fields?: CurrentFilter[], locale?: string) {
        let params = new HttpParams();
        params = params.append('page', page);
        params = params.append('per_page', per_page);
        params = params.append('locale', locale);

        if (fields) {
            fields.forEach(field => {
                params = params.append(field.name, field.value)
            });
        }

       return this.http.get(`${environment.apiUrl}/admin/ingredients`, { params })
            .pipe(map((response) => {
                return response['data']
            }));
    }

    listIngredientFilters() {
        return this.http.get(`${environment.apiUrl}/admin/ingredients/filters`).pipe(
            map(res =>  {
                return res['data'];
            })
        );
    }

    createOrUpdateIngredient(ingredient: Ingredient, isNew: boolean) {
        let url = `${environment.apiUrl}/admin/ingredients`;

        if(!isNew) {
            url += `/${ingredient.id}`;
            return this.http.patch(url, ingredient).pipe(
                map(res =>  {
                    return res;
                })
            );
        } else {
            return this.http.post(url, ingredient).pipe(
                map(res =>  {
                    return res;
                })
            );
        }
    }
}