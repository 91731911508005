import { Component, OnInit, OnDestroy, Input, Output } from "@angular/core";
import { FormBuilder, FormGroup, Validators } from "@angular/forms";
import * as moment from 'moment';
import { FlatpickrOptions } from "ng2-flatpickr";
import { Subscription } from "rxjs";
import { Appointment } from "src/app/shared/classes/appointment.class";
import { SnackbarService } from "src/app/shared/components/snackbar/snackbar.service";
import { AppointmentService } from "src/app/shared/services/appointment.service";
import { SettingService } from "src/app/shared/services/setting.service";
import { SettingGeneralService } from "src/app/shared/services/settings-general.service";

@Component({
    selector: 'app-new-appointment',
    templateUrl: './new-appointment.component.html'
})

export class NewAppointmentComponent implements OnInit, OnDestroy {
    
    @Input() appointmentDay;
    @Input() appointmentHour;
    @Input() customer;
    @Input() title: string;
    @Input() text: string;
    @Input() text_button_register: string;
    @Output() closeModal;
    @Output() reloadAction;

    sevenDays = { day: 7 };
    fifteenDays = { day: 15 };
    newAppointment7days;
    newAppointment14days;

    newappointmentsForm: FormGroup;
    submitted = false;
    dateOptions7days: FlatpickrOptions;
    dateOptions14days: FlatpickrOptions;

    subscriptionkindsAppointments = new Subscription;
    kindsAppointments;
    firstKindAppointment7days;
    firstKindAppointment14days;
    showListKind7days = false;
    showListKind14days = false;

    team_id: number;
    user_id: number;

    subscription = new Subscription;
    settingsValue;

    format; 

    constructor(
        private settingGeneralService: SettingGeneralService,
        private settingGeneral: SettingService,
        private builder: FormBuilder,
        private appointmentService: AppointmentService,
        private snackbarService: SnackbarService
    ){
        this.settingGeneralService.changeCountry$.subscribe(
            () => {
                this.settingsValue = this.settingGeneralService.getSettings();
            }
        );

        this.format = this.settingsValue.formatMoment+ ' HH:mm';
        this.dateOptions7days = {
            locale: this.settingsValue.flatpickr,
            dateFormat: this.settingsValue.formatFlatpickr+' H:i',
            disableMobile: false,
            enableTime: true,
            minuteIncrement: 20
        };

        this.dateOptions14days = {
            locale: this.settingsValue.flatpickr,
            dateFormat: this.settingsValue.formatFlatpickr+' H:i',
            disableMobile: true,
            enableTime: true,
            minuteIncrement: 20
        };

        this.team_id = (this.settingGeneralService.auth).getUserVariable('current_team_id');
        this.user_id = (this.settingGeneralService.auth).getUserVariable('id');
    }

    ngOnInit(): void {
        const currentDay = moment(this.appointmentDay+' '+ this.appointmentHour);
        
        this.newAppointment7days = currentDay.clone().add(7, 'days');
        this.dateOptions7days.defaultDate = moment(this.newAppointment7days).format(this.format);
        this.newAppointment14days = currentDay.clone().add(14, 'days');
        this.dateOptions14days.defaultDate = moment(this.newAppointment14days).format(this.format);

        this.newappointmentsForm = this.builder.group({
            newAppointment7days: [{ 0: this.newAppointment7days.format(this.format) }, [Validators.required]],
            kindsAppointments7days: [null, [Validators.required]],
            duration7days: [20, [Validators.required]],
            newAppointment14days: [{ 0: this.newAppointment14days.format(this.format) }, [Validators.required]],
            kindsAppointments14days: [null, [Validators.required]],
            duration14days: [20, [Validators.required]]
        });

        this.subscriptionkindsAppointments.add(this.settingGeneral.getColors(this.team_id).subscribe(
            (response) => {
                this.kindsAppointments = response;
                this.changeKind(this.kindsAppointments[6]);
                this.changeKind(this.kindsAppointments[3], false);
            }
        ));

        
    }

    get f() { return this.newappointmentsForm.controls; }

    closeModalF()
    {
        this.closeModal();
    }

    changeKind(kind, days7 = true) {
        if(days7) {
            this.showListKind7days = false;
            this.firstKindAppointment7days = kind;
            this.newappointmentsForm.get('kindsAppointments7days').setValue(this.firstKindAppointment7days.kind_appointment_id);
        } else {
            this.showListKind14days = false;
            this.firstKindAppointment14days = kind;
            this.newappointmentsForm.get('kindsAppointments14days').setValue(this.firstKindAppointment14days.kind_appointment_id);
        }
    }

    submit()
    {
        this.submitted = true;

        if(!this.newappointmentsForm.valid){
            return;
         }
           
        const date = (this.newappointmentsForm.get('newAppointment7days')).value;
        
        let appointment7day = new Appointment(
            this.customer, 
            (this.newappointmentsForm.get('duration7days')).value,
            this.team_id, 
            this.user_id, 
            moment(date[0], this.format).format('YYYY-MM-DD'),
            moment(date[0], this.format).format('HH:mm'),
            this.firstKindAppointment7days.kind_appointment_id
        );
         
        const date15 = (this.newappointmentsForm.get('newAppointment14days')).value;
        let appointment14day = new Appointment(
            this.customer, 
            (this.newappointmentsForm.get('duration14days')).value,
            this.team_id, 
            this.user_id, 
            moment(date15[0], this.format).format('YYYY-MM-DD'),
            moment(date15[0], this.format).format('HH:mm'),
            this.firstKindAppointment14days.kind_appointment_id
        );

        const appointments = {
            'seven': appointment7day,
            'fifteen': appointment14day
        };
        

        this.subscription.add(this.appointmentService.createSchedule(appointments).subscribe(
            (response) => {
                this.reloadAction();
                this.snackbarService.show('Appointments created successfully.', 'success');
            }
        ));
        
    }

    ngOnDestroy(): void {
        if(this.subscriptionkindsAppointments) {
            this.subscriptionkindsAppointments.unsubscribe();
        }
    }
}

