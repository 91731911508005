import { HttpClient } from "@angular/common/http";
import { Injectable } from "@angular/core";
import { map } from "rxjs/operators";
import { environment } from "src/environments/environment";


@Injectable({
    providedIn: 'root'
})
export class ConfigService {

    constructor(private http: HttpClient) { }

    isValidZipcode(team_id, zipcode) {
        return this.http
            .get(`${environment.apiUrl}/appointments/config/${team_id}/${zipcode}`,)
            .pipe(map((response: any) => {
                return response.data;
            }));
    }

}