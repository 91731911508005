<div class="min-h-full flex flex-col justify-center py-12 sm:px-6 lg:px-8">
    <div class="sm:mx-auto sm:w-full sm:max-w-md">
        <img class="mx-auto h-12 w-auto"
            src="assets/images/logo"
            alt="Workflow">
        <h2 class="mt-6 text-center text-3xl font-extrabold text-nutriwell">
            Nutriwell 
        </h2>
    </div>

    <div class="mt-8 sm:mx-auto sm:w-full sm:max-w-md">
        <div class="bg-white py-8 px-4 shadow sm:rounded-lg sm:px-10">
            <form class="space-y-6" [formGroup]="loginForm"
                (ngSubmit)="onSubmit()">
                <div>
                    <app-label-form for="email" name="{{ 'login.username' |
                        translate }}"></app-label-form>
                    <div class="mt-1">
                        <input formControlName="email" id="email" name="email"
                            type="email"
                            [ngClass]="{ 'border border-red-500': submitted &&
                            f.email.errors }"
                            class="appearance-none
                            block w-full px-3 py-2 border border-gray-300
                            rounded-md shadow-sm placeholder-gray-400
                            focus:outline-none focus:ring-nutriwell
                            focus:border-nutriwell sm:text-sm" />
                        <ng-container *ngIf="submitted && f.email.errors">
                            <app-error-form *ngIf="f.email.errors.required"
                                error="{{'errors.required' |
                                translate}}">
                            </app-error-form>
                            <app-error-form *ngIf="f.email.errors.email"
                                error="{{'errors.email' |
                                translate}}">
                            </app-error-form>
                        </ng-container>
                    </div>
                </div>

                <div>
                    <app-label-form for="password" name="{{ 'login.password' |
                        translate }}"></app-label-form>
                    <div class="mt-1">
                        <input formControlName="password" id="password"
                            name="password" type="password"
                            [ngClass]="{ 'border border-red-500': submitted &&
                            f.password.errors }"
                            class="appearance-none block w-full px-3 py-2 border
                            border-gray-300 rounded-md shadow-sm
                            placeholder-gray-400 focus:outline-none
                            focus:ring-nutriwell focus:border-nutriwell
                            sm:text-sm">
                        <ng-container *ngIf="submitted && f.password.errors">
                            <app-error-form *ngIf="f.password.errors.required"
                                error="{{'errors.required' |
                                translate}}">
                            </app-error-form>
                            <app-error-form *ngIf="f.password.errors.minlength"
                                error="{{'errors.minLength' |
                                translate:passwordLength}}">
                            </app-error-form>
                        </ng-container>
                    </div>
                </div>

                <div class="flex items-center justify-between">
                    <app-error-form *ngIf="error == 401" error="Password or username invalid">
                    </app-error-form>
                </div>

                <div>
                    <button type="submit" class="w-full flex justify-center py-2
                        px-4 border border-transparent rounded-md shadow-sm
                        text-sm font-medium text-white bg-nutriwell focus:outline-none 
                        focus:ring-2 focus:ring-offset-2 focus:ring-nutriwell">
                        {{ 'login.sign_in' |translate }}
                    </button>
                </div>
            </form>
            <p class="text-center text-gray-500 text-xs mt-4">
                {{ 'login.signed' | translate:param }}
            </p>
        </div>
    </div>
</div>