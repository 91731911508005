import { HttpClient } from '@angular/common/http';
import { Injectable } from '@angular/core';
import { TranslateService } from '@ngx-translate/core';
import { NgxPermissionsService, NgxRolesService } from 'ngx-permissions';
import { BehaviorSubject } from 'rxjs';
import { map } from 'rxjs/operators';
import { environment } from 'src/environments/environment';
import { Roles } from '../settings/rol';
import { User } from '../interfaces/user.interface';
import { AuthenticationGeneralService } from './auth-general.service';
import { Settings } from '../settings/setting';
import { DAYS_OF_WEEK } from 'angular-calendar';

@Injectable({
	providedIn: 'root'
})
export class SettingGeneralService {

	public country = localStorage.getItem('country') ? localStorage.getItem('country') : 'es';
	public settings = Settings.langSettings[this.country];

	private changeGeneralSettings = new BehaviorSubject<any>({
		locale: this.settings.locale,
		flatpickr: this.settings.flatpickr,
		legal_age: 18,
		weekStartsOn: DAYS_OF_WEEK.MONDAY,
        weekendDays: [DAYS_OF_WEEK.SATURDAY, DAYS_OF_WEEK.SUNDAY],
        formatMoment: 'DD-MM-YYYY',
        formatFlatpickr: 'd-m-Y',
        formatFlatpickrHour: 'd-m-Y H:i',
        zip_maps: ['es'],
        prefix: '+34',
		team_id: 1
	});
	
	public changeCountry$ = this.changeGeneralSettings.asObservable();

	constructor(
		private http: HttpClient,
		public translateService: TranslateService,
		private authService: AuthenticationGeneralService,
		private rolesService: NgxRolesService,
		private permissionsService: NgxPermissionsService
	) { }

	get auth() {
		return this.authService
	}

	setLang(lang) {
		this.translateService.use(lang);
	}

	changeLocale(country: string): void {
		const countrySettings = Settings.langSettings[country];
		
		const locale = countrySettings.locale;
		this.setLang(locale);
		localStorage.setItem('country', country);
		
		this.changeGeneralSettings.next({
			locale: locale,
			flatpickr: countrySettings.flatpickr,
			formatFlatpickr: countrySettings.formatFlatpickr,
			formatMoment: countrySettings.formatMoment,
		});
	}

	generateSettings(settings)
	{
		let country = settings.language;
		const countrySettings = Settings.langSettings[country];
		
		const locale = countrySettings.locale;
		this.setLang(locale);
		localStorage.setItem('country', country);

		this.changeGeneralSettings.next({
			locale: locale,
			flatpickr: countrySettings.flatpickr,
			weekStartsOn: settings.week_starts_on,
			weekendDays: settings.weekend_days,
			legal_age: settings.legal_age,
			formatMoment: settings.format_moment,
			formatFlatpickr: settings.format_flatpickr,
			formatFlatpickrHour: settings.format_flatpickr_hour,
			prefix: settings.prefix,
			team_id: settings.team_id,
			zip_maps: settings.zip_maps
		});
	}

	getSettings()
	{
		return this.changeGeneralSettings.value;
	}

	getLangText(target: string) {
		return this.translateService.instant(target)
	}

	getTeam() {
		const user: User = this.authService.getUser();

		const currentTeam = user.teams;
		
		const nameTeam = currentTeam.name;

		const totalTeams = (user.teams).length;

		const teams = user.teams;

		const current_team_id = user.current_team_id;

		const rol = Roles.roles.filter(x => x.id == currentTeam.rol)[0];

		if (typeof rol == 'undefined') {
			localStorage.setItem('version', 'v1');
			localStorage.removeItem('country');
			localStorage.removeItem('currentUser');
            window.open('/', "_self");
		}
		
		// refresh permissions
		this.permissionsService.flushPermissions();
		this.permissionsService.loadPermissions(rol.permissions);

		// refresh roles
		this.rolesService.flushRoles();
		this.rolesService.addRole(rol.name, rol.permissions);

	}

	changeSettings(id: number, kindsAppointments) {
		const body = {
			id,
			kindsAppointments
		};

		return this.http
			.put(`${environment.apiUrl}/change-team`, body)
			.pipe(map((response: any) => {
				if (!response.error) {
					let user = this.authService.getUser();
					user.current_team_id = id;
					this.authService.setToken(user);
					this.getTeam();
				}
			}));

	}

	// data layer
	createDataLayer()
	{
		if(true === environment.production) {
			window['dataLayer'] = window['dataLayer'] || [];
		}
	}

	insertDataLayer(event : string, label = '') {
		if(true === environment.production) {
			window['dataLayer'] = window['dataLayer'] || [];
			window['dataLayer'].push({
				'event': event,
				'eventCategory': 'Appointment Request',
				'eventAction':  event,
				'eventLabel': label
			})
		}
	}

	submitFormDatalayer(customerId) {
		if(true === environment.production) {
			window['dataLayer'] = window['dataLayer'] || [];
			window['dataLayer'].push({
				'event': 'Submit form',
				'eventCategory': 'Appointment Request',
				'eventAction':  'Submit form',
				'eventLabel': '',
				'user_id': customerId,
				'gtm.uniqueEventId': 80
			})
		}
	}

	countryDataLayer(name: string) {
		if(true === environment.production) {
			window['dataLayer'] = window['dataLayer'] || [];
			window['dataLayer'].push({
				'country': this.transformNameToCountry(name)
			})
		}
	}

	private transformNameToCountry(name: string): string {
		const transform = {
			'es': 'Spain',
			'fr': 'France',
			'it' : 'Italy',
			'ie': 'Ireland',
			'de': 'Deutchland',
			'us': 'EEUU',
			'pt': 'Portuguese',
			'hr': 'Croatia',
			'sg': 'Singapore'
		};

		for (var key in transform) {
			if(name.includes(key))
				return transform[key];
		  }
	}
}
