

export class Slug {


    public static normalize(slug:string): string
    {
        const params = new URLSearchParams(slug);
        

        return slug.split('?')[0];
    }
}