import { HttpClient, HttpParams } from '@angular/common/http';
import { Injectable } from '@angular/core';
import { map } from 'rxjs/operators';
import { environment } from 'src/environments/environment';

@Injectable({
    providedIn: 'root'
})

export class CustomerExternalService {

    constructor(private http: HttpClient) {

    }

    saveGoogleCookiesByUuid(customer_id: string, cookies)
    {
        const formData: FormData = new FormData();
        formData.append('cookies', JSON.stringify(cookies));
        
        return this.http
            .post(`${environment.apiUrl}/external/customers/${customer_id}/cookies`, formData)
            .pipe(map((response: any) => {
                response.data;
            }));
    }
}