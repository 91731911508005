import { Component, OnInit } from '@angular/core';
import { AppService } from 'src/app/app.service';
import { ModalInfoOptionsComponent } from 'src/app/shared/components/modals/info-options/modal-info-options.component';
import { ModalInfoComponent } from 'src/app/shared/components/modals/info/modal-info.component';
import { AppointmentService } from 'src/app/shared/services/appointment.service';
import { CustomerService } from 'src/app/shared/services/customer.service';
import { ModalService } from 'src/app/shared/services/modal.service';
import { SettingGeneralService } from 'src/app/shared/services/settings-general.service';
import { CustomerEditNutritionalPlanService } from '../../customer/customer-edit/nutritional-plan/customer-edit-nutritional-plan.service';
import { NewAppointmentComponent } from './appointment/new-appointment.component';
import { CalendarModalComponent } from './calendar/calendar-modal.component';
import { CustomerEditCrashDietComponent } from './crashDiet/customer-edit-crash-diet.component';
import { CustomerComponent } from './customer/customer.component';
import { ShowOrderModalComponent } from './order/show-order-modal.component';

@Component({
    selector: 'app-menu',
    templateUrl: './menu.component.html',
    styleUrls: ['./menu.component.scss'],
})

export class MenuComponent implements OnInit {

    isOpenMobile = true;
    showSmallNavBar = false;
    rol_id: number;

    // team
    nameTeam: string;
    divTeams = false;

    totalTeams: number;
    teams: any;

    crashDays: [];

    showAdmin = false;

    
    show = false;

    constructor(
        public settingGeneralService: SettingGeneralService,
        private modalService: ModalService,
        private nutritionalPlanService: CustomerEditNutritionalPlanService,
        private appointmentService: AppointmentService,
        private customerService: CustomerService,
        public appService: AppService
    ) { }


    ngOnInit(): void {
        this.rol_id = this.settingGeneralService.auth.data.rol;
    }

    changeValue(value?) {
        this.isOpenMobile = value ? value : !this.isOpenMobile;
    }

    smallNavBar() {
        this.showSmallNavBar = !this.showSmallNavBar;
    }

    showModal() {
        this.changeValue(true);
        // this.modalService.init(ModalInfoComponent, this.settingGeneralService.getLangText('modal'), { closeModal: this.closeModal.bind(this) });
        this.generateSchedule('2021-09-05', '14:53', 1);
    }

    closeModal() {
        this.modalService.destroy();
    }

    createCustomer() {
        this.changeValue(true);
        this.modalService.init(CustomerComponent, this.settingGeneralService.getLangText('customer_create'), { closeModal: this.closeModal.bind(this) });
    }

    createAppointment(date?, customer?) {
        this.changeValue(true);
        let inputs = this.settingGeneralService.getLangText('appointment_create');
        if (date) {
            inputs.appointmentDay = date;
        }
        if (customer) {
            inputs.customerI = customer;
        }

        this.modalService.init(CalendarModalComponent, inputs, { closeModal: this.closeModal.bind(this) })
    }

    // crashDays
    createCrashDay() {
        this.changeValue(true);
        this.modalService.init(
            CustomerEditCrashDietComponent,
            this.settingGeneralService.getLangText('customer_edit.nutritional_plan.shock'),
            { closeModal: this.closeModal.bind(this), sendDays: ($days) => { this.sendDays($days) } }
        );
    }

    sendDays(days) {
        this.closeModal();

        this.nutritionalPlanService.changeCrashState(days);
    }

    // deleteAppointment
    generateDeleteAppointment(appointment_id) {
        this.changeValue(true);

        this.modalService.init(
            ModalInfoComponent,
            this.settingGeneralService.getLangText('customer_edit.appointments_delete'),
            { closeModal: this.closeModal.bind(this), buttonAction: ($action) => { this.deleteAppointmentAction(appointment_id) } });
    }

    deleteAppointmentAction(appointment_id) {
        this.closeModal();
        this.appointmentService.deleteAppointmentState(appointment_id)
    }

    // startAppointment
    generateStartAppointment(event) {
        const appointment = event.appointment;
        const customer = event.customer;
        this.changeValue(true);

        let inputs = this.settingGeneralService.getLangText('customer_edit.appointments_show');

        inputs.options = [];
        inputs.options.push({
            'text': this.settingGeneralService.getLangText('customer_edit.appointments_show.see'),
            'optionReturn': 4,
            'class': 'customer-info-button-white'
        });
        
        if (customer.in_my_team) {
            let text = 'Actualizar';
            let option = 3;
            if(!appointment.status) {
                option = 2;
                text = this.settingGeneralService.getLangText('customer_edit.appointments_show.start');
            }
            inputs.options.push({
                'text': text,
                'optionReturn': option,
                'class': 'customer-info-button-reverse'
            });
        }

        this.modalService.init(
            ModalInfoOptionsComponent,
            inputs,
            { closeModal: this.closeModal.bind(this), buttonAction: ($action) => { this.startAppointmentAction($action, appointment) } });
    }

    startAppointmentAction(action, appointment) {
        this.closeModal();
        
        this.appointmentService.showAppointmentState(action, appointment)
    }

    
    // generateSchedule
    generateSchedule(date: string, hour: String, customer_id: number) {

        this.changeValue(true);

        let inputs = this.settingGeneralService.getLangText('next_appointments');

        inputs.appointmentDay = date;
        inputs.appointmentHour = hour;
        inputs.customer = customer_id;

        this.modalService.init(
            NewAppointmentComponent, 
            inputs, 
            { closeModal: this.closeModal.bind(this), reloadAction: () => { this.closeGenerateSchedule() } });
    }

    closeGenerateSchedule() {
        this.closeModal();
        window.location.reload();
    }

    // delete customer
    generateDeleteCustomer(customer_id) {
        this.changeValue(true);

        this.modalService.init(
            ModalInfoComponent,
            this.settingGeneralService.getLangText('customer_list.table.delete'),
            { closeModal: this.closeModal.bind(this), buttonAction: ($action) => { this.deleteCustomerAction(customer_id) } });
    }

    deleteCustomerAction(customer_id) {
        this.closeModal();
        //this.customerService.deleteCustomerState(customer_id)
    }

    // order Details
    showOrderDetails(items: string[])
    {
        this.changeValue(true);

        let inputs = this.settingGeneralService.getLangText('order.modal');

        inputs.items = items;
        
        this.modalService.init(
            ShowOrderModalComponent,
            inputs,
            { closeModal: this.closeModal.bind(this) });
    }
}