import { Component, OnInit } from '@angular/core';
import { environment } from 'src/environments/environment';
import { SettingGeneralService } from './shared/services/settings-general.service';
import { Settings } from './shared/settings/setting';


// fireabase
import { initializeApp } from "firebase/app";
initializeApp(environment.firebaseConfig);
import { getMessaging, getToken, onMessage } from "firebase/messaging";
import { AppService } from './app.service';

@Component({
	selector: 'app-root',
	templateUrl: 'app.component.html',
})

export class AppComponent implements OnInit{

	defaultCountry = Settings.langs;
	setLocale: string = 'en';
	
	constructor(
		private settingGeneralService: SettingGeneralService,
		private appService: AppService
		) {
			if('ie' === localStorage.getItem('country')) {
				localStorage.setItem('country', 'en');
			}

			if (localStorage.getItem('country')) {
				this.setLocale = Settings.langSettings[localStorage.getItem('country')].locale;
			} else {
				const browserLocale = (navigator.language).split('-')[0];

				this.setLocale = this.defaultCountry.includes(browserLocale) ? browserLocale : 'en'				
			}
	}
	
	ngOnInit(): void {
		
		
		this.settingGeneralService.changeLocale(this.setLocale);

		if(this.settingGeneralService.auth.isLoggedIn()) {
			if (Notification.permission !== "granted") {
				this.requestPermission();
			}
			this.listen();
		}
	}

	private requestPermission() {
		const messaging = getMessaging();
		getToken(messaging, 
		    { vapidKey: environment.firebaseConfig.vapidKey})
        .then(
		    (currentToken) => {
		        if (currentToken) {
		            this.settingGeneralService.auth.setTokenNotification(currentToken).subscribe();
                } else {
                    console.log('No registration token available. Request permission to generate one.');
                }
		    })
        .catch((err) => {
                console.log('An error occurred while retrieving token. ', err);
        });
	}

	private listen() {
		const messaging = getMessaging();
		onMessage(messaging, (payload) => {
			this.appService.setHasNewMessage(true);
		});
	}
}
