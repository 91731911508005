<app-modal-data-min title="Introduzca su código postal" (closeModal)="closeModalF()">
    <div data class="w-full">
        <p class="text-center">{{title}}</p>
        <div class="grid justify-items-center p-4">
            <div class="sm:w-1/2 p-2">
                <input type="{{zipCodesConfig.acceptAlpha ? 'text': 'number'}}" 
                    maxlength="{{zipCodesConfig.length}}" 
                    [(ngModel)]="zipCode" class="inputClass text-center" id="duration" 
                    [ngClass]="{ 'border border-red-500': submitted && error }"
                    placeholder="{{'customer_create.zip' | translate}}">
                <ng-container *ngIf="submitted && error">
                    <app-error-form  error="{{'errors.required' | translate}}">
                    </app-error-form>
                </ng-container>
                <ng-container *ngIf="prohibitedError">
                    <app-error-form error="El código postal no es valido.">
                    </app-error-form>
                </ng-container>
            </div>

            <div class="p-2">
                <button type="submit"  class="customer-info-button-reverse" (click)="submit()">
                    {{button}}
                </button>  
            </div>
        </div>
    </div>
</app-modal-data-min>