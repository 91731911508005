<app-appointments-header slug="es"></app-appointments-header>

<div class="max-w-7xl mx-auto px-4 sm:px-6 lg:px-8">
    <div class="max-w-3xl mx-auto">
        <div >
            <div class="flex-1 flex flex-col p-8">
                <img class="w-32 h-32 flex-shrink-0 mx-auto bg-white rounded-full"
                    [src]="srcImage"
                    alt="" />
                
                <div class="text-center py-4">
                    {{txt}}
                </div>

            </div>
            <div class="grid grid-cols-2 gap-8 p-3" [@enterAnimation] *ngIf="allLoading;">
                <div class="col-span-2">
                    <div ngClass="{{class}}" [formGroup]="customerExternalForm">
                        <div class="col-span-2 lg:col-span-1">
                            <app-label-form for="name" name="{{ 'customer_create.name' | translate }}"></app-label-form>
                            <input formControlName="name" class="inputClass" id="name" type="text"
                                [ngClass]="{ 'border border-red-500': submitted && f.name.errors }"
                                placeholder="{{ 'customer_create.name' | translate }}">
                            <ng-container *ngIf="submitted && f.name.errors">
                                <app-error-form *ngIf="f.name.errors.required" error="{{'errors.required' |
                                    translate}}">
                                </app-error-form>
                                <app-error-form *ngIf="f.name.errors.maxlength"
                                    error="{{'errors.maxLength' | translate:{number:
                                    validationMaxString.short_string} }}">
                                </app-error-form>
                            </ng-container>
                        </div>
                    
                        <div class="col-span-2 lg:col-span-1">
                            <app-label-form for="name" name="{{ 'customer_create.surnames' | translate }}">
                            </app-label-form>
                    
                            <input formControlName="surnames" class="inputClass" id="surnames" type="text"
                                [ngClass]="{ 'border border-red-500': submitted && f.surnames.errors }"
                                placeholder="{{ 'customer_create.surnames' | translate }}">
                            <ng-container *ngIf="submitted && f.surnames.errors">
                                <app-error-form *ngIf="f.surnames.errors.required" error="{{'errors.required' |
                                    translate}}">
                                </app-error-form>
                                <app-error-form *ngIf="f.surnames.errors.maxlength"
                                    error="{{'errors.maxLength' | translate:{number:
                                    validationMaxString.long_string} }}">
                                </app-error-form>
                            </ng-container>
                        </div>
                    
                        <div class="col-span-2 lg:col-span-1">
                            <app-label-form for="zip" name="{{ 'customer_create.zip' | translate }}"></app-label-form>
                            <input formControlName="zip" class="inputClass" id="zip"
                                type="text" [ngClass]="{ 'border border-red-500': submitted && f.zip.errors }"
                                placeholder="{{ 'customer_create.zip' | translate }}" min="0">
                            <ng-container *ngIf="submitted && f.zip.errors">
                                <app-error-form *ngIf="f.zip.errors.required" error="{{'errors.required' |
                                    translate}}">
                                </app-error-form>
                            </ng-container>
                        </div>

                        <div class="col-span-2 lg:col-span-1">
                            <app-label-form for="typeA" name="¿Ir a una tienda o desde casa?">
                            </app-label-form>
                            <select formControlName="typeA" class="inputClass" id="typeA" [ngClass]="{ 'border border-red-500': submitted && f.typeA.errors }">
                                <option hidden selected value="">
                                    Digital o tienda
                                </option>
                                <option value="1">
                                    Desde casa
                                </option>
                                <option value="2">
                                    Ir a la tienda
                                </option>
                            </select>
                            <ng-container *ngIf="submitted && f.typeA.errors">
                                <app-error-form *ngIf="f.typeA.errors.required"
                                    error="{{'errors.required' | translate}}">
                                </app-error-form>
                            </ng-container>
                        </div>

                        <div class="col-span-2 lg:col-span-1">
                            <app-label-form for="mobile" name="{{ 'customer_create.mobile' | translate }}">
                            </app-label-form>
                            <input formControlName="mobile" class="inputClass" id="mobile" 
                                [ngClass]="{ 'border border-red-500': submitted && f.mobile.errors }"
                                type="number" placeholder="{{ 'customer_create.mobile' | translate }}" min="0">
                    
                            <ng-container *ngIf="submitted && (f.mobile.errors || f.prefix.errors)">
                                <app-error-form error="{{'errors.required' | translate}}">
                                </app-error-form>
                            </ng-container>
                        </div>

                        <div class="col-span-2">
                            <div class="relative flex items-start">
                                <div class="flex items-center h-5">
                                    <input id="comments" name="comments" type="checkbox"
                                        formControlName="terms"
                                        class="focus:ring-nutriwell h-4 w-4 text-nutriwell border-gray-300
                                        rounded">
                                </div>
                                <div class="ml-3 text-sm">
                                    <label for="comments" class="font-medium text-gray-700 cursor-pointer"
                                        (click)="showTerms()">
                                        <span innerHTML="{{ 'customer_create.terms' | translate }}"></span>
                                    </label>
                                </div>
                    
                            </div>
                            <ng-container *ngIf="submitted && f.terms.errors">
                                <app-error-form *ngIf="f.terms.errors.required"
                                    error="{{'errors.required' | translate}}">
                                </app-error-form>
                            </ng-container>
                        </div>
                    
                        <div class="col-span-2 flex flex-row-reverse">
                            <span class="shadow-sm rounded-md">
                                <button type="button" class="customer-info-button-reverse" (click)="onSubmit()">
                                    <img class="customer-info-button-svg"
                                        src="assets/images/svg/check-white.svg" />
                    
                                    Enviar
                                </button>
                            </span>
                        </div>
                    </div>
                </div>
            </div>
        </div>
    </div>
</div>

<ng-template #loading>
    <app-loading></app-loading>
</ng-template>