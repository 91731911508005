<main class="max-w-7xl mx-auto pb-10 lg:py-12 lg:px-8">
    <div class="lg:grid lg:grid-cols-12 lg:gap-x-5">
        <aside class="py-6 px-2 sm:px-6 lg:py-0 lg:px-0 lg:col-span-3">
            <nav class="space-y-1">
                <a class="text-gray-900 hover:text-gray-900 hover:bg-gray-50 group rounded-md px-3 py-2 flex items-center text-sm font-medium">
                    <svg class="text-gray-400 group-hover:text-gray-500 flex-shrink-0 -ml-1 mr-3 h-6 w-6" xmlns="http://www.w3.org/2000/svg" fill="none" viewBox="0 0 24 24" stroke-width="2" stroke="currentColor" aria-hidden="true">
                        <path stroke-linecap="round" stroke-linejoin="round" d="M5.121 17.804A13.937 13.937 0 0112 16c2.5 0 4.847.655 6.879 1.804M15 10a3 3 0 11-6 0 3 3 0 016 0zm6 2a9 9 0 11-18 0 9 9 0 0118 0z" />
                    </svg>
                    <span class="truncate" (click)="showProfile = true; showBlocked = false"> 
                        {{ 'profile.profile' | translate }}    
                    </span>
                </a>

                <a class="text-gray-900 hover:text-gray-900 hover:bg-gray-50 group rounded-md px-3 py-2 flex items-center text-sm font-medium">
                    <svg class="text-gray-400 group-hover:text-gray-500 flex-shrink-0 -ml-1 mr-3 h-6 w-6" xmlns="http://www.w3.org/2000/svg" fill="none" viewBox="0 0 24 24" stroke-width="2" stroke="currentColor" aria-hidden="true">
                        <path stroke-linecap="round" stroke-linejoin="round" d="M10.325 4.317c.426-1.756 2.924-1.756 3.35 0a1.724 1.724 0 002.573 1.066c1.543-.94 3.31.826 2.37 2.37a1.724 1.724 0 001.065 2.572c1.756.426 1.756 2.924 0 3.35a1.724 1.724 0 00-1.066 2.573c.94 1.543-.826 3.31-2.37 2.37a1.724 1.724 0 00-2.572 1.065c-.426 1.756-2.924 1.756-3.35 0a1.724 1.724 0 00-2.573-1.066c-1.543.94-3.31-.826-2.37-2.37a1.724 1.724 0 00-1.065-2.572c-1.756-.426-1.756-2.924 0-3.35a1.724 1.724 0 001.066-2.573c-.94-1.543.826-3.31 2.37-2.37.996.608 2.296.07 2.572-1.065z" />
                        <path stroke-linecap="round" stroke-linejoin="round" d="M15 12a3 3 0 11-6 0 3 3 0 016 0z" />
                    </svg>
                    <span (click)="showProfile = false; showBlocked = true">
                        Cambiar tus días o periodos bloqueados
                    </span>
                </a>
            </nav>
        </aside>

        <!-- Payment details -->
        <div class="space-y-6 sm:px-6 lg:px-0 lg:col-span-9">
            <!-- Profile -->
            <section *ngIf="showProfile">
                <div class="shadow sm:rounded-md sm:overflow-hidden">
                    <div class="bg-white py-6 px-4 sm:p-6" [formGroup]="userForm">
                        <div>
                            <h2 id="payment-details-heading" class="text-lg leading-6 font-medium text-gray-900">
                                {{ 'profile.profile' | translate }}
                            </h2>
                        </div>

                        <div class="mt-6 grid grid-cols-4 gap-6">
                            <div class="col-span-4 sm:col-span-2">
                                <app-label-form for="name" name="{{ 'customer_create.name' | translate }}"></app-label-form>
                                <input formControlName="name" class="inputClass" id="name" type="text"
                                    [ngClass]="{ 'border border-red-500': submitted && f.name.errors }"
                                    placeholder="{{ 'customer_create.name' | translate }}">
                                <ng-container *ngIf="submitted && f.name.errors">
                                    <app-error-form *ngIf="f.name.errors.required" error="{{'errors.required'
                                        | translate}}">
                                    </app-error-form>
                                    <app-error-form *ngIf="f.name.errors.maxlength"
                                        error="{{'errors.maxLength' | translate:{number:
                                        validationMaxString.short_string} }}">
                                    </app-error-form>
                                </ng-container>
                            </div>

                            <div class="col-span-4 sm:col-span-2">
                                <app-label-form for="email" name="{{ 'customer_create.email' | translate }}"></app-label-form>
                                <input formControlName="email" class="inputClass" id="email" type="text"
                                    [ngClass]="{ 'border border-red-500': submitted && f.email.errors }"
                                    placeholder="{{ 'customer_create.email' | translate }}">
                                <ng-container *ngIf="submitted && f.email.errors">
                                    <app-error-form *ngIf="f.email.errors.required"
                                        error="{{'errors.required' | translate}}">
                                    </app-error-form>
                                    <app-error-form *ngIf="f.email.errors.maxlength"
                                        error="{{'errors.maxLength' | translate:{number:
                                        validationMaxString.short_string} }}">
                                    </app-error-form>
                                </ng-container>
                            </div>

                            <div class="col-span-4 sm:col-span-2">
                                <app-label-form for="start_hour" name="{{ 'profile.start' | translate }}"></app-label-form>
                                <ng2-flatpickr formControlName="start_hour" #start_hour addClass="inputClass"
                                    [ngClass]="{ 'border border-red-500': submitted && f.start_hour.errors }"
                                    [config]="dateOptionsTimeS" (change)="changeHour(1, $event)"
                                    placeholder="{{ 'profile.start' | translate }}">
                                </ng2-flatpickr>
                                
                                <ng-container *ngIf="submitted && f.start_hour.errors">
                                    <app-error-form *ngIf="f.start_hour.errors.required"
                                        error="{{'errors.required' | translate}}">
                                    </app-error-form>
                                </ng-container>
                            </div>

                            <div class="col-span-4 sm:col-span-2">
                                <app-label-form for="finish_hour" name="{{ 'profile.finish' | translate }}"></app-label-form>
                                <ng2-flatpickr formControlName="finish_hour" #finish_hour addClass="inputClass"
                                    [ngClass]="{ 'border border-red-500': submitted && f.finish_hour.errors }"
                                    [config]="dateOptionsTimeF" (change)="changeHour(2, $event)"
                                    placeholder="{{ 'profile.finish' | translate }}">
                                </ng2-flatpickr>
                                
                                <ng-container *ngIf="submitted && f.finish_hour.errors">
                                    <app-error-form *ngIf="f.finish_hour.errors.required"
                                        error="{{'errors.required' | translate}}">
                                    </app-error-form>
                                </ng-container>
                            </div>

                            <div class="col-span-4 sm:col-span-2">
                                <app-label-form for="phone" name="{{ 'profile.phone' | translate }}"></app-label-form>
                                <input formControlName="phone" class="inputClass" id="phone"
                                    type="text"
                                    [ngClass]="{ 'border border-red-500': submitted && f.phone.errors }"
                                    placeholder="{{ 'profile.phone' | translate }}">
                                <ng-container *ngIf="submitted && f.phone.errors">
                                    <app-error-form *ngIf="f.phone.errors.required"
                                        error="{{'errors.required' | translate}}">
                                    </app-error-form>
                                </ng-container>
                            </div>

                            <div class="col-span-4 sm:col-span-2">
                                <app-label-form for="image" name="{{ 'profile.image' | translate }}"></app-label-form>
                                <input formControlName="image" class="inputClass" id="image" accept="image/*"
                                    (change)="handleFileInput($event.target.files)"
                                    type="file" placeholder="{{ 'profile.image' | translate }}">
                            </div>
                            <div class="col-span-4 sm:col-span-2">
                                <app-label-form for="calendar_view_config" name="{{ 'data.ingredients.form.measure' | translate }}"></app-label-form>
                                <select formControlName="calendar_view_config" class="bg-white inputClass" id="calendar_view_config">
                                    <option hidden selected value="">
                                        {{ 'customer_edit.medical.build' | translate }}</option>
                                        <option selected value="Day">Day</option>
                                        <option selected value="Week">Week</option>
                                        <option selected value="Month">Month</option>
                                </select>
                                <ng-container *ngIf="submitted && f.calendar_view_config.errors">
                                    <app-error-form *ngIf="f.calendar_view_config.errors.required"
                                        error="{{'errors.required' | translate}}">
                                    </app-error-form>
                                </ng-container>
                            </div>
                            <div class="col-span-4 sm:col-span-2">
                                <app-label-form name="{{ 'profile.days' | translate }}"></app-label-form>
                                <div class="flex flex-col">
                                    <label class="flex inline-flex items-center" *ngFor="let day of days">
                                        <input type="checkbox" class="ml-2 form-checkbox text-nutriwell
                                            focus:border-nutriwell focus:ring-2 focus:ring-nutriwell
                                            focus:ring-opacity-50 focus:ring-offset-0" [checked]="day.selected ?
                                            true : false" (change)="changeDaySelected(day)">
                                        <span class="ml-2 text-nutriwell">{{day.text | translate}}</span>
                                    </label>
                                </div>
                                <ng-container *ngIf="submitted && f.days.errors">
                                    <app-error-form *ngIf="f.days.errors.required"
                                        error="{{'errors.required' | translate}}">
                                    </app-error-form>
                                </ng-container>
                            </div>
                           
                        </div>
                    </div>
                    <div class="px-4 py-3 bg-gray-50 text-right sm:px-6">
                        <button type="button" class="customer-info-button-reverse" (click)="submit()">
                            <img src="assets/images/svg/check-white.svg"
                                class="customer-info-button-svg" alt="check">
                            {{ 'customer_edit.save' | translate }}
                        </button>
                    </div>
                </div>
            </section>

            <!-- Blocked -->
            <section *ngIf="showBlocked">
                <div class="bg-white py-6 px-4 space-y-6 sm:p-6">
                    <div>
                        <h2 id="payment-details-heading" class="text-lg leading-6 font-medium text-gray-900">
                            Cambiar tus días o periodos bloqueados
                        </h2>
                    </div>
                    <div class="mt-5 md:mt-0 md:col-span-2">
                        <div class="pb-2">
                            <app-label-form for="name" name="Días enteros bloqueados"></app-label-form>
                            <ng2-flatpickr #calendar addClass="inputClass"
                                [config]="dateOptions"
                                placeholder="Días enteros bloqueados">
                            </ng2-flatpickr>
                        </div>
                        
                        <div class="pb-2 text-right">
                            <button type="button" class="customer-info-button-reverse" (click)="submitConfigDates()">
                                <img src="assets/images/svg/check-white.svg"
                                    class="customer-info-button-svg" alt="check">
                                {{ 'customer_edit.save' | translate }}
                            </button>
                        </div>
                        
                        <div class="pb-2">
                            <app-label-form for="email" name="Bloqueo único por horas"></app-label-form>
                            <div class="mt-5 border-t border-gray-200">
                                <dl class="divide-y divide-gray-200">
                                    <div class="py-4 sm:py-5 sm:grid sm:grid-cols-3 sm:gap-4" *ngFor="let block of blockDays">
                                        <dt class="text-sm font-medium text-gray-500">
                                        {{block.day}}
                                        </dt>
                                        <dd class="mt-1 flex text-sm text-gray-900 sm:mt-0 sm:col-span-2">
                                        <span class="flex-grow">
                                            {{block.start_hour}} - {{block.finish_hour}}
                                        </span>
                                        <span class="ml-4 flex-shrink-0 couser-pointer" (click)="deleteBlockDay(block.id)">
                                            <img src="assets/images/svg/x-circle.svg" class="customer-info-button-svg" alt="check">
                                        </span>
                                        </dd>
                                    </div>
                                </dl>
                            </div>
                        </div>
                        
                        <div class="pb-2">
                            <app-label-form for="start_hour" name="Bloqueo semanal por horas"></app-label-form>
                            <div class="mt-5 border-t border-gray-200">
                                <dl class="divide-y divide-gray-200">
                                    <div class="py-4 sm:py-5 sm:grid sm:grid-cols-3 sm:gap-4" *ngFor="let week of blockDaysWeek">
                                        <dt class="text-sm font-medium text-gray-500">
                                        {{ getName(week.week_day) }}
                                        </dt>
                                        <dd class="mt-1 flex text-sm text-gray-900 sm:mt-0 sm:col-span-2">
                                        <span class="flex-grow">
                                            {{week.start_hour}} - {{week.finish_hour}}
                                        </span>
                                        <span class="ml-4 flex-shrink-0 courser-pointer" (click)="deleteBlockWeek(week.id)">
                                            <img src="assets/images/svg/x-circle.svg" class="customer-info-button-svg" alt="check">
                                        </span>
                                        </dd>
                                    </div>
                                </dl>
                            </div>
                        </div>
                    </div>
                </div>
            </section>
        </div>
    </div>
</main>