import { Component, OnInit, Input, Output } from "@angular/core";


@Component({
    selector: 'app-show-order-modal',
    templateUrl: './show-order-modal.component.html'
})

export class ShowOrderModalComponent implements OnInit {

    @Input() title: string;
    @Input() name: string;
    @Input() quantity: string;
    @Input() items;
    @Output() closeModal;


    ngOnInit(): void {
        
    }

    closeModalF() {
        this.closeModal();
    }
}