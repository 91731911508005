<app-modal-data [title]="title" (closeModal)="closeModalF()">
    <div data class="w-full">
        <h1 class="text-center mb-2">
            {{'terms_modal.use.title' | translate}}
        </h1>
        <p class="text-justify">
            {{'terms_modal.use.text' | translate}}
        </p>

        <h1 class="text-center my-2">
            {{'terms_modal.security.title' | translate}}
        </h1>
        <p class="text-justify">
            {{'terms_modal.security.text' | translate}}
        </p>


        <h1 class="text-center my-2">
            {{'terms_modal.general.title' | translate}}
        </h1>
        <p class="text-justify">
            {{'terms_modal.general.text' | translate}}
        </p>

        <p class="mt-3 text-justify">
            {{'terms_modal.text-bottom' | translate}}
        </p>
    </div>
</app-modal-data>