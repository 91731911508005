import { HttpClient } from '@angular/common/http';
import { Injectable } from '@angular/core';
import { Router } from '@angular/router';
import { NgxRolesService, NgxPermissionsService } from 'ngx-permissions';
import { map } from 'rxjs/operators';
import { environment } from 'src/environments/environment';
import { User } from '../interfaces/user.interface';
import { Roles } from '../settings/rol';

@Injectable()
export class AuthenticationGeneralService {
    data;

    constructor(
        private router: Router, 
        private rolesService: NgxRolesService,
        private permissionsService: NgxPermissionsService,
        private http: HttpClient
    ) {
        if (this.isLoggedIn()) {
            this.data = this.getUser();
        }
    }

    getUser(): User {
        return JSON.parse(localStorage.getItem('currentUser'));
    }

    getUserVariable(name) {
        const user = this.getUser();
        return user[name];
    }

    setToken(user: User) {
        localStorage.setItem('currentUser', JSON.stringify(user));
    }

    updateUser(user: User) {
        let currentUser = this.getUser();

        const newUser = { ...currentUser, ...user };

        localStorage.setItem('currentUser', JSON.stringify(newUser));
    }

    isLog() {
        const data = this.getUser();
        if (!data) {
            return false;
        }
        return true;
    }

    isLoggedIn() {
        const data = this.getUser();
        if (!data) {
            return false;
        }

        const user: User = this.getUser();

        const currentTeam = user.teams;

        const rol = Roles.roles.filter(x => x.id == currentTeam.rol)[0];
        if (typeof rol == 'undefined') {
			localStorage.setItem('version', 'v1');
			localStorage.removeItem('country');
			localStorage.removeItem('currentUser');
            window.open('/', "_self");
		}

        // refresh permissions
        this.permissionsService.flushPermissions();
        this.permissionsService.loadPermissions(rol.permissions);

        // refresh roles
        this.rolesService.flushRoles();
        this.rolesService.addRole(rol.name, rol.permissions);

        return true;
    }

    clearUser() {
        localStorage.removeItem('currentUser');
    }

    logout() {
        this.clearUser();
        this.router.navigate(['/login']);
    }

    
    setTokenNotification(token: string) {
        return this.http
            .post(`${environment.apiUrl}/user-token`, {
                token: token
            });
    }
}
