<div class="w-full rounded bg-white" *ngIf="ready">
    <div class="relative flex items-center p-3 border-b border-gray-300">
        <img class="object-cover w-10 h-10 rounded-full"
        src="assets/images/flor-circle.png" alt="username" />
        <span class="block ml-2 font-bold text-gray-600">Naturhouse</span>
    </div>
    <div #scroll (scroll)="onScroll($event)" class="relative w-full p-6 overflow-y-auto h-[40rem] max-h-80">
		<ul class="space-y-2">
			<li *ngIf="loadingMore">
				<div class="text-center">
					...
				</div>
			</li>
			<li class="flex" *ngFor="let message of messages"
			[ngClass]="{ 'justify-start': 2 === message.type, 'justify-end': 2 !== message.type }"
			>
				<div class="relative max-w-xl px-4 py-2 rounded shadow"
				[ngClass]="{ 'text-white bg-nutriwell-sansivera': 2 === message.type, 'text-gray-700 bg-gray-100': 2 !== message.tyep }"
				>
					<span class="block">
						<p>{{message.text}}</p>
					</span>
				</div>
			</li>
        </ul>
    </div>

    <div class="flex items-center justify-between w-full p-3 border-t border-gray-300">
		<input type="text" placeholder="Message" 
        	class="block w-full py-2 pl-4 mx-3 bg-gray-100 rounded-full focus:text-gray-700"
			(ngModelChange)="modelChangeFn($event)"
        	name="message" [(ngModel)]="message" required />
      	<button (click)="sendMessage()">
       		<svg class="w-5 h-5 text-gray-500 origin-center transform rotate-90" xmlns="http://www.w3.org/2000/svg"
          	viewBox="0 0 20 20" fill="currentColor">
          	<path
            	d="M10.894 2.553a1 1 0 00-1.788 0l-7 14a1 1 0 001.169 1.409l5-1.429A1 1 0 009 15.571V11a1 1 0 112 0v4.571a1 1 0 00.725.962l5 1.428a1 1 0 001.17-1.408l-7-14z" />
        	</svg>
      	</button>
    </div>
	<div class="flex items-center justify-between w-full p-3 border-t border-gray-300" *ngIf="showMessageLimit">
		Máximo 150 characteres
	</div>
</div>
