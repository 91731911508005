import { Component, OnInit, OnDestroy, Input, Output, EventEmitter } from "@angular/core";
import { FormGroup, FormBuilder, Validators } from "@angular/forms";
import { Subscription } from "rxjs";
import { SettingGeneralService } from "src/app/shared/services/settings-general.service";
import { Validations } from "src/app/shared/settings/validation";
import { Ingredient } from "../ingredient.interace";
import { IngredientService } from "../ingredient.service";
import { v4 as uuidv4 } from 'uuid';
import { LocaleName } from "src/app/shared/interfaces/localeText.interface";
import { Locale } from "src/app/shared/enum/locale.enum";
import { SnackbarService } from "src/app/shared/components/snackbar/snackbar.service";


@Component({
    selector: 'app-ingredient-form',
    templateUrl: './ingredient-form.component.html',
    styleUrls: ['./ingredient-form.component.scss']
})

export class IngredientFormComponent implements OnInit, OnDestroy {

    @Input() ingredient: Ingredient;
    @Output() createOrUpdateList = new EventEmitter<{isNew: boolean, ingredient: Ingredient}>();

    ingredientForm: FormGroup;
    submitted = false;

    validationMaxString = Validations.validationMaxString;

    measures: string[] = ['gr', 'ml'];

    groupsObject;
    groups = [];

    private subscription = new Subscription();

    isNew: boolean = true;

    title: string;
    subtitle: string;

    locales: LocaleName[] = [];

    constructor(
        private builder: FormBuilder,
        private setting: SettingGeneralService,
        private ingredientService: IngredientService,
        private snackbarService: SnackbarService
    ) {
        
    }

    get f() { return this.ingredientForm.controls; }

    ngOnInit(): void {

        for (const value in Locale) {
            this.locales.push({
                locale: value,
                value: ''
            });
        }
        

        this.groupsObject = this.setting.getLangText('data.groups');
        for (const [key, value] of Object.entries(this.groupsObject)) {
            this.groups.push({id: key, text: value});
            
        }
        
        this.ingredientForm = this.builder.group({
            id: [uuidv4()],
            locales: this.locales,
            quantity: [null, [Validators.required]],
            group: [null, [Validators.required]],
            measure: ['gr', [Validators.required]],
            
            calories: [0.0, [Validators.required]],
            fat: [0.0, [Validators.required]],
            carbohydrates: [0.0, [Validators.required]],
            proteins: [0.0, [Validators.required]],
            
            cholesterol: [0.0, [Validators.required]],
            fiber: [0.0, [Validators.required]],
            sodium: [0.0, [Validators.required]],
            
            water: [0.0, [Validators.required]],
            vitaminA: [0.0, [Validators.required]],
            vitaminB6: [0.0, [Validators.required]],

            vitaminB12: [0.0, [Validators.required]],
            vitaminC: [0.0, [Validators.required]],
            vitaminD: [0.0, [Validators.required]],
            
            vitaminE: [0.0, [Validators.required]],
            sugars: [0.0, [Validators.required]],
            folicAcid: [0.0, [Validators.required]],

            calcium: [0.0, [Validators.required]],
            potassium: [0.0, [Validators.required]],
            phosphorus: [0.0, [Validators.required]],

            iron: [0.0, [Validators.required]],
            magnesium: [0.0, [Validators.required]],
            zinc: [0.0, [Validators.required]],
            
            thiamin: [0.0, [Validators.required]],
            riboflavin: [0.0, [Validators.required]],
            niacin: [0.0, [Validators.required]]
        });

        if(this.ingredient) {
            this.isNew = false;
            this.ingredientForm.patchValue(this.ingredient);

            this.title = 'data.ingredients.edit.title';
            this.subtitle = 'data.ingredients.edit.subtitle';
            
            this.matchLocales(this.ingredient.locales);
        } else {
            this.title = 'data.ingredients.create.title';
            this.subtitle = 'data.ingredients.create.subtitle';
        }
    }

    private matchLocales(ingredientLocales) {
        ingredientLocales.forEach(element => {
            this.locales.map(locale => {
                if(locale.locale == element.locale) {
                    locale.value = element.value;
                }
            })
        });
    }

    submit() {
        this.submitted = true;

        if(this.ingredientForm.invalid) {
            return ;
        }

        const localeNotEmpty  = this.locales.filter(locale => locale.value != '');
        this.ingredientForm.patchValue({locales: localeNotEmpty});
        this.subscription.add(
            this.ingredientService.createOrUpdateIngredient(this.ingredientForm.value, this.isNew).subscribe(
                () => {
                    this.createOrUpdateList.emit({
                        isNew: this.isNew,
                        ingredient:this.ingredientForm.value
                    });
                    this.snackbarService.show('Ingredient saved successfully', 'success');
                },
                (error) =>this.snackbarService.show('Someting was wrong, retry in a minutes', 'danger')
            )
        )
    }

    ngOnDestroy(): void {
        if(this.subscription){
            this.subscription.unsubscribe();
        }
    }
}