import { HttpClient } from "@angular/common/http";
import { Injectable } from "@angular/core";
import { map } from "rxjs/operators";
import { SettingGeneralService } from "src/app/shared/services/settings-general.service";
import { environment } from 'src/environments/environment';

@Injectable({
    providedIn: 'root'
})
export class ProfileService {


    constructor(
        private http: HttpClient,
        private settingGeneralService: SettingGeneralService
    ) { }

    updateProfile(profile, image: File) {

        const formData: FormData = new FormData();
        formData.append('_method', 'PUT');
        formData.append('days', JSON.stringify(profile.days));
        formData.append('email', profile.email);
        formData.append('finish_hour', profile.finish_hour);
        formData.append('id', profile.id);
        formData.append('name', profile.name);
        formData.append('password', profile.password);
        formData.append('start_hour', profile.start_hour);
        formData.append('phone', profile.phone);
        formData.append('calendar_view_config', profile.calendar_view_config);
        if (image) {
            formData.append('image', image, image.name);
        }

        return this.http
            .post(`${environment.apiUrl}/profile/${profile.id}`, formData)
            .pipe(map((response: any) => {

                return response.data;
            }));
    }

    saveGoogleCalendarEvents(id: number, events) {
        return this.http
            .post(`${environment.apiUrl}/profile-google-calendar/${id}`, events)
            .pipe(map((response: any) => {
                return response.data;
            }));
    }

    updateConfigDates(id:number, dates) {
        
        const formData: FormData = new FormData();
        formData.append('dates', dates);
        
        
        return this.http
            .post(`${environment.apiUrl}/user/dates/${id}`, formData)
            .pipe(map((response: any) => {
                return response;
            }));
    }
}