import { Component, Input, OnDestroy, Output } from '@angular/core';
import { Subscription } from 'rxjs';
import { SettingGeneralService } from 'src/app/shared/services/settings-general.service';
import { CustomerService } from 'src/app/shared/services/customer.service';
import { SnackbarService } from 'src/app/shared/components/snackbar/snackbar.service';


@Component({
    selector: 'app-customer',
    templateUrl: './customer.component.html'
})

export class CustomerComponent implements OnDestroy {

    @Input() title: string;
    @Input() text: string;
    @Input() text_button_register: string;
    @Output() closeModal;

    private subscription = new Subscription();

    class: string = "grid grid-cols-2 gap-8 p-3";

    constructor(
        public settingGeneralService: SettingGeneralService,
        private customerService: CustomerService,
        private snackbarService: SnackbarService,
    ) { }

    onSubmit($event) {
        this.subscription.add(this.customerService.saveCustomer($event).subscribe(
            (response) => {
                this.showSnackBar('Customer created successfully.', 'success');
            },
            (error) => {
                this.showSnackBar('Algo ha pasado ....', 'danger');
            }
        ));
    }

    showSnackBar(text: string, _class: string) {
        this.closeModalF();
        this.snackbarService.show(text, _class);
    }

    closeModalF() {
        this.closeModal();
    }

    ngOnDestroy() {
        this.subscription.unsubscribe();
    }
}