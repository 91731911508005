import { HttpClient } from "@angular/common/http";
import { Injectable } from "@angular/core";
import { map } from "rxjs/operators";
import { Block } from "src/app/shared/interfaces/block.interface";
import { environment } from "src/environments/environment";

@Injectable({
    providedIn: 'root'
})
export class BlockService {

    constructor(private http: HttpClient) { }

    saveBlock(block: Block) {
        return this.http
            .post(`${environment.apiUrl}/blocks`, block)
            .pipe(map((response: any) => {
                response.data;
            }));
    }

    getBlocks(userId: number) {
        return this.http
            .get(`${environment.apiUrl}/blocks/${userId}`)
            .pipe(map((response: any) => {
                return response.data;
            }));
    }

    deleteBlockDay(id: number, userId: number) {
        return this.http
            .delete(`${environment.apiUrl}/blocks/${userId}/day/${id}`)
            .pipe(map((response: any) => {
                return response.data;
            }));
    }

    deleteBlockWeek(id: number, userId: number) {
        return this.http
            .delete(`${environment.apiUrl}/blocks/${userId}/week/${id}`)
            .pipe(map((response: any) => {
                return response.data;
            }));
    }
}