import { Component, Input, Output } from "@angular/core";
import { AppointmentsService } from "src/app/external/appointments/appointments.service";
import { ConfigService } from "src/app/shared/services/config.service";


@Component({
    selector: 'app-find-zip-team',
    templateUrl: './find-team-zip.component.html'
})

export class FindTeamByZipComponent {

    @Input() title: string;
    @Input() button: string;
    @Input() id_team: number;
    @Input() zipCodesConfig: any;
    @Input() text_button_register: string;
    @Output() closeModal;

    submitted: boolean = false;
    error: boolean = false;
    prohibitedError: boolean = false;
    zipCode:string;

    constructor(
        private appointmentService: AppointmentsService,
        private configService: ConfigService
    ) {
     }

    submit() {
        if(!this.zipCode) {
            this.submitted = true;
            this.error = true;
        } else {
            if(this.zipCodesConfig.zipcodes && this.zipCodesConfig.zipcodes.includes(this.zipCode)) {
                this.prohibitedError = true;
                this.zipCode = null;
            } else {
                if(this.zipCodesConfig.length && this.zipCode.length >= this.zipCodesConfig.length) {
                    this.prohibitedError = true;
                    this.zipCode = null
                } else {
                    this.appointmentService.setZipCode(this.zipCode);
                    this.closeModalF();
                }
            }
        }
    }

    closeModalF()
    {
        this.closeModal();
    }
}