
<div class="h-screen flex overflow-hidden bg-white">
    <!-- menu mobile -->
    <div [ngClass]="{ hidden:isOpenMobile}" class="lg:hidden">
        <div class="fixed inset-0 flex z-40">

            <div class="fixed inset-0">
                <div class="absolute inset-0 bg-gray-600 opacity-75">

                </div>
            </div>

            <div class="relative flex-1 flex flex-col max-w-xs w-full pt-5 pb-4 bg-nutriwell">
                <div class="absolute top-0 right-0 -mr-14 p-1">
                    <button (click)="isOpenMobile= !isOpenMobile"
                        class="flex items-center justify-center h-12 w-12 rounded-full
                        focus:outline-none focus:bg-gray-600"
                        aria-label="Close sidebar">
                        <img src="assets/images/svg/x-white.svg" class="w-6 h-6"
                            />
                    </button>
                </div>

                <div class="items-center flex-shrink-0 px-4">
                    <h3 class="text-nutriwell-sansivera text-center" (click)="showModal()"><strong>Nutri</strong>well
                    </h3>
                </div>

                <div class="mt-5 flex-1 flex flex-col">
                    <nav class="flex-1 px-2 bg-nutriwell space-y-1">
                       <a routerLink="calendar" routerLinkActive="active" class="group
                            text menu rounded-md">
                            <div class="bg-nutriwell-stone flex items-center mr-3 p-1.5
                                rounded-full">
                                <img src="assets/images/svg/calendar-white.svg" class="w-6 h-6" />
                            </div>
                            {{ 'dashboard.calendar' | translate }}
                        </a>

                        <a routerLink="customers" routerLinkActive="active" class="group text
                            menu rounded-md">
                            <div class="bg-nutriwell-stone flex items-center mr-3 p-1.5
                                rounded-full">
                                <img src="assets/images/svg/customer-white.svg" class="w-6 h-6"
                                    />
                            </div>
                            {{ 'dashboard.customers' | translate }}
                        </a>
                        <a *ngIf="show" routerLink="chats" routerLinkActive="active" class="group text
                            menu rounded-md">
                            <div class="bg-nutriwell-stone flex items-center mr-3 p-1.5
                                rounded-full">
                                <img src="assets/images/svg/chat.svg" class="w-6 h-6"
                                    />
                            </div>
                            <ng-container *ngIf="appService.hasNewMessage()">
                                <svg xmlns="http://www.w3.org/2000/svg" class="h-5 w-5" viewBox="0 0 20 20" fill="currentColor">
                                    <path d="M10 2a6 6 0 00-6 6v3.586l-.707.707A1 1 0 004 14h12a1 1 0 00.707-1.707L16 11.586V8a6 6 0 00-6-6zM10 18a3 3 0 01-3-3h6a3 3 0 01-3 3z" />
                                </svg>
                            </ng-container>
                            Chats
                        </a>
                    </nav>
                </div>

                <div class="flex-col flex border-t border-nutriwell-stone pt-4">

                    <a (click)="createAppointment()" class="group text menu active">
                        <div class="bg-nutriwell-stone flex items-center mr-3 p-1.5
                            rounded-full">
                            <img src="assets/images/svg/plus-circle-white.svg" class="w-6 h-6"
                                />
                        </div>
                        {{ 'dashboard.new_appointment' | translate }}
                    </a>

                    <a (click)="createCustomer()" class="group text menu
                        bg-nutriwell-mustela">
                        <div class="bg-nutriwell-stone flex items-center mr-3 p-1.5
                            rounded-full">
                            <img src="assets/images/svg/user-add-white.svg" class="w-6 h-6"
                                />
                        </div>
                        {{ 'dashboard.new_customer' | translate }}
                    </a>
                </div>
            </div>

            <div class="flex-shrink-0 w-14">
                <!-- Dummy element to force sidebar to shrink to fit close icon -->
            </div>

        </div>
    </div>

    <div id="modal-container" class="hidden"></div>
    <div id="overlay" (click)="closeModal()" class="hidden"></div>

    <!-- menu desktop -->
    <div class="hidden lg:flex lg:flex-shrink-0">

        <!-- long menu -->
        <div class="flex flex-col w-64" *ngIf="!showSmallNavBar">

            <!-- Sidebar component, swap this element with another sidebar if you like -->
            <div class="flex flex-col flex-grow bg-nutriwell pt-5 pb-4 overflow-y-auto">
                <div class="items-center flex-shrink-0 px-4">
                    <h3 class="text-nutriwell-sansivera text-center" (click)="showModal()"><strong>Nutri</strong>well
                    </h3>
                </div>

                <div class="mt-5 flex-1 flex flex-col">
                    <nav class="flex-1 px-2 bg-nutriwell space-y-1">
                        <a routerLink="calendar" routerLinkActive="active"
                            class="group text menu rounded-md">
                            <div class="bg-nutriwell-stone flex items-center mr-3 p-1.5
                                rounded-full">
                                <img src="assets/images/svg/calendar-white.svg" class="w-6 h-6"
                                    />
                            </div>
                            {{ 'dashboard.calendar' | translate }}
                        </a>

                        <a routerLink="customers" routerLinkActive="active" class="group text menu rounded-md">
                            <div class="bg-nutriwell-stone flex items-center mr-3 p-1.5
                                rounded-full">
                                <img src="assets/images/svg/customer-white.svg" class="w-6 h-6"
                                    />
                            </div>
                            {{ 'dashboard.customers' | translate }}
                        </a>

                        

                        <a *ngIf="show" routerLink="chats" routerLinkActive="active" class="group text menu rounded-md">
                            <div class="bg-nutriwell-stone flex items-center mr-3 p-1.5
                                rounded-full">
                                <img src="assets/images/svg/chat.svg" class="w-6 h-6" />
                            </div>
                            <div class="flex justify-between w-10/12">
                                <p>Chats</p>
                                <ng-container *ngIf="appService.hasNewMessage()">
                                    <svg xmlns="http://www.w3.org/2000/svg" class="h-5 w-5" viewBox="0 0 20 20" fill="currentColor">
                                        <path d="M10 2a6 6 0 00-6 6v3.586l-.707.707A1 1 0 004 14h12a1 1 0 00.707-1.707L16 11.586V8a6 6 0 00-6-6zM10 18a3 3 0 01-3-3h6a3 3 0 01-3 3z" />
                                    </svg>
                                </ng-container>
                            </div>
                        </a>

                        <a *ngIf="show && rol_id === 1" class="group text menu rounded-md items-stretch" (click)="showAdmin = !showAdmin">
                            <div class="bg-nutriwell-stone flex items-center mr-3 p-1.5
                                rounded-full">
                                <img src="assets/images/svg/briefcase.svg" class="w-6 h-6" />
                            </div>
                            <span class="flex-1 text-left whitespace-nowrap" sidebar-toggle-item>Admin</span>
                            <img src="assets/images/svg/chevron-down-white.svg" class="w-6 h-6" />
                        </a>
                        <ul *ngIf="showAdmin">
                            <li routerLink="ingredients" class="ml-4 text menu rounded-md">
                                <div class="bg-nutriwell-stone flex items-center mr-3 p-1.5 rounded-full">
                                    <img src="assets/images/svg/apple.svg" class="w-6 h-6" />
                                </div>
                               Ingredients
                            </li>
                            <li routerLink="recipes" class="ml-4 text menu rounded-md">
                                <div class="flex items-center mr-3 p-1.5 rounded-full">
                                    <img src="assets/images/svg/apple.svg" class="w-6 h-6" />
                                </div>
                                Meals
                            </li>
                            <li routerLink="diets" class="ml-4 text menu rounded-md">
                                <div class="flex items-center mr-3 p-1.5 rounded-full">
                                    <img src="assets/images/svg/apple.svg" class="w-6 h-6" />
                                </div>
                                Diets
                            </li>
                        </ul>
                        
                    </nav>
                </div>

                <div class="flex-col flex border-t border-nutriwell-stone pt-4">
                    <nav class="flex-1 px-2 space-y-1">
                        <a (click)="createAppointment()" class="group text menu
                            active">
                            <div class="bg-nutriwell-stone flex items-center mr-3
                                p-1.5 rounded-full">
                                <img src="assets/images/svg/plus-circle-white.svg" class="w-6
                                    h-6"
                                    />
                            </div>
                            {{ 'dashboard.new_appointment' | translate }}
                        </a>

                        <a (click)="createCustomer()" class="group text menu
                            bg-nutriwell-mustela">
                            <div class="bg-nutriwell-stone flex items-center mr-3
                                p-1.5 rounded-full">
                                <img src="assets/images/svg/user-add-white.svg" class="w-6 h-6"
                                    />
                            </div>
                            {{ 'dashboard.new_customer' | translate }}
                        </a>
                    </nav>
                </div>
            </div>
        </div>

        <!-- short menu -->
        <div class="flex flex-col w-16" *ngIf="showSmallNavBar">

            <!-- Sidebar component, swap this element with another sidebar if you like -->
            <div class="flex flex-col flex-grow bg-nutriwell pt-5 pb-4
                overflow-y-auto">

                <div class="items-center flex-shrink-0 px-4">
                    <img src="assets/images/logo" alt="Nutriwell">
                </div>

                <div class="mt-5 flex-1 flex flex-col">
                    <nav class="flex-1 px-2 bg-nutriwell space-y-1 tooltip">
                        <!-- <a [routerLink]="['/']" class="group text menu rounded-md"
                                aria-label="Click to Switch to Solid Icons">
                                <div class="bg-nutriwell-sun flex items-center mr-3
                                    p-1.5 rounded-full">
                                    <svg class="w-6 h-6" fill="none"
                                        stroke="currentColor" viewBox="0 0 24 24"
                                        xmlns="http://www.w3.org/2000/svg">
                                        <path stroke-linecap="round"
                                            stroke-linejoin="round" stroke-width="2"
                                            d="M3 12l2-2m0 0l7-7 7 7M5 10v10a1 1 0 001
                                            1h3m10-11l2 2m-2-2v10a1 1 0 01-1 1h-3m-6 0a1
                                            1 0 001-1v-4a1 1 0 011-1h2a1 1 0 011 1v4a1 1
                                            0 001 1m-6 0h6">
                                        </path>
                                    </svg>
                                </div>
                            </a> -->
                        <a routerLink="calendar" routerLinkActive="active"
                            class="group text menu rounded-md">
                            <div class="bg-nutriwell-stone flex items-center mr-3
                                p-1.5 rounded-full">
                                <img src="assets/images/svg/calendar-white.svg" class="w-6 h-6"
                                    />
                            </div>
                        </a>

                        <a routerLink="customers" routerLinkActive="active"
                            class="group text menu rounded-md">
                            <div class="bg-nutriwell-stone flex items-center mr-3
                                p-1.5 rounded-full">
                                <img src="assets/images/svg/customer-white.svg" class="w-6 h-6"
                                    />
                            </div>
                        </a>
                    </nav>
                </div>

                <div class="flex-col flex border-t border-nutriwell-stone pt-4">
                    <nav class="flex-1 px-2 bg-nutriwell space-y-1">
                        <a (click)="createAppointment()" class="group text
                            menu">
                            <div class="bg-nutriwell-stone flex items-center
                                mr-3 p-1.5 rounded-full">
                                <img src="assets/images/svg/plus-circle-white.svg" class="w-6
                                    h-6"
                                    />
                            </div>
                        </a>

                        <a (click)="createCustomer()" class="group text menu">
                            <div class="bg-nutriwell-stone flex items-center
                                mr-3 p-1.5 rounded-full">
                                <img src="assets/images/svg/user-add-white.svg" class="w-6 h-6"
                                    />
                            </div>
                        </a>
                    </nav>
                </div>
            </div>
        </div>
    </div>
</div>