import { OnDestroy, ViewChild } from '@angular/core';
import { Component, OnInit } from '@angular/core';
import { FormBuilder, FormGroup, Validators } from '@angular/forms';
import { FlatpickrOptions } from 'ng2-flatpickr';
import { Subscription } from 'rxjs';
import { LoginService } from 'src/app/auth/login/login.service';
import { SnackbarService } from 'src/app/shared/components/snackbar/snackbar.service';
import { Day } from 'src/app/shared/interfaces/day.interface';
import { User } from 'src/app/shared/interfaces/user.interface';
import { AuthenticationGeneralService } from 'src/app/shared/services/auth-general.service';
import { SettingGeneralService } from 'src/app/shared/services/settings-general.service';
import { Validations } from 'src/app/shared/settings/validation';
import { ProfileService } from './profile.service';
import * as moment from 'moment';
import { BlockService } from '../home/menu/calendar/block/block.service';
import { BlockDay } from 'src/app/shared/interfaces/blockDay.interface';

@Component({
    selector: 'app-profile',
    templateUrl: './profile.component.html',
    styleUrls: ['./profile.component.scss']
})
export class ProfileComponent implements OnInit, OnDestroy {

    @ViewChild('calendar') pickerCalendar;

    subscription: Subscription = new Subscription;
    userForm: FormGroup;
    submitted = false;

    validationMaxString = Validations.validationMaxString;

    days: Day[] = [];

    calendars = [];
    showCalendars: boolean = false;
    fileToUpload: File = null;

    dateOptions: FlatpickrOptions;
    dates: string;
    blockDays = [];
    blockDaysWeek = [];
    user_id: number;
    settingsValue;
    showCalendar:boolean = false;

    dateOptionsTimeS: FlatpickrOptions;
    dateOptionsTimeF: FlatpickrOptions;

    showProfile: boolean = true;
    showBlocked: boolean = false;

    constructor(
        private builder: FormBuilder,
        private authService: AuthenticationGeneralService,
        private snackbarService: SnackbarService,
        public settingGeneralService: SettingGeneralService,
        private profileService: ProfileService,
        private loginService: LoginService,
        private blockService: BlockService
    ) {
        this.settingGeneralService.changeCountry$.subscribe(
            () => {
                this.settingsValue = this.settingGeneralService.getSettings();
            }
        );

        this.days.push({ id: 0, text: 'week.monday' });
        this.days.push({ id: 1, text: 'week.tuesday' });
        this.days.push({ id: 2, text: 'week.wednesday' });
        this.days.push({ id: 3, text: 'week.thursday' });
        this.days.push({ id: 4, text: 'week.friday' });
        this.days.push({ id: 5, text: 'week.saturday' });
        this.days.push({ id: 6, text: 'week.sunday' });

        this.dateOptionsTimeS ={
            locale: this.settingsValue.flatpickr,
            dateFormat: 'H:i',
            enableTime: true,
            noCalendar: true,
            minuteIncrement: 20,
            onReady: function(selectedDates, dateStr, instance) {
                let result = instance.calendarContainer.getElementsByClassName("flatpickr-minute");
                Array.from(result).forEach((el: any) => {
                    el.setAttribute('readonly', 'true');
                });
            },
        };
         this.dateOptionsTimeF = Object.assign({}, this.dateOptionsTimeS);
    }


    ngOnInit(): void {
        if(localStorage.getItem('calendar')) {
            this.showCalendar = true;
        };

        const user = this.authService.getUser();
        this.user_id = user.id;
        
        this.userForm = this.builder.group({
            id: user.id,
            name: [user.name, [Validators.required, Validators.maxLength(this.validationMaxString.long_string)]],
            email: [user.email, [Validators.required, Validators.email, Validators.maxLength(this.validationMaxString.long_string)]],
            password: ['', [Validators.maxLength(this.validationMaxString.long_string)]],
            phone: [user.phone, Validators.required],
            start_hour: [{ 0: moment(user.start_hour, 'HH:mm:ii').format('HH:mm') }, [Validators.required]],
            finish_hour: [{0: moment(user.finish_hour, 'HH:mm:ii').format('HH:mm')}, [Validators.required]],
            days: [null, [Validators.required, Validators.minLength(1)]],
            image: [null, null],
            calendar_view_config: [user.calendar_view_config, Validators.required]
        });
        

        this.dateOptionsTimeS.defaultDate = user.start_hour.toString();
        this.dateOptionsTimeF.defaultDate = user.finish_hour.toString();

        const currentDays = JSON.parse(user.days);

        currentDays.forEach(element => {
            this.days.map((days, index) => {
                if (days.id == element) {
                    this.days[index].selected = true;
                }
            });
        });

        this.subscription.add(this.blockService.getBlocks(this.user_id).subscribe(
            (response) => {
                this.dateOptions = {
                    locale: this.settingsValue.flatpickr,
                    dateFormat: this.settingsValue.formatFlatpickr,
                    minDate: moment().format(this.settingsValue.formatMoment),
                    disableMobile: true,
                    mode: "multiple",
                    defaultDate: this.parseBlockDates(response.config.dates)
                };
                this.blockDays = this.parseBlockDays(response.blockDays);

                this.blockDaysWeek = response.blockWeekDays;
            }
        ));
    } 

    handleFileInput(files: FileList) {
        this.fileToUpload = files.item(0);
    }

    get f() { return this.userForm.controls; }

    changeHour(type, event){
        const target = event.target.value;
 
        this.userForm.patchValue([1=== type ? 'start_hour' : 'finish_hour', {0: target}]);
    }

    changeFinishHour(event){
        this.userForm.patchValue(['finish_hour', {0: event}]);
    }

    submit() {
        this.submitted = true;

        const getSelectedDays = (days) => {
            return days.reduce((results, day) => {
                if (day?.selected) {
                    results.push(day.id);
                }
                return results;
            }, []);
        };

        const selectedDays = getSelectedDays(this.days);

        if (selectedDays.length) {
            this.userForm.controls['days'].setValue(selectedDays);
        }

        if (this.userForm.invalid) {
            return;
        }

        let profile = this.userForm.getRawValue();
        
        profile.start_hour = profile.start_hour[0] instanceof Object ? 
        moment(profile.start_hour[0]).format('HH:mm') : profile.start_hour[0];
        profile.finish_hour = profile.finish_hour[0] instanceof Object ? 
        moment(profile.finish_hour[0]).format('HH:mm') : profile.finish_hour[0];

        this.subscription.add(this.profileService.updateProfile(profile, this.fileToUpload).subscribe(
            (response) => {
                this.snackbarService.show('User updated successfully.', 'success');
                const result: User = response;
                result.days = result.days;
                this.authService.updateUser(result);

                this.loginService.changeImage(response.image);
            },
            (error) => {
                this.snackbarService.show('Algo ha pasado ....', 'danger');
            }
        ));
    }

    changeDaySelected(value) {
        this.days[value.id].selected = !this.days[value.id].selected;
    }

    showSnackBar(text: string, _class: string) {
        this.snackbarService.show(text, _class);
    }

    deleteBlockDay(id: number) {
        this.subscription.add(this.blockService.deleteBlockDay(id, this.user_id).subscribe(
            (response) => {
                this.blockDays = this.parseBlockDays(response);
            }
        ))
    }

    deleteBlockWeek(id: number) {
        this.subscription.add(this.blockService.deleteBlockWeek(id, this.user_id).subscribe(
            (response) => {
                this.blockDaysWeek = response;
            }
        ))
    }

    private parseBlockDays(days: []) {
        days.map((item: BlockDay) => {
            item.day = this.parseDay(item.day);
        });

       return days;
    }

    private parseDay(day: string) {
        return moment(day).format(this.settingsValue.formatMoment);
    }

    private parseBlockDates(days: string){
        const arrayDays = JSON.parse(days);

        return arrayDays.map((day: string) => this.parseDay(day));
    }

    getName(number){
        return this.days.filter(day => day.id == number).map(x => x.text)[0];
    }

    submitConfigDates() {
        const selecteDates = this.pickerCalendar.flatpickr.selectedDates.map(element =>
            moment(element).format('YYYY-MM-DD')
        );
        
        this.subscription.add(this.profileService.updateConfigDates(this.user_id, JSON.stringify(selecteDates)).subscribe(
            (response) => {
                this.snackbarService.show('User updated successfully.', 'success');
            }
        ))
    }

    ngOnDestroy(): void {
        if(this.subscription) {
            this.subscription.unsubscribe();
        }
    }
}