import { HttpClient } from "@angular/common/http";
import { Injectable } from "@angular/core";
import { map } from "rxjs/operators";
import { environment } from "src/environments/environment";


@Injectable({
    providedIn: 'root'
})
export class ChatService {
    
    constructor(private http: HttpClient) { }
    
    getChats() {
        let url = `${environment.apiUrl}/chats`;

        return this.http.get(url)
            .pipe(
                map(res => {
                    return {
                        'chats': res['data']
                    }
                })
            );
    }

    listCustomerMessage(customer_id: string, chat_id?: string) {
        let url = `${environment.apiUrl}/customers/${customer_id}/chats`;

        if(chat_id){
            url += '?id='+chat_id;
        }
        
        return this.http.get(url)
            .pipe(map((response: any) => {
                return response.data.reverse();
            }));
    }
    
    createCustomerMessage(customer_id: string, message: string) {

        const formData: FormData = new FormData();
        formData.append('text', message);

        return this.http.post(`${environment.apiUrl}/customers/${customer_id}/chats`, formData)
            .pipe(map((response: any) => {
                return response.data;
            }));
    }

    setHasNewMessages(customer_id: number) {
        let url = `${environment.apiUrl}/customers/${customer_id}/chats/markAsRead`;

        return this.http.get(url)
            .pipe(map((response: any) => {
                return response;
            }));
    }
}