import { Routes, RouterModule } from '@angular/router';
import { NgModule } from '@angular/core';
import { AppointmentsComponent } from './appointments.component';
import { AppointmentsListComponent } from './list/appointments-list.component';
import { AppointmentsScheduleConfirmComponent } from './schedule-confirm/appointmentsScheduleConfirm.component';
import { AppointmentsCmbComponent } from './cmb/cmb.component';


const routes: Routes = [
    { path: '', component: AppointmentsCmbComponent },
    
    { path: ':lang', component: AppointmentsComponent },
    
    { path: ':lang/list', component: AppointmentsListComponent },
    { path: ':lang/schedule/:hash', component: AppointmentsScheduleConfirmComponent},
    { path: ':lang/:user', component: AppointmentsComponent },
    { path: ':lang/:user/:hash', component: AppointmentsComponent },
   
];

@NgModule({
    imports: [RouterModule.forChild(routes)],
    exports: [RouterModule]
})
export class AppointmentsRoutingModule { }