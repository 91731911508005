import { HttpClient } from "@angular/common/http";
import { Injectable } from "@angular/core";
import { map } from "rxjs/operators";
import { environment } from "src/environments/environment";


@Injectable({
    providedIn: 'root'
})
export class CustomerEditTagService {

    constructor(private http: HttpClient) { }

    getTagsByCustomerId(customer_id: number) {
        return this.http
            .get(`${environment.apiUrl}/customers/${customer_id}/tags`)
            .pipe(map((response: any) => {
                return response.data;
            }));
    }

    updateCustomerTag(tags: number[], customer_id: number) {

        return this.http.post(`${environment.apiUrl}/customers/${customer_id}/tags`,{'tags': tags})
            .pipe(map((response: any) => {
                return response.data;
            }));
    }

    deleteTagToCustomer(customer_id, tag_id) {
        
        return this.http.delete(`${environment.apiUrl}/customers/${customer_id}/tags/${tag_id}`)
            .pipe(map((response: any) => {
                return response.data;
            }));
    }
}