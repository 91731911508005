import { Injectable } from '@angular/core';
import {
    HttpEvent, HttpInterceptor, HttpHandler, HttpRequest, HttpXsrfTokenExtractor, HttpErrorResponse
} from '@angular/common/http';

import { Observable } from 'rxjs';

import { AuthenticationGeneralService } from '../services/auth-general.service';
import { User } from '../interfaces/user.interface';
import { catchError } from 'rxjs/operators';


@Injectable()
export class AuthInterceptor implements HttpInterceptor {
    headerName = 'X-XSRF-TOKEN';
    constructor(
        private tokenService: HttpXsrfTokenExtractor,
        private auth: AuthenticationGeneralService) { }

    intercept(req: HttpRequest<any>, next: HttpHandler): Observable<HttpEvent<any>> {
        /* if('v1' !== localStorage.getItem('version')) {
			localStorage.setItem('version', 'v1');
			localStorage.removeItem('country');
			localStorage.removeItem('currentUser');
            window.open('/', "_self");
		} */
        const userData: User = this.auth.getUser();

        req = req.clone({
            withCredentials: true
        })

        if (userData && userData.token) {
            req = req.clone({
                headers: req.headers.set('Authorization', `Bearer ${userData.token}`)
            });
        }

        let catchErrorF = (err) => {
            return new Observable<HttpEvent<any>>(observer => {
                let e = err.error;
                
                if (err instanceof HttpErrorResponse) {
                    const errResp = <HttpErrorResponse>err;
                    if(errResp.status === 401){
                        this.auth.logout();
                    }
                }
                observer.error(err);
                observer.complete();
              })
        }

        if (req.method === 'GET' || req.method === 'HEAD') {
            return next.handle(req).pipe(
                catchError(  err => catchErrorF(err))
            )
        }

        const token = this.tokenService.getToken();

        // Be careful not to overwrite an existing header of the same name.
        if (token !== null && !req.headers.has(this.headerName)) {
            req = req.clone({ headers: req.headers.set(this.headerName, token) });
        }

        // return next.handle(req);

        return next.handle(req).pipe(
            catchError(err => catchErrorF(err))
        )
    }
}