<div>
    <div class="text-center px-4 py-5 sm:px-6">
        <p class=" text-2xl">
            {{ title | translate }}
        </p>
        <p class="mt-1 text-sm leading-5 text-gray-500">
            {{ subtitle | translate }}
        </p>
    </div>
    <div class="px-4 py-5 sm:p-6" [formGroup]="ingredientForm">
        <div class="grid grid-cols-2 gap-6">
            <div class="col-span-2 sm:col-span-1">
                <div class="py-2" *ngFor="let locale of locales; let i = index">
                    <app-label-form for="name" name="{{ 'data.ingredients.form.name' | translate }} {{locale.locale | uppercase}}"></app-label-form>
                    <div class="relative mt-1 flex items-center">
                        <input [(ngModel)]="locales[i].value" [ngModelOptions]="{standalone: true}" class="inputClass" id="name" type="text" placeholder="{{ 'data.ingredients.form.name' | translate }} {{locale.locale | uppercase}}">
                        <app-kbd value="{{locale.locale}}"></app-kbd>
                    </div>
                </div>
            </div>
            <div class="col-span-2 sm:col-span-1">
                <div class="py-2">
                    <app-label-form for="quantity" name="{{ 'data.ingredients.form.quantity' | translate }}"></app-label-form>
                    <input formControlName="quantity" class="inputClass" id="quantity" type="number" placeholder="{{ 'data.ingredients.form.quantity' | translate }}">
                    <ng-container *ngIf="submitted && f.quantity.errors">
                        <app-error-form *ngIf="f.quantity.errors.required"
                            error="{{'errors.required' | translate}}">
                        </app-error-form>
                    </ng-container>
                </div>
                <div class="py-2">
                    <app-label-form for="measure" name="{{ 'data.ingredients.form.measure' | translate }}"></app-label-form>
                    <select formControlName="measure" class="bg-white inputClass" id="measure">
                        <option hidden selected value="">
                            {{ 'customer_edit.medical.build' | translate }}</option>
                        <option value="{{ measure }}" *ngFor="let measure of measures">
                            {{measure}}
                        </option>
                    </select>
                </div>
                <div class="py-2">
                    <app-label-form for="group" name="{{ 'data.ingredients.form.group' | translate }}"></app-label-form>
                    <select formControlName="group" class="bg-white inputClass" id="measure">
                        <option hidden selected value="">{{ 'data.ingredients.form.group' | translate }}</option>
                        <option value="{{ group.id }}" *ngFor="let group of groups">
                            {{group.text}}
                        </option>
                    </select>
                    <ng-container *ngIf="submitted && f.group.errors">
                        <app-error-form *ngIf="f.group.errors.required"
                            error="{{'errors.required' | translate}}">
                        </app-error-form>
                    </ng-container>
                </div>
            </div>
        </div>
        
        <p class="my-8 text-center text-1xl">
            {{ 'data.macronutrients' | translate }}
        </p>
        <div class="grid grid-cols-4 gap-6">
            <div class="col-span-4 sm:col-span-1">
                <app-label-form for="calories" name="{{ 'data.ingredients.form.calories' | translate }}"></app-label-form>
                <div class="relative mt-1 flex items-center">
                    <input formControlName="calories" class="inputClass" id="calories" type="number" min="0.00" step="0.01" placeholder="{{ 'data.ingredients.form.calories' | translate }}">
                    <app-kbd value="{{'kcal'}}"></app-kbd>
                </div>
                <ng-container *ngIf="submitted && f.calories.errors">
                    <app-error-form *ngIf="f.calories.errors.required"
                        error="{{'errors.required' | translate}}">
                    </app-error-form>
                </ng-container>
            </div>
            <div class="col-span-4 sm:col-span-1">
                <app-label-form for="fat" name="{{ 'data.ingredients.form.fat' | translate }}"></app-label-form>
                <div class="relative mt-1 flex items-center">
                    <input formControlName="fat" class="inputClass" id="fat" type="number" min="0.00"  step="0.01" placeholder="{{ 'data.ingredients.form.fat' | translate }}">
                    <app-kbd value="{{'gr'}}"></app-kbd>
                </div>
                <ng-container *ngIf="submitted && f.fat.errors">
                    <app-error-form *ngIf="f.fat.errors.required"
                        error="{{'errors.required' | translate}}">
                    </app-error-form>
                </ng-container>
            </div>
            <div class="col-span-4 sm:col-span-1">
                <app-label-form for="carbohydrates" name="{{ 'data.ingredients.form.carbohydrates' | translate }}"></app-label-form>
                <div class="relative mt-1 flex items-center">
                    <input formControlName="carbohydrates" class="inputClass" id="carbohydrates" type="number" min="0.00"  step="0.01" placeholder="{{ 'data.ingredients.form.carbohydrates' | translate }}">
                    <app-kbd value="{{'gr'}}"></app-kbd>
                </div>
                <ng-container *ngIf="submitted && f.carbohydrates.errors">
                    <app-error-form *ngIf="f.carbohydrates.errors.required"
                        error="{{'errors.required' | translate}}">
                    </app-error-form>
                </ng-container>
            </div>
            <div class="col-span-4 sm:col-span-1">
                <app-label-form for="proteins" name="{{ 'data.ingredients.form.proteins' | translate }}"></app-label-form>
                <div class="relative mt-1 flex items-center">
                    <input formControlName="proteins" class="inputClass" id="proteins" type="number" min="0.00"  step="0.01" placeholder="{{ 'data.ingredients.form.proteins' | translate }}">
                    <app-kbd value="{{'gr'}}"></app-kbd>
                </div>
                <ng-container *ngIf="submitted && f.proteins.errors">
                    <app-error-form *ngIf="f.proteins.errors.required"
                        error="{{'errors.required' | translate}}">
                    </app-error-form>
                </ng-container>
            </div>
        </div>
        <p class="my-8 text-center text-1xl">
            {{ 'data.micronutrients' | translate }}
        </p>
        <div class="grid grid-cols-3 gap-6">
            <div class="col-span-3 sm:col-span-1">
                <app-label-form for="cholesterol" name="{{ 'data.ingredients.form.cholesterol' | translate }}"></app-label-form>
                <div class="relative mt-1 flex items-center">
                    <input formControlName="cholesterol" class="inputClass" id="cholesterol" type="number" min="0.00"  step="0.01" placeholder="{{ 'data.ingredients.form.cholesterol' | translate }}">
                    <app-kbd value="{{'mg'}}"></app-kbd>
                </div>
                <ng-container *ngIf="submitted && f.cholesterol.errors">
                    <app-error-form *ngIf="f.cholesterol.errors.required"
                        error="{{'errors.required' | translate}}">
                    </app-error-form>
                </ng-container>
            </div>
            <div class="col-span-3 sm:col-span-1">
                <app-label-form for="fiber" name="{{ 'data.ingredients.form.fiber' | translate }}"></app-label-form>
                <div class="relative mt-1 flex items-center">
                    <input formControlName="fiber" class="inputClass" id="fiber" type="number" min="0.00"  step="0.01" placeholder="{{ 'data.ingredients.form.fiber' | translate }}">
                    <app-kbd value="{{'gr'}}"></app-kbd>
                </div>
                <ng-container *ngIf="submitted && f.fiber.errors">
                    <app-error-form *ngIf="f.fiber.errors.required"
                        error="{{'errors.required' | translate}}">
                    </app-error-form>
                </ng-container>
            </div>
            <div class="col-span-3 sm:col-span-1">
                <app-label-form for="sodium" name="{{ 'data.ingredients.form.sodium' | translate }}"></app-label-form>
                <div class="relative mt-1 flex items-center">
                    <input formControlName="sodium" class="inputClass" id="sodium" type="number" min="0.00"  step="0.01" placeholder="{{ 'data.ingredients.form.sodium' | translate }}">
                    <app-kbd value="{{'gr'}}"></app-kbd>
                </div>
                <ng-container *ngIf="submitted && f.sodium.errors">
                    <app-error-form *ngIf="f.sodium.errors.required"
                        error="{{'errors.required' | translate}}">
                    </app-error-form>
                </ng-container>
            </div>

            <div class="col-span-3 sm:col-span-1">
                <app-label-form for="water" name="{{ 'data.ingredients.form.water' | translate }}"></app-label-form>
                <div class="relative mt-1 flex items-center">
                    <input formControlName="water" class="inputClass" id="water" type="number" min="0.00"  step="0.01" placeholder="{{ 'data.ingredients.form.water' | translate }}">
                    <app-kbd value="{{'gr'}}"></app-kbd>
                </div>
                <ng-container *ngIf="submitted && f.water.errors">
                    <app-error-form *ngIf="f.water.errors.required"
                        error="{{'errors.required' | translate}}">
                    </app-error-form>
                </ng-container>
            </div>
            <div class="col-span-3 sm:col-span-1">
                <app-label-form for="vitaminA" name="{{ 'data.ingredients.form.vitaminA' | translate }}"></app-label-form>
                <div class="relative mt-1 flex items-center">
                    <input formControlName="vitaminA" class="inputClass" id="vitaminA" type="number" min="0.00"  step="0.01" placeholder="{{ 'data.ingredients.form.vitaminA' | translate }}">
                    <app-kbd value="{{'ug'}}"></app-kbd>
                </div>
                <ng-container *ngIf="submitted && f.vitaminA.errors">
                    <app-error-form *ngIf="f.vitaminA.errors.required"
                        error="{{'errors.required' | translate}}">
                    </app-error-form>
                </ng-container>
            </div>
            <div class="col-span-3 sm:col-span-1">
                <app-label-form for="vitaminB6" name="{{ 'data.ingredients.form.vitaminB6' | translate }}"></app-label-form>
                <div class="relative mt-1 flex items-center">
                    <input formControlName="vitaminB6" class="inputClass" id="vitaminB6" type="number" min="0.00"  step="0.01" placeholder="{{ 'data.ingredients.form.vitaminB6' | translate }}">
                    <app-kbd value="{{'mg'}}"></app-kbd>
                </div>
                <ng-container *ngIf="submitted && f.vitaminB6.errors">
                    <app-error-form *ngIf="f.vitaminB6.errors.required"
                        error="{{'errors.required' | translate}}">
                    </app-error-form>
                </ng-container>
            </div>

            <div class="col-span-3 sm:col-span-1">
                <app-label-form for="vitaminB12" name="{{ 'data.ingredients.form.vitaminB12' | translate }}"></app-label-form>
                <div class="relative mt-1 flex items-center">
                    <input formControlName="vitaminB12" class="inputClass" id="vitaminB12" type="number" min="0.00"  step="0.01" placeholder="{{ 'data.ingredients.form.vitaminB12' | translate }}">
                    <app-kbd value="{{'gr'}}"></app-kbd>
                </div>
                <ng-container *ngIf="submitted && f.vitaminB12.errors">
                    <app-error-form *ngIf="f.vitaminB12.errors.required"
                        error="{{'errors.required' | translate}}">
                    </app-error-form>
                </ng-container>
            </div>
            <div class="col-span-3 sm:col-span-1">
                <app-label-form for="vitaminC" name="{{ 'data.ingredients.form.vitaminC' | translate }}"></app-label-form>
                <div class="relative mt-1 flex items-center">
                    <input formControlName="vitaminC" class="inputClass" id="vitaminC" type="number" min="0.00"  step="0.01" placeholder="{{ 'data.ingredients.form.vitaminC' | translate }}">
                    <app-kbd value="{{'ug'}}"></app-kbd>
                </div>
                <ng-container *ngIf="submitted && f.vitaminC.errors">
                    <app-error-form *ngIf="f.vitaminC.errors.required"
                        error="{{'errors.required' | translate}}">
                    </app-error-form>
                </ng-container>
            </div>
            <div class="col-span-3 sm:col-span-1">
                <app-label-form for="vitaminD" name="{{ 'data.ingredients.form.vitaminD' | translate }}"></app-label-form>
                <div class="relative mt-1 flex items-center">
                    <input formControlName="vitaminD" class="inputClass" id="vitaminD" type="number" min="0.00"  step="0.01" placeholder="{{ 'data.ingredients.form.vitaminD' | translate }}">
                    <app-kbd value="{{'mg'}}"></app-kbd>
                </div>
                <ng-container *ngIf="submitted && f.vitaminD.errors">
                    <app-error-form *ngIf="f.vitaminD.errors.required"
                        error="{{'errors.required' | translate}}">
                    </app-error-form>
                </ng-container>
            </div>

            <div class="col-span-3 sm:col-span-1">
                <app-label-form for="vitaminE" name="{{ 'data.ingredients.form.vitaminE' | translate }}"></app-label-form>
                <div class="relative mt-1 flex items-center">
                    <input formControlName="vitaminE" class="inputClass" id="vitaminE" type="number" min="0.00"  step="0.01" placeholder="{{ 'data.ingredients.form.vitaminE' | translate }}">
                    <app-kbd value="{{'mg'}}"></app-kbd>
                </div>
                <ng-container *ngIf="submitted && f.vitaminE.errors">
                    <app-error-form *ngIf="f.vitaminE.errors.required"
                        error="{{'errors.required' | translate}}">
                    </app-error-form>
                </ng-container>
            </div>
            <div class="col-span-3 sm:col-span-1">
                <app-label-form for="sugars" name="{{ 'data.ingredients.form.sugars' | translate }}"></app-label-form>
                <div class="relative mt-1 flex items-center">
                    <input formControlName="sugars" class="inputClass" id="sugars" type="number" min="0.00"  step="0.01" placeholder="{{ 'data.ingredients.form.sugars' | translate }}">
                    <app-kbd value="{{'gr'}}"></app-kbd>
                </div>
                <ng-container *ngIf="submitted && f.sugars.errors">
                    <app-error-form *ngIf="f.sugars.errors.required"
                        error="{{'errors.required' | translate}}">
                    </app-error-form>
                </ng-container>
            </div>
            <div class="col-span-3 sm:col-span-1">
                <app-label-form for="folicAcid" name="{{ 'data.ingredients.form.folicAcid' | translate }}"></app-label-form>
                <div class="relative mt-1 flex items-center">
                    <input formControlName="folicAcid" class="inputClass" id="folicAcid" type="number" min="0.00"  step="0.01" placeholder="{{ 'data.ingredients.form.folicAcid' | translate }}">
                    <app-kbd value="{{'ug'}}"></app-kbd>
                </div>
                <ng-container *ngIf="submitted && f.folicAcid.errors">
                    <app-error-form *ngIf="f.folicAcid.errors.required"
                        error="{{'errors.required' | translate}}">
                    </app-error-form>
                </ng-container>
            </div>

            <div class="col-span-3 sm:col-span-1">
                <app-label-form for="calcium" name="{{ 'data.ingredients.form.calcium' | translate }}"></app-label-form>
                <div class="relative mt-1 flex items-center">
                    <input formControlName="calcium" class="inputClass" id="calcium" type="number" min="0.00"  step="0.01" placeholder="{{ 'data.ingredients.form.calcium' | translate }}">
                    <app-kbd value="{{'mg'}}"></app-kbd>
                </div>
                <ng-container *ngIf="submitted && f.calcium.errors">
                    <app-error-form *ngIf="f.calcium.errors.required"
                        error="{{'errors.required' | translate}}">
                    </app-error-form>
                </ng-container>
            </div>
            <div class="col-span-3 sm:col-span-1">
                <app-label-form for="potassium" name="{{ 'data.ingredients.form.potassium' | translate }}"></app-label-form>
                <div class="relative mt-1 flex items-center">
                    <input formControlName="potassium" class="inputClass" id="potassium" type="number" min="0.00"  step="0.01" placeholder="{{ 'data.ingredients.form.potassium' | translate }}">
                    <app-kbd value="{{'mg'}}"></app-kbd>
                </div>
                <ng-container *ngIf="submitted && f.potassium.errors">
                    <app-error-form *ngIf="f.potassium.errors.required"
                        error="{{'errors.required' | translate}}">
                    </app-error-form>
                </ng-container>
            </div>
            <div class="col-span-3 sm:col-span-1">
                <app-label-form for="phosphorus" name="{{ 'data.ingredients.form.phosphorus' | translate }}"></app-label-form>
                <div class="relative mt-1 flex items-center">
                    <input formControlName="phosphorus" class="inputClass" id="phosphorus" type="number" min="0.00"  step="0.01" placeholder="{{ 'data.ingredients.form.phosphorus' | translate }}">
                    <app-kbd value="{{'mg'}}"></app-kbd>
                </div>
                <ng-container *ngIf="submitted && f.phosphorus.errors">
                    <app-error-form *ngIf="f.phosphorus.errors.required"
                        error="{{'errors.required' | translate}}">
                    </app-error-form>
                </ng-container>
            </div>

            <div class="col-span-3 sm:col-span-1">
                <app-label-form for="iron" name="{{ 'data.ingredients.form.iron' | translate }}"></app-label-form>
                <div class="relative mt-1 flex items-center">
                    <input formControlName="iron" class="inputClass" id="iron" type="number" min="0.00"  step="0.01" placeholder="{{ 'data.ingredients.form.iron' | translate }}">
                    <app-kbd value="{{'mg'}}"></app-kbd>
                </div>
                <ng-container *ngIf="submitted && f.iron.errors">
                    <app-error-form *ngIf="f.iron.errors.required"
                        error="{{'errors.required' | translate}}">
                    </app-error-form>
                </ng-container>
            </div>
            <div class="col-span-3 sm:col-span-1">
                <app-label-form for="magnesium" name="{{ 'data.ingredients.form.magnesium' | translate }}"></app-label-form>
                <div class="relative mt-1 flex items-center">
                    <input formControlName="magnesium" class="inputClass" id="magnesium" type="number" min="0.00"  step="0.01" placeholder="{{ 'data.ingredients.form.magnesium' | translate }}">
                    <app-kbd value="{{'mg'}}"></app-kbd>
                </div>
                <ng-container *ngIf="submitted && f.magnesium.errors">
                    <app-error-form *ngIf="f.magnesium.errors.required"
                        error="{{'errors.required' | translate}}">
                    </app-error-form>
                </ng-container>
            </div>
            <div class="col-span-3 sm:col-span-1">
                <app-label-form for="zinc" name="{{ 'data.ingredients.form.zinc' | translate }}"></app-label-form>
                <div class="relative mt-1 flex items-center">
                    <input formControlName="zinc" class="inputClass" id="zinc" type="number" min="0.00"  step="0.01" placeholder="{{ 'data.ingredients.form.zinc' | translate }}">
                    <app-kbd value="{{'mg'}}"></app-kbd>
                </div>
                <ng-container *ngIf="submitted && f.zinc.errors">
                    <app-error-form *ngIf="f.zinc.errors.required"
                        error="{{'errors.required' | translate}}">
                    </app-error-form>
                </ng-container>
            </div>

            <div class="col-span-3 sm:col-span-1">
                <app-label-form for="thiamin" name="{{ 'data.ingredients.form.thiamin' | translate }}"></app-label-form>
                <div class="relative mt-1 flex items-center">
                    <input formControlName="thiamin" class="inputClass" id="thiamin" type="number" min="0.00"  step="0.01" placeholder="{{ 'data.ingredients.form.thiamin' | translate }}">
                    <app-kbd value="{{'mg'}}"></app-kbd>
                </div>
                <ng-container *ngIf="submitted && f.thiamin.errors">
                    <app-error-form *ngIf="f.thiamin.errors.required"
                        error="{{'errors.required' | translate}}">
                    </app-error-form>
                </ng-container>
            </div>
            <div class="col-span-3 sm:col-span-1">
                <app-label-form for="riboflavin" name="{{ 'data.ingredients.form.riboflavin' | translate }}"></app-label-form>
                <div class="relative mt-1 flex items-center">
                    <input formControlName="riboflavin" class="inputClass" id="riboflavin" type="number" min="0.00"  step="0.01" placeholder="{{ 'data.ingredients.form.riboflavin' | translate }}">
                    <app-kbd value="{{'mg'}}"></app-kbd>
                </div>
                <ng-container *ngIf="submitted && f.riboflavin.errors">
                    <app-error-form *ngIf="f.riboflavin.errors.required"
                        error="{{'errors.required' | translate}}">
                    </app-error-form>
                </ng-container>
            </div>
            <div class="col-span-3 sm:col-span-1">
                <app-label-form for="niacin" name="{{ 'data.ingredients.form.niacin' | translate }}"></app-label-form>
                <div class="relative mt-1 flex items-center">
                    <input formControlName="niacin" class="inputClass" id="niacin" type="number" min="0.00"  step="0.01" placeholder="{{ 'data.ingredients.form.niacin' | translate }}">
                    <app-kbd value="{{'mg'}}"></app-kbd>
                </div>
                <ng-container *ngIf="submitted && f.niacin.errors">
                    <app-error-form *ngIf="f.niacin.errors.required"
                        error="{{'errors.required' | translate}}">
                    </app-error-form>
                </ng-container>
            </div>
        </div>
        <div class="pt-8 text-right">
            <button type="button" class="customer-info-button-reverse" (click)="submit()">
                <img src="assets/images/svg/check-white.svg"
                    class="customer-info-button-svg" alt="check">
                {{ 'customer_edit.save' | translate }}
            </button>
        </div>
    </div>
</div>