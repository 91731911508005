import { OnDestroy } from '@angular/core';
import { OnInit } from '@angular/core';
import { Component } from '@angular/core';
import { Router } from '@angular/router';
import { Subscription } from 'rxjs';
import { LoginService } from 'src/app/auth/login/login.service';
import { SettingGeneralService } from 'src/app/shared/services/settings-general.service';
import { Settings } from 'src/app/shared/settings/setting';

@Component({
    selector: 'app-home',
    templateUrl: './home.component.html',
    styleUrls: ['./home.component.scss'],
})

export class HomeComponent implements OnInit, OnDestroy {

    isOpen = false;
    isCountry = false;

    // locale
    countries = Settings.langs;
    defaultCountry: string = localStorage.getItem('country');
    private subscription = new Subscription();

    constructor(
        public settingGeneralService: SettingGeneralService,
        public loginService: LoginService,
        private router: Router,
    ) { }

    ngOnInit(): void {
        this.settingGeneralService.getTeam();
    }

    disabled() {
        this.isOpen = false;
        this.isCountry = false;
    }

    logout() {
        this.subscription.add(this.loginService.logout().subscribe(() => this.router.navigate(['/login'])));
    }



    ngOnDestroy(): void {
        this.subscription.unsubscribe();
    }
}