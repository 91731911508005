export const environment = {
  production: false,
  url: 'https://dev.api.naturhousedigital.com',
  apiUrl: 'https://dev.api.naturhousedigital.com/api',
  firebaseConfig: {
    apiKey: "AIzaSyCQBCYSSHjBWZnicDeJraiFDlWO7UcD6bI",
    authDomain: "naturhouse-75af8.firebaseapp.com",
    projectId: "naturhouse-75af8",
    storageBucket: "naturhouse-75af8.appspot.com",
    messagingSenderId: "385518223072",
    appId: "1:385518223072:web:1be2f65913be0634ed1a85",
    measurementId: "G-5VPVVNZZTE",
    vapidKey: "BD5PML1-5R58FRc8F9y6chDtl0e0mdAHppvBOTZqR9X-VaeZ3WEsGdTDYohbFRbisA5lglJbwzCJSl5kpzk6HTM"
  }
};
