import { Component, OnDestroy } from '@angular/core';
import { Subscription } from 'rxjs';
import { SettingGeneralService } from '../../../shared/services/settings-general.service';
import { NavigationEnd, Router } from '@angular/router';
import { GoogleTagManagerService } from 'angular-google-tag-manager';
import { environment } from 'src/environments/environment';
import { TeamService } from 'src/app/shared/services/team.service';

@Component({
    selector: 'app-appointments-list',
    templateUrl: './appointments-list.component.html',
    styleUrls: ['../appointments.component.scss']
})

export class AppointmentsListComponent implements OnDestroy {

    srcImage = '/assets/images/flor-circle.png';
    private subscription = new Subscription();
    users;
    team_id: number;
    slug: string;
    
    constructor(
        public teamService: TeamService,
        public settingGeneralService: SettingGeneralService,
        private router: Router,
        private gtmService: GoogleTagManagerService,
    ) {
        if(environment.production === true) {
            this.router.events.forEach(item => {
                if (item instanceof NavigationEnd) {
                    const gtmTag = {
                        event: 'page',
                        pageName: item.url
                    };
    
                    this.gtmService.pushTag(gtmTag);
                }
            });
        }


        const slug = (this.router.url).split('/');
        this.slug =  slug[2];
        this.subscription.add(this.teamService.getConfigTeam(slug[2]).subscribe(
            (response) => {
                const settings = response.data;
                
                this.settingGeneralService.generateSettings(settings);
                this.subscription.add(this.teamService.listUsersNutriwell(settings.team_id).subscribe(
                    (response) => {
                        this.users = response;
                    }
                ))
            },
            () => {
                window.open('/citas/es', "_self");
            }
        ));
    }

    calendar(slug: string) {
        this.settingGeneralService.insertDataLayer('Select Nutritionist');
        const url = this.router.url.replace('list', slug);
       
        window.open(url, "_self");
    }

    ngOnDestroy(): void {
        if(this.subscription) {
            this.subscription.unsubscribe();
        }
    }
}