import { Injectable } from "@angular/core";
import { environment } from '../../../environments/environment';
import { HttpClient } from "@angular/common/http";
import { map } from "rxjs/operators";
import { AuthenticationGeneralService } from 'src/app/shared/services/auth-general.service';
import { User } from 'src/app/shared/interfaces/user.interface';
import { BehaviorSubject } from "rxjs";
import { SettingGeneralService } from "src/app/shared/services/settings-general.service";

@Injectable()
export class LoginService {

    private imgProfileSource = new BehaviorSubject<string>('assets/images/svg/user-circle.svg');
    public imgProfileSource$ = this.imgProfileSource.asObservable();

    constructor(
        private http: HttpClient,
        private auth: AuthenticationGeneralService,
        private settingGeneralService: SettingGeneralService
    ) { }

    sanctum() {
        return this.http
            .get(`${environment.url}/sanctum/csrf-cookie`)
            .pipe(map((response: any) => response));
    }

    login(email: string, password: string) {
        const body = {
            email,
            password,
        };
        return this.http
            .post(`${environment.apiUrl}/login`, body)
            .pipe(map((response: any) => {
                if (!response.error) {
                    const result: User = response.data;
                    this.auth.setToken(result);
                }
            }));
    }

    changeImage(imageUrl) 
    {
        this.imgProfileSource.next(imageUrl);
    }

    getUser() 
    {
        return this.http
            .get(`${environment.apiUrl}/user`)
            .pipe(map((response: any) => {
                const result: User = response.data;
                result.token = JSON.parse(localStorage.getItem('currentUser')).token;
                
                if (result.image) {
                    this.imgProfileSource.next(result.image);
                }
                this.auth.setToken(result);
                
                this.settingGeneralService.changeLocale(result.teams.config.language)
            }));
    }

    logout() {
        return this.http
            .get(`${environment.apiUrl}/logout`)
            .pipe(map(() => {
                this.auth.clearUser();
            }));
    }
}