import { Component, Input, OnDestroy, OnInit, Output } from '@angular/core';

@Component({
    selector: 'app-calendar-modal',
    templateUrl: './calendar-modal.component.html'
})

export class CalendarModalComponent implements OnInit, OnDestroy {

    @Input() title: string;
    @Input() text_button_create: string;
    @Input() appointmentDay;
    @Input() customerI;
    @Output() closeModal;

    type = 0;

    constructor() { }

    ngOnInit(): void {
        if (this.customerI) this.type = 1;
    }

    closeModalF() {
        this.customerI = null;
        this.appointmentDay = null;
        this.closeModal();
    }

    ngOnDestroy() {
        this.customerI = null;
        this.appointmentDay = null;
    }
}