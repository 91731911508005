<div class="px-4 py-6 sm:px-6 lg:px-8">
    <div class="lg:hidden">
        <select aria-label="Selected tab" #t
            class="form-select block w-full focus:outline-none focus:ring-nutriwell focus:border-nutriwell rounded-md"
            (change)="selectTabMobile($event.target.value)">
            <option *ngFor="let tab of tabs; let i = index" value="{{i}}">{{tab.title}}</option>
        </select>
    </div>
    <div class="hidden lg:block">
        <div class="border-b border-gray-200">
            <nav class="flex -mb-px">
                <a *ngFor="let tab of tabs; let i = index" (click)="selectTab(tab)"
                    class="cursor-pointer group inline-flex items-center py-4 px-1 border-b-2 border-transparent font-medium text-sm leading-5 text-gray-500 hover:text-gray-700 hover:border-gray-300 focus:outline-none focus:text-gray-700 focus:border-gray-300"
                    [ngClass]="{ 'border-nutriwell-sansivera text-nutriwell-sansivera': tab.isActive , 'ml-8': i > 0}">
                    <span>{{tab.title}}</span>
                </a>
            </nav>
        </div>
    </div>
</div>
<div class="px-4 sm:px-6 lg:px-8">
    <ng-content></ng-content>
</div>