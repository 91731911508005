import { HttpClient, HttpParams } from '@angular/common/http';
import { Injectable } from '@angular/core';
import { map } from 'rxjs/operators';
import { environment } from 'src/environments/environment';
import { CustomerMedicalInformation } from '../interfaces/customers.interface';
import { UtilsService } from './util.service';

@Injectable({
    providedIn: 'root'
})
export class CustomerService {

    constructor(private http: HttpClient, private utilService: UtilsService) {

    }

    listCustomer(page?, per_page?, fields?, sortings?) {
        if (!page) {
            page = 1;
        }
        if (!per_page) {
            per_page = 15;
        }

        let params = new HttpParams();
        params = params.append('page', page);
        params = params.append('per_page', per_page);

        
        if (fields) {
            fields.forEach(field => {
                params = params.append(field.name, field.value)
            });
        }

        params = params.append('sorting', JSON.stringify(sortings));

        return this.http.get(`${environment.apiUrl}/customers`, { params })
            .pipe(map((response: any) => {
                return response;
            }));
    }

    listCustomerFilter(fields) {
        let params = new HttpParams;
        if (fields) {
            params = this.utilService.addToParam(params, fields);
        }


        return this.http.get(`${environment.apiUrl}/customers/filter`, { params })
            .pipe(map((response: any) => {
                return response.data;
            }));
    }

    saveCustomer(customer) {
        return this.http
            .post(`${environment.apiUrl}/customers`, customer)
            .pipe(map((response: any) => {
                response.data;
                this.listCustomer();
            }));
    }

    updateCustomer(customer) {
        return this.http
            .put(`${environment.apiUrl}/customers/${customer.id}`, customer)
            .pipe(map((response: any) => {
                response.data;
            }));
    }

    getCustomer(customer_id: string | number) {
        return this.http.get(`${environment.apiUrl}/customers/${customer_id}`,)
            .pipe(map((response: any) => {
                return response.data;
            }));
    }

    getCustomerStatistic(customer_id: string | number) {
        return this.http.get(`${environment.apiUrl}/customers/${customer_id}/statistics`)
        .pipe(map((response: any) => {
                return response.data;
            })
        );
    }

    getCustomerTags(customer_id: string | number) {
        return this.http.get(`${environment.apiUrl}/customers/${customer_id}/tags`)
        .pipe(map((response: any) => {
            return response.data;
            })
        );
    }

    getTagsAssignableToCustomer(customer_id: string | number) {
        return this.http.get(`${environment.apiUrl}/tags/list/${customer_id}`)
        .pipe(map((response: any) => {
            return response.data;
            })
        );
    }
    
    saveMedicalInformation(customer_id: number, medicalInformation: CustomerMedicalInformation) {
        return this.http
            .post(`${environment.apiUrl}/customers/${customer_id}/medical-history`, medicalInformation)
            .pipe(map((response: any) => {
                response.data;
            }));
    }

    /* deleteCustomerState(customer_id) {
        this.deleteCustomerSubject.next({ customer_id: customer_id });
        
    } */

    updateAttempts(customer_id) {
        return this.http.get(`${environment.apiUrl}/customers/${customer_id}/attempts`).pipe();
    }

    listCustomerAppointments(customer_id) {
        return this.http.get(`${environment.apiUrl}/customers/${customer_id}/appointments`).pipe(map((response: any) => {
            return response.data;
            })
        );
    }

    saveOnlineSurvey(customerId: number, survey) {
        let data = {data: JSON.stringify(survey)};
        return this.http
            .post(`${environment.apiUrl}/customers/${customerId}/online-survey`, data)
            .pipe(map((response: any) => {
                response.data;
            }));
    }

    getOnlineSurvey(customerId: number) {
        return this.http
            .get(`${environment.apiUrl}/customers/${customerId}/online-survey`)
            .pipe(map((response: any) => {
                return response.data.data;
            }));
    }

    
}