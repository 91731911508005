<app-appointments-header [slug]="slug.toLocaleLowerCase()"></app-appointments-header>

<div class="max-w-7xl mx-auto px-4 sm:px-6 lg:px-8" *ngIf="hasData; else loading">
    <div class="mx-auto">
        <div class="flex-1 flex flex-col p-8" *ngIf="!notValidHash; else notValidHashDiv">
            <img class="w-32 h-32 flex-shrink-0 mx-auto bg-white rounded-full"
                [src]="srcImage"
                alt="" />

            <div class="text-center">
                <p class="title pt-2">
                    {{'external_schedule_confirm.title' | translate}}
                </p>

                <p class="tex py-5">
                    {{'external_schedule_confirm.subtitle' | translate: param}} {{ appointmentDate | date:'EEEE dd/MM/yyyy' : '' : localeCalendar }} {{appointmentHour}}
                </p>
                <dl class="mt-5 flex items-center justify-center b-gray-500 space-x-8">
                    <div *ngIf="!isConfirm" (click)="confirmAppointment()" 
                    class="cursor-pointer w-48 bg-nutriwell-mustela text-white rounded-lg border border-gray-300 px-6 py-4 hover:border-gray-400 text-center text-sm">
                        <p class="font-medium">
                            {{'external_schedule_confirm.button_confirm' | translate}}
                        </p>                    
                    </div>
                    <div (click)="rescheduleAppointment()" 
                    class="cursor-pointer w-48 rounded-lg border border-nutriwell-mustela bg-white px-6 py-4 hover:border-gray-400 text-center text-sm">
                        <p class="font-medium text-nutriwell-mustela">
                            {{'external_schedule_confirm.button_schedule' | translate}}                            
                        </p>
                    </div>
                </dl>

                <p class="text pt-5" *ngIf="showConfirmText">
                    {{'external_schedule_confirm.text_confirm' | translate}}
                </p>
            </div>
        </div>

        <ng-template #notValidHashDiv>
            <section id="" class="error-page">
                <div class="error-box">
                    <!-- <div class="error-body text-center">
            
                        <h3 class="text-uppercase">Page Not Found !</h3>
                        <p class="text-muted m-t-30 m-b-30">YOU SEEM TO BE TRYING TO FIND
                            HIS WAY HOME</p>
                        <a href="index.html" class="btn btn-info btn-rounded waves-effect
                            waves-light m-b-40">Back to home</a>
                    </div>
                    <footer class="footer text-center">© {{ year }} Admin Pro.</footer> -->
                </div>
            </section>
        </ng-template>
    </div>
</div>

<ng-template #loading>
    <app-loading></app-loading>
</ng-template>