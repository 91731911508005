<div class="w-full p-2" *ngIf="!customer">
    <app-label-form for="name" name="{{ 'customer_create.name' | translate }}">
    </app-label-form>
    <input class="inputClass" id="name" type="text"
        placeholder="{{ 'appointment_create.find_customer' | translate }}" [formControl]="searchText" autofocus
        />
</div>

<div class="w-full p-2 overflow-x-auto" [style.display]="showCustomers">
    <table class="min-w-full divide-y divide-gray-200">
        <thead>
            <tr>
                <th class="px-6 py-3 bg-gray-50 text-left text-xs leading-4 font-medium
                    text-gray-500 uppercase tracking-wider">
                    {{ 'customer_list.table.name' | translate }}
                </th>
                <th class="px-6 py-3 bg-gray-50 text-left text-xs leading-4 font-medium
                    text-gray-500 uppercase tracking-wider">
                    {{ 'customer_list.table.phone' | translate }}
                </th>
            </tr>
        </thead>
        <tbody class="bg-white divide-y divide-gray-200">
            <tr class="cursor-pointer" *ngFor="let customer of customers"
                (click)="selectCustomer(customer)">
                <td class="px-6 py-4 whitespace-no-wrap">
                    <div class="flex items-center">
                        <div class="flex-shrink-0 h-10 w-10">
                            <img class="h-10 w-10 rounded-full"
                                src="https://images.unsplash.com/photo-1494790108377-be9c29b29330?ixlib=rb-1.2.1&amp;ixid=eyJhcHBfaWQiOjEyMDd9&amp;auto=format&amp;fit=facearea&amp;facepad=4&amp;w=256&amp;h=256&amp;q=60"
                                alt="">
                        </div>
                        <div class="ml-4">
                            <div class="text-sm leading-5 font-medium text-gray-900">
                                {{customer.name}}
                            </div>
                            <div class="text-sm leading-5 text-gray-500">
                                {{customer.email}}
                            </div>
                        </div>
                    </div>
                </td>
                <td class="px-6 py-4 whitespace-no-wrap">
                    <div class="text-sm leading-5 text-gray-900">{{customer.phone}}</div>
                </td>
            </tr>
        </tbody>
    </table>
</div>

<div class="w-full p-2" *ngIf="customer">
    <table class="min-w-full divide-y divide-gray-200 overflow-x-auto">
        <tbody class="bg-white divide-y divide-gray-200">
            <tr class="cursor-pointer">
                <td class="px-6 py-4 whitespace-no-wrap">
                    <div class="flex items-center">
                        <div class="flex-shrink-0 h-10 w-10">
                            <img class="h-10 w-10 rounded-full"
                            src="https://eu.ui-avatars.com/api/?name={{customer.name}}&background=fff&background=006C5B&color=fff">
                        </div>
                        <div class="ml-4">
                            <div class="text-sm leading-5 font-medium text-gray-900">
                                {{customer.name}}
                            </div>
                            <div class="text-sm leading-5 text-gray-500">
                                {{customer.email}}
                            </div>
                        </div>
                    </div>
                </td>
                <td class="px-6 py-4 whitespace-no-wrap hidden sm:table-cell">
                    <div class="text-sm leading-5 text-gray-900">{{customer.prefix}}{{customer.mobile}}</div>
                </td>
                <td>
                    <img class="w-6 h-6" src="/assets/images/svg/trash.svg"
                        (click)="cleanCustomer()" />
                </td>
            </tr>
        </tbody>
    </table>

    <div class="flex flex-wrap" [formGroup]="appointmentForm" *ngIf="kindsAppointments">
        <div class="w-full sm:w-1/2 p-2" #flatpickrA>
            <app-label-form for="date" name="{{ 'appointment_create.date' | translate
                }}">
            </app-label-form>
            <ng2-flatpickr  formControlName="date" #calendar addClass="inputClass"
                [config]="dateOptions"
                placeholder="{{ 'appointment_create.date' | translate }}">
            </ng2-flatpickr>
            <ng-container *ngIf="submitted && f.date.errors">
                <app-error-form *ngIf="f.date.errors.required"
                    error="{{'errors.required' |
                    translate}}">
                </app-error-form>
            </ng-container>
        </div>
        <div class="w-full sm:w-1/2 p-2">
            <app-label-form for="duration" name="{{ 'appointment_create.duration' |
                translate }}">
            </app-label-form>
            <input formControlName="duration" readonly class="inputClass" id="duration"
                type="number"
                min="0"
                [ngClass]="{ 'border border-red-500': submitted && f.duration.errors }"
                placeholder="{{ 'appointment_create.duration' | translate }}">
            <ng-container *ngIf="submitted && f.duration.errors">
                <app-error-form *ngIf="f.duration.errors.required"
                    error="{{'errors.required' | translate}}">
                </app-error-form>
            </ng-container>
        </div>
        <div class="w-full sm:w-1/3 p-2">
            <app-label-form for="name" name="{{'kind_appointments.name' | translate}}"></app-label-form>
            <div class="relative">
                <span class="inline-block w-full rounded-md shadow-sm">
                    <button type="button" (click)="showListKind= !showListKind"
                        class="cursor-default relative w-full rounded-md border
                        border-gray-300 bg-white pl-3 pr-10 py-2 text-left
                        focus:outline-none transition ease-in-out duration-150
                        sm:text-sm
                        sm:leading-5">
                        <div class="flex items-center space-x-3">
                            <span aria-label="Online" class="flex-shrink-0 inline-block
                                h-2
                                w-2 rounded-full"
                                [ngStyle]="{'background-color':
                                firstKindAppointment.color}"></span>
                            <span class="block truncate">
                                {{firstKindAppointment.name}}
                            </span>
                        </div>
                        <span class="absolute inset-y-0 right-0 flex items-center pr-2
                            pointer-events-none">
                            <img class="h-5 w-5 text-gray-400"
                                src="assets/images/svg/selector.svg" />

                        </span>
                    </button>
                </span>

                <div class="mt-1 w-full rounded-md bg-white shadow-lg"
                    *ngIf="showListKind">
                    <ul tabindex="-1" role="listbox" aria-labelledby="listbox-label"
                        aria-activedescendant="listbox-item-3"
                        class="max-h-60 rounded-md py-1 text-base leading-6 ring-1
                        ring-black ring-opacity-5 overflow-auto focus:outline-none
                        sm:text-sm sm:leading-5">

                        <li id="listbox-item-0" *ngFor="let kinds of kindsAppointments"
                            (click)="changeKind(kinds)"
                            class="cursor-default select-none relative py-2 pl-3 pr-9">
                            <div class="flex items-center space-x-3">
                                <span [ngStyle]="{'background-color': kinds.color}"
                                    class="flex-shrink-0 inline-block h-2 w-2
                                    rounded-full"></span>
                                <span class="font-normal block truncate">
                                    {{kinds.name}}
                                </span>
                            </div>
                        </li>
                    </ul>
                </div>
            </div>
        </div>
        <div class="w-full sm:w-1/3 p-2">
            <app-label-form for="user" name="{{ 'appointment_create.user' | translate
                }}">
            </app-label-form>
            <select formControlName="user_id" class="bg-white inputClass" id="user">
                <option hidden selected value="">{{ 'appointment_create.user' |
                    translate }}
                </option>
                <option value="{{ user.id }}" *ngFor="let user of users">
                    {{ user.name }}
                </option>
            </select>
            <ng-container *ngIf="submitted && f.user_id.errors">
                <app-error-form *ngIf="f.user_id.errors.required"
                    error="{{'errors.required' | translate}}">
                </app-error-form>
            </ng-container>
        </div>
        <div class="w-full sm:w-1/3 p-2">
            <app-label-form for="type" name="Type">
            </app-label-form>
            <select formControlName="type" class=" inputClass" id="type">
                <option hidden selected value="">
                    Type
                </option>
                <option value=1>
                    Digital
                </option>
                <option value=2>
                    Shop
                </option>
            </select>
            <ng-container *ngIf="submitted && f.type.errors">
                <app-error-form *ngIf="f.type.errors.required"
                    error="{{'errors.required' | translate}}">
                </app-error-form>
            </ng-container>
        </div>
    </div>
    <div class="p-2 flex justify-end" *ngIf="kindsAppointments">
        <button type="submit" (click)="createAppointment()"
            class="customer-info-button">
            {{text_button_create}}
        </button>
    </div>
</div>