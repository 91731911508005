import { Component, Input, OnInit } from "@angular/core";
import { DeviceDetectorService } from "ngx-device-detector";

@Component({
    selector: 'app-appointments-header',
    templateUrl: './appointments-header.component.html',
})
export class AppointmentsHeaderComponent implements OnInit {
    
    @Input() slug: string;
    isOpenMobile: boolean = true;

    // mobile
    isMobile: boolean = false;
    
    urls = {
        'es': 'https://naturhouse.es',
        'fr': 'https://naturhouse.fr',
        'gb': 'https://naturhouse.co.uk',
        'us': 'https://naturhouseusa.com',
        'it': 'https://naturhouse.it'
    };

    constructor(
        private deviceService: DeviceDetectorService,
    ) {
        this.isMobile = this.deviceService.isMobile();

        
    }
    ngOnInit(): void {
        if(this.slug == 'it') {
            const pixel =  "!function(f,b,e,v,n,t,s){if(f.fbq)return;n=f.fbq=function(){n.callMethod?n.callMethod.apply(n,arguments):n.queue.push(arguments)};if(!f._fbq)f._fbq=n;n.push=n;n.loaded=!0;n.version='2.0';n.queue=[];t=b.createElement(e);t.async=!0;t.src=v;s=b.getElementsByTagName(e)[0];s.parentNode.insertBefore(t,s)}(window, document,'script','https://connect.facebook.net/en_US/fbevents.js');fbq('init', '371757208614130');fbq('track', 'PageView');";

            const pixelDiv = document.createElement("script");
            pixelDiv.type = 'text/javascript';
            pixelDiv.text = pixel;

            document.head.appendChild(pixelDiv);
        }
    }
}