<nav class="border-b border-gray-200">
    <div class="max-w-7xl mx-auto px-4 sm:px-6 lg:px-8">
        <div class="flex justify-between h-16">
            <div class="flex">
                <div class="flex-shrink-0 flex items-center">
                    <img src="https://naturhouse.es/media/logo/websites/1/Logo.png" class="block h-8
                        w-auto">
                </div>
            </div>
        </div>
    </div>
</nav>

<div class="py-2 bg-gray-100" *ngIf="customer || is_signed; else loading;">
    <div class="max-w-screen-xl mx-auto px-4 sm:px-6 lg:px-8" *ngIf="customer; else is_signedDiv">
        <div class="mt-10">
            <div class="grid grid-cols-1 md:grid-cols-2 gap-x-8 gap-y-10 border-b-2">
                <div class="ml-4 pb-2">
                    <h4 class="text-lg leading-6 font-medium text-gray-900">
                        PROTECCIÓN DE DATOS PERSONALES
                    </h4>
                </div>

                <div class="ml-4 pb-2">
                    <h4 class="text-lg leading-6 font-medium text-gray-900">
                        Empresa responsable del tratamiento
                    </h4>
                    <p class="mt-2 text-base leading-6 text-gray-500">
                        NATURHOUSE HEALTH, S.A.<br />
                        Claudio Coello 91, 4ª Planta, 28006<br />
                        Madrid<br />
                        CIF: A-01115286<br />
                        Teléfono: 91 432 39 53<br />
                        Email: dpo@naturhouse.com
                    </p>
                </div>
            </div>
            <div class="gap-x-8 gap-y-10 grid grid-cols-1 py-5">
                <div class="ml-4 pb-2">
                    <ul>
                        <li class="pb-2">
                            <p class="font-bold inline mr-1 uppercase">Nombre:</p> {{customer.name}}
                            {{customer.surnames}}
                        </li>
                        <li class="pb-2">
                            <p class="font-bold inline mr-1 uppercase">DNI/NIE:</p>
                            {{customer.name}}
                        </li>
                        <li class="pb-2">
                            <p class="font-bold inline mr-1 uppercase">dirección:</p>
                            {{customer.address}}
                        </li>
                        <li class="pb-2">
                            <p class="font-bold inline mr-1 uppercase">email:</p> {{customer.email}}
                        </li>
                        <li class="pb-2">
                            <p class="font-bold inline mr-1 uppercase">Teléfono:</p>
                            {{customer.prefix}}
                            {{customer.mobile}}
                        </li>
                    </ul>
                </div>
                <div class="gap-x-8 gap-y-10 grid grid-cols-1 py-5" [innerHTML]="customer.text"></div>
            </div>
            <div class="ml-4 pb-2 flex flex-col" *ngFor="let c of customer.checkboxes; let i = index">
                <p class="pb-2">{{c.text}}</p>
                <div class="pb-2 flex">
                    <span role="checkbox" tabindex="{{i}}" aria-checked="false"
                            (click)="c.selected=!c.selected"
                            class="relative inline-flex flex-shrink-0 h-6 w-11 border-2
                            border-transparent rounded-full cursor-pointer transition-colors ease-in-out
                            duration-200 focus:outline-none"
                            [ngClass]="{'bg-gray-200': !c.selected, 'bg-nutriwell': c.selected}">
                        <span class="translate-x-0 relative inline-block h-5 w-5 rounded-full bg-white
                            shadow transform transition ease-in-out duration-200"
                            [ngClass]="{'translate-x-0': !c.selected, 'translate-x-5': c.selected}">
                            <span class="absolute inset-0 h-full w-full flex items-center
                                justify-center transition-opacity"
                                [ngClass]="{ 'off': c.selected, 'on': !c.selected }">
                                <svg class="h-3 w-3 text-gray-400" fill="none" viewBox="0 0 12 12">
                                    <path d="M4 8l2-2m0 0l2-2M6 6L4 4m2 2l2 2" stroke="currentColor" stroke-width="2" stroke-linecap="round" stroke-linejoin="round" />
                                </svg>
                            </span>
                            <span class="absolute inset-0 h-full w-full flex items-center
                                justify-center transition-opacity"
                                [ngClass]="{ 'on': c.selected, 'off': !c.selected }">
                                <svg class="h-3 w-3 text-nutriwell" fill="currentColor"
                                    viewBox="0 0 12 12">
                                    <path d="M3.707 5.293a1 1 0 00-1.414 1.414l1.414-1.414zM5
                                        8l-.707.707a1 1 0 001.414 0L5 8zm4.707-3.293a1 1 0
                                        00-1.414-1.414l1.414 1.414zm-7.414 2l2 2
                                        1.414-1.414-2-2-1.414 1.414zm3.414 2l4-4-1.414-1.414-4 4
                                        1.414 1.414z" />
                                </svg>
                            </span>
                        </span>
                    </span>
                </div>
            </div>
            <div class="mt-5 sm:mt-8 sm:flex justify-center">
                <div>
                    <a (click)="submit()"
                        class="cursor-pointer w-full flex items-center justify-center px-8
                        py-3 border border-white text-base leading-6 font-medium rounded-3xl
                        text-white bg-nutriwell focus:outline-none focus:border-nutriwell
                        focus:ring-nutriwell transition duration-150 ease-in-out md:py-4
                        md:text-lg md:px-10">
                        {{ 'customer_privacy.button' | translate }}
                    </a>
                </div>
            </div>
        </div>
    </div>

    <ng-template #is_signedDiv>
        <div class="flex flex-col justify-center items-center" style="height: 93vh;">
            <p>
                GRACIAS POR FIRMAR NUESTRA POLITICA DE PRIVACIDAD
            </p>
            <p>
                MUCHAS GRACIAS
            </p>
            <div class="mt-5 sm:mt-8 sm:flex justify-center">
                <div class="">
                    <a href="https://naturhouse.es"
                        class="cursor-pointer w-full flex items-center justify-center px-8
                        py-3 border border-white text-base leading-6 font-medium rounded-3xl
                        text-white bg-nutriwell focus:outline-none focus:border-nutriwell
                        focus:ring-nutriwell transition duration-150 ease-in-out md:py-4
                        md:text-lg md:px-10">
                        Ir a Naturhouse
                    </a>
                </div>
            </div>
        </div>
    </ng-template>
</div>

<ng-template #loading>
    <app-loading></app-loading>
</ng-template>