<div class="fixed z-40 inset-0 overflow-y-auto">
	<div class="flex items-end justify-center min-h-screen pt-4 px-4 pb-20 text-center sm:block
		sm:p-0">

		<div class="fixed inset-0 transition-opacity" (click)="closeModalF()">
			<div class="absolute inset-0 bg-gray-500 opacity-75"></div>
		</div>

		<!-- This element is to trick the browser into centering the modal contents. -->
		<span class="hidden sm:inline-block sm:align-middle sm:h-screen"></span>&#8203;

		<div class="inline-block align-bottom bg-white rounded-lg text-left overflow-hidden shadow-xl
			transform transition-all sm:my-8 sm:align-middle sm:max-w-lg sm:w-full"
			role="dialog" aria-modal="true" aria-labelledby="modal-headline">
			<div class="bg-gray-50 px-4 pt-3 sm:px-6 sm:flex sm:flex-row-reverse">
				<svg class="w-6 h-6 pb-2 cursor-pointer" fill="none" stroke="currentColor" viewBox="0 0 24 24"
					xmlns="http://www.w3.org/2000/svg" (click)="closeModalF()">
					<path stroke-linecap="round" stroke-linejoin="round" stroke-width="2" d="M6 18L18 6M6 6l12 12">
					</path>
				</svg>
			</div>
			<div class="bg-white px-4 pt-5 pb-4 sm:p-6 sm:pb-4">
				<div class="sm:flex sm:items-start">
					<div
						class="mx-auto flex-shrink-0 flex items-center justify-center h-12 w-12 rounded-full
						bg-red-100 sm:mx-0 sm:h-10 sm:w-10">
						<!-- Heroicon name: exclamation  https://heroicons.dev/ -->
						<svg class="w-6 h-6" fill="none" stroke="currentColor" viewBox="0 0 24 24"
							xmlns="http://www.w3.org/2000/svg">
							<path stroke-linecap="round" stroke-linejoin="round" stroke-width="2"
								d="M7 8h10M7 12h4m1 8l-4-4H5a2 2 0 01-2-2V6a2 2 0 012-2h14a2 2 0 012 2v8a2 2 0 01-2 2h-3l-4
								4z">
							</path>
						</svg>
					</div>
					<div class="mt-3 text-center sm:mt-0 sm:ml-4 sm:text-left">
						<h3 class="text-lg leading-6 font-medium text-gray-900" id="modal-headline">
							{{title}}
						</h3>
						<div class="mt-2">
							<p class="text-sm leading-5 text-gray-500">
								{{text}}
							</p>
						</div>
					</div>
				</div>
			</div>
			<div class="bg-gray-50 px-4 py-3 sm:px-6 sm:flex sm:flex-row-reverse">
				<span class="flex w-full rounded-md shadow-sm sm:ml-3 sm:w-auto">
					<button type="button" (click)="buttonAction()"
						class="inline-flex justify-center w-full rounded-md border border-transparent px-4 py-2
						bg-red-600 text-base leading-6 font-medium text-white shadow-sm hover:bg-red-500
						focus:outline-none focus:border-red-700 focus:ring-red transition ease-in-out duration-150
						sm:text-sm sm:leading-5">
						{{text_button_cancel}}
					</button>
				</span>
			</div>

		</div>
	</div>
</div>