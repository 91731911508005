import { Component, Input, Output } from "@angular/core";

@Component({
    selector: 'app-term',
    templateUrl: './term.component.html'
})

export class TermComponent {

    @Input() title: string;
    @Output() closeModal;

    constructor() {
    }

    closeModalF() {
        this.closeModal();
    }
}