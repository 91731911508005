<div class="div-meals">
    <span class="w-full flex items-center justify-between p-6 text-gray-200"
    [ngClass]="{'bg-nutriwell-stone': isComplementValue, 'bg-nutriwell': !isComplementValue}">
        {{name}}
    </span>

    <div cdkDropList [cdkDropListData]="items" class="div-list" (cdkDropListDropped)="drop($event, itemName, position)" [cdkDropListEnterPredicate]="0 === isComplementValue ? isComplement : isMeal">
        <ul class="my-3 grid grid-cols-1 gap-5">
            <li class="col-span-1 flex rounded-md px-2 cursor-pointer" *ngFor="let item of items" cdkDrag [cdkDragData]="item">
                <div class=" flex-shrink-0 flex items-center justify-center w-16 bg-gray-200 text-white text-sm font-medium rounded-l-md">
                    <img src="{{item.image}}" />
                </div>
                <div class="flex-1 flex items-center justify-between border-t border-r border-b border-gray-200 bg-white rounded-r-md truncate">
                    <div class="flex-1 px-4 py-2 text-sm truncate">
                        <p class="text-gray-900 font-medium hover:text-gray-600">{{item.name}}</p>
                        <p class="text-gray-500">{{item.description}}</p>
                    </div>
                    <div class="flex-shrink-0 pr-2">
                        <button (click)="deleteElement(itemName, item, position)"
                            class="w-8 h-8 bg-white inline-flex items-center justify-center text-gray-400 rounded-full bg-transparent hover:text-gray-500 focus:outline-none">
                            <span class="sr-only">Delete</span>
                            <img src="assets/images/svg/trash.svg" />
                        </button>
                    </div>
                </div>
            </li>
        </ul>
    </div>
</div>