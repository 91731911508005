import { Component, OnInit } from '@angular/core';
import { ActivatedRoute } from '@angular/router';
import { SignPrivacyService } from './signprivacy.service';
import { v4 as uuidv4 } from 'uuid';

@Component({
    selector: 'app-signprivacy',
    templateUrl: './signprivacy.component.html',
    styleUrls: ['./signprivacy.component.scss']
})
export class SignPrivacyComponent implements OnInit {

    customer;
    customerId: string;
    isOpenMobile = true;
    is_signed = false;

    constructor(
        private route: ActivatedRoute,
        private signPrivacyService: SignPrivacyService
    ) { }


    ngOnInit(): void {
        this.customerId = this.route.snapshot.paramMap.get("customer");


        this.signPrivacyService.getCustomerCrypt(this.customerId).subscribe(
            (response) => {
                if (!response.signed) {
                    this.customer = response;
                    this.customer.id = uuidv4();
                } else {
                    this.is_signed = true;
                }
            }
        );
    }

    submit() {
        console.log(this.customer)
        this.signPrivacyService.signCustomerGdpr(this.customerId, this.customer).subscribe(
            (response) => {
                this.customer = null;
                this.is_signed = true;
            }
        );
        this.customer = null;
        this.is_signed = true;
    }
}