import { Component, OnInit, OnDestroy, Input, ViewChild, ElementRef, HostListener } from "@angular/core";
import { Subscription } from "rxjs";
import { ChatService } from 'src/app/shared/services/chats.service';
import { SnackbarService } from "src/app/shared/components/snackbar/snackbar.service";
import { ChatMessage } from "../../interfaces/chat.interface";

@Component({
    selector: 'app-customer-chat',
    templateUrl: './customer-chat.component.html',
})

export class CustomerChatComponent implements OnInit, OnDestroy {

    @Input() customer_id;
    @Input() firstMessages: ChatMessage[];

    message: string = '';

    messages: any[] = [];

    showMessageLimit: boolean = false;

    private subscription = new Subscription();

    ready: boolean = false;

    scrollToDown: boolean = true;
    @ViewChild('scroll') private myScrollContainer: ElementRef;
    noMore: boolean = false;
    loadingMore: boolean = false;
    
    constructor(
        private chatService: ChatService,
        private snackbarService: SnackbarService,
    ) {
       
    }

    ngOnChanges() {
        this.getFirstMessage();
    }
    
    ngOnInit(): void {
        if(!this.firstMessages || this.firstMessages.length > 0) {
            this.subscription.add(this.chatService.listCustomerMessage(this.customer_id).subscribe(
                (response) => {
                    this.messages = response.map(chat => {
                        return {
                            id: chat.id,
                            type: chat.type,
                            text: chat.message
                        }
                    });
                    
                    this.ready = true;
                },
                () => {
                    this.showSnackService('Something was wrong', 'danger');
                }
            ));
        } else {
            this.getFirstMessage();
            this.ready = true;
        }
    }

    private getFirstMessage() {
        if(this.firstMessages) {
            this.messages = this.firstMessages.map(chat => {
                return {
                    id: chat.id,
                    type: chat.type,
                    text: chat.message
                }
            });
        }
        
    }

    ngAfterViewChecked() { 
        if(this.scrollToDown) {
            try {
                this.myScrollContainer.nativeElement.scrollTop = this.myScrollContainer.nativeElement.scrollHeight;
                this.scrollToDown = false;
                
            } catch(err) { } 
        }
    }

    onScroll(event) {
        if(0 === event.target.scrollTop && !this.noMore) {
            this.loadingMore = true;

            this.subscription.add(this.chatService.listCustomerMessage(this.customer_id, this.messages[0].id).subscribe(
                (response) => {
                    const moreMessage = response.map(chat => {
                        return {
                            id: chat.id,
                            type: chat.type,
                            text: chat.message
                        }
                    });

                    this.loadingMore = false;
                    if(0 === moreMessage.length) {
                        this.noMore = true;
                        return ;
                    }

                    this.messages = moreMessage.concat(this.messages);
                },
                () => {
                    this.showSnackService('Something was wrong', 'danger');
                }
            ));
        }
        
    }

    modelChangeFn(event)
    {
        if(event.length < 151) {
            this.showMessageLimit = false;
            this.message = event;
        } else {
            this.showMessageLimit = true;
        }
    }

    sendMessage() {
        if('' !== this.message && this.message.length <= 150) {
            this.subscription.add(this.chatService.createCustomerMessage(this.customer_id, this.message).subscribe(
                () => {
                    this.messages.push({
                        type: 1,
                        text: this.message
                    });
                    this.message = '';
                },
                (error) => {
                    this.showSnackService('Something was wrong', 'danger');
                }
            ));
    
            this.showMessageLimit = false;
        }
    }


    private showSnackService(text: string, cssClass: string) {
        this.snackbarService.show(text, cssClass);
    }

    ngOnDestroy(): void {
        this.subscription.unsubscribe();
    }
}