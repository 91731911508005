import { HttpClient, HttpParams } from '@angular/common/http';
import { Injectable } from '@angular/core';
import { BehaviorSubject, Subject } from 'rxjs';
import { map } from 'rxjs/operators';
import { environment } from 'src/environments/environment';
import { AppointmentCalendar } from '../interfaces/appointment.interface';
import { UtilsService } from './util.service';
import { saveAs } from 'file-saver';



@Injectable({
    providedIn: 'root'
})
export class AppointmentService {

    private listAppointmentCustomerSubject = new Subject<any>();
    public listAppointmentCustomer$ = this.listAppointmentCustomerSubject.asObservable();

    date;

    private deleteSubject = new Subject<any>();
    public deleteState = this.deleteSubject.asObservable();

    private showSubject = new Subject<any>();
    public showState = this.showSubject.asObservable();

    constructor(private http: HttpClient, private utilService: UtilsService) { }

    listAppointment(date?, user_id?) {
        this.date = date;

        let params = new HttpParams;
        if (date) {
            params = params.append('date', date);
        }
        if (user_id) {
            params = params.append('user_id', user_id);
        }
        return this.http
            .get(`${environment.apiUrl}/appointments`, { params })
            .pipe(
                map(
                    (response: any) => {
                        return response.data.map(
                            (appointmentCalendar: AppointmentCalendar) => {
                                return {
                                    title: appointmentCalendar.title,
                                    customer_id: appointmentCalendar.customer_id,
                                    start: new Date(appointmentCalendar.start),
                                    end: new Date(appointmentCalendar.end),
                                    allDay: appointmentCalendar.allDay,
                                    color: {
                                        'primary': appointmentCalendar.color,
                                        'secondary': appointmentCalendar.color
                                    },
                                    status: appointmentCalendar.status,
                                    meta: {
                                        appointmentCalendar,
                                    },
                                    cssClass: 'event-class',
                                    typeA: appointmentCalendar.typeA
                                };
                            }
                        );
                    }
                )
            );
    }

    saveAppointment(appointment) {
        return this.http
            .post(`${environment.apiUrl}/appointments`, appointment)
            .pipe(map((response: any) => {
                response.data;
                this.listAppointmentCustomer(appointment.customer_id, response.data.date);
            }));
    }

    listAppointmentCustomer(customer_id, date?) {
        this.date = date;
        let params = new HttpParams;
        params = params.append('customer_id', customer_id);
        if (date) {
            params = params.append('date', date);
        }

        let data = this.http
            .get(`${environment.apiUrl}/appointments`, { params })
            .pipe(map((response: any) => {
                return response.data.map((appointmentCalendar: AppointmentCalendar) => {
                    return {
                        title: appointmentCalendar.title,
                        start: new Date(appointmentCalendar.start),
                        allDay: appointmentCalendar.allDay,
                        color: {
                            primary: appointmentCalendar.color,
                            secondary: appointmentCalendar.color,
                        },
                        meta: {
                            id: appointmentCalendar.id,
                            data: appointmentCalendar.data,
                        },
                    };
                });
            }));

        this.listAppointmentCustomerSubject.next(data);
    }

    updateAppointment(appointment_id, appointment) {
        return this.http
            .post(`${environment.apiUrl}/appointments/${appointment_id}/data`, appointment)
            .pipe(map((response: any) => {
                response.data;
                this.listAppointment(this.date);
            }));
    }

    updateFatAppointment(appointment_id, fat) {
        return this.http
            .post(`${environment.apiUrl}/appointments/${appointment_id}/data/fat`, fat)
            .pipe(map((response: any) => {
                response.data;
            }));
    }

    deleteAppointmentState(appointment_id) {
        this.deleteSubject.next({ appointment_id: appointment_id });
    }

    deleteAppointment(appointment_id) {
        return this.http
            .delete(`${environment.apiUrl}/appointments/${appointment_id}`)
            .pipe(map((response: any) => {
                response.data;
                this.listAppointment(this.date);
            }));
    }

    showAppointmentState(action, appointment) {
        const data = { action: action, appointment: appointment };
        if (action === 2) {
            this.startAppointmentTimestamp(data).subscribe();
        } else {
            this.showSubject.next(data);
        }
    }

    startAppointmentTimestamp(data) {

        return this.http
            .get(`${environment.apiUrl}/appointments/start/${data.appointment.id}`,)
            .pipe(map((response: any) => {
                this.showSubject.next(data);
            }));
    }

    calculateTeoricWeight(weight: number) {
        return this.http
            .get(`${environment.apiUrl}/external/weight-theoric/${weight * 100}`,)
            .pipe(map((response: any) => {
                
            }));
    }

    createSchedule(appointments) {
        return this.http
            .post(`${environment.apiUrl}/appointments/schedule`, appointments)
            .pipe(map((response: any) => {
                response.data;
            }));
    }

    listAppointmentFilters(teamId) {
        return this.http.get(`${environment.apiUrl}/appointments/filters/${teamId}`).pipe(
            map(res =>  {
                return res['data'];
            })
        );
    }

    getUpcomingAppointments(customerId, date) {
        return this.http.get(`${environment.apiUrl}/appointments/upcoming/${customerId}/${date}`).pipe(
            map(res => res)
        );
    }

    getPDF(appointment_id) {
        

        return `${environment.apiUrl}/appointments/${appointment_id}/pdf`;
    }
}