<app-modal-data [title]="title" (closeModal)="closeModalF()">
    <div data class="w-full">
        <div class="grid grid-cols-1">
            <div class="mt-3 mx-4 text-left">
                <ul role="list" class="-my-5 divide-y divide-gray-200">
                    <li class="py-5" *ngFor="let item of items">
                      <div class="relative focus-within:ring-2 focus-within:ring-indigo-500">
                        <h3 class="text-sm font-semibold text-gray-800">
                         
                            <span class="absolute inset-0" aria-hidden="true"></span>
                            {{name}}: {{item.name}}
                        </h3>
                        <p class="mt-1 text-sm text-gray-600 line-clamp-2">
                          {{quantity}}: {{item.quantity}}
                        </p>
                      </div>
                    </li>
                    </ul>
            </div>
        </div>
    </div>
    <div button>
        
    </div>
</app-modal-data>