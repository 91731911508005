import { trigger, transition, style, animate } from "@angular/animations";
import { Component, OnInit, OnDestroy } from "@angular/core";
import { FormBuilder, FormGroup, Validators } from "@angular/forms";
import { DeviceDetectorService } from "ngx-device-detector";
import { Subscription } from "rxjs";
import { ModalService } from "src/app/shared/services/modal.service";
import { Validations } from "src/app/shared/settings/validation";
import { AppointmentsService } from "../appointments.service";
import { TermComponent } from "src/app/dashboard/home/menu/term/term.component";
import { SettingGeneralService } from "src/app/shared/services/settings-general.service";

@Component({
    selector: 'app-appointments-cmb',
    templateUrl: './cmb.component.html',
    animations: [
        trigger(
            'enterAnimation', [
            transition(':enter', [
                style({ transform: 'translateY(100%)', opacity: 0 }),
                animate('500ms', style({ transform: 'translateY(0)', opacity: 1 }))
            ]),
            transition(':leave', [
                style({ transform: 'translateY(100%)', opacity: 1 }),
                animate('500ms', style({ transform: 'translateY(0)', opacity: 0 }))
            ])
        ]
        )
    ],
})

export class AppointmentsCmbComponent implements OnInit, OnDestroy {
    
    isOpenMobile: boolean = true;
    submitted: boolean = false;
    customerExternalForm: FormGroup;
    allLoading = true;
    class: string = "grid grid-cols-2 gap-8 p-3";
    validationMaxString = Validations.validationMaxString;

    // mobile
    isMobile: boolean = false;

    private subscription = new Subscription();

    srcImage = '/assets/images/flor-circle.png';

    txt = '¡Bienvenida! , únete al metodo Naturhouse y empieza tu nueva vida. Dejanos tus datos y nos pondremo en contacto contigo lo antes posible.';
    
    constructor(
        private builder: FormBuilder,
        private modalService: ModalService,
        private deviceService: DeviceDetectorService,
        private appointmentsService: AppointmentsService,
        private settingGeneralService: SettingGeneralService
    ) {
        this.isMobile = this.deviceService.isMobile();
    }

    get f() { return this.customerExternalForm.controls; }
    
    ngOnInit(): void {
        this.customerExternalForm = this.builder.group({
            name: ['', [Validators.required, Validators.maxLength(this.validationMaxString.short_string)]],
            surnames: ['', [Validators.required, Validators.maxLength(this.validationMaxString.long_string)]],
            prefix: ['+34', [Validators.required]],
            mobile: ['', [Validators.required]],
            email: ['', [Validators.email, Validators.maxLength(this.validationMaxString.long_string)]],
            zip: ['', [Validators.required]],
            typeA: [null, [Validators.required]],
        });
        this.customerExternalForm.addControl('terms', this.builder.control(null, [Validators.required]));
    }

    closeModal() {
        this.modalService.destroy();
    }

    onSubmit() {
        this.submitted = true;

        if (this.customerExternalForm.invalid) {
            return;
        }

        let data = this.customerExternalForm.value;

        this.subscription.add(this.appointmentsService.saveCustomerCmb(data).subscribe(
            (response) => {
                this.allLoading = false;
                this.customerExternalForm.reset();
                
                Object.keys(this.customerExternalForm.controls).forEach(key => {
                    this.customerExternalForm.get(key).setErrors(null) ;
                  });

                this.txt = '¡Hemos registrado tus datos nuestras nutricionistas se pondran en contacto contigo lo antes posible!';
            },
            () => {
                this.allLoading = true;
            }
            
        ))
    }

    showTerms() {
        this.modalService.init(TermComponent, this.settingGeneralService.getLangText('terms_modal'), { closeModal: this.closeModal.bind(this) });
    }
    
    ngOnDestroy(): void {
        // throw new Error("Method not implemented.");
    }
}