<ng-container *ngIf="ingredients; else loading">
    <div class="flex min-w-0 flex-1 flex-col overflow-hidden">
        <div class="relative z-0 flex flex-1 overflow-hidden">
            <main *ngIf="showForm" [@showIngredient] class="relative z-0 flex-1 focus:outline-none xl:order-last">
                
                <nav class="flex items-start px-4 py-3 sm:px-6 lg:px-8 xl:hidden" aria-label="Breadcrumb">
                    <a class="inline-flex items-center space-x-3 text-sm font-medium text-gray-900">
                        <img class="-ml-2 h-5 w-5" src="assets/images/svg/chevron-left.svg" />
                        <span>
                            {{ 'data.ingredients.list' | translate }}
                        </span> 
                    </a> 
                </nav>

                <article>
                    <div class="border-2 rounded bg-white align-bottom inline-block overflow-hidden rounded-lg sm:align-middle text-left transform transition-all w-full"
                        role="dialog" aria-modal="true" aria-labelledby="modal-headline">
                        <div class="px-4 pt-3 sm:px-6 sm:flex sm:flex-row-reverse cursor-pointer">
                            <svg class="w-6 h-6 pb-2 cursor-pointer" fill="none" stroke="currentColor" viewBox="0 0 24 24"
                                xmlns="http://www.w3.org/2000/svg" (click)="changeView(false)">
                                <path stroke-linecap="round" stroke-linejoin="round" stroke-width="2" d="M6 18L18 6M6 6l12 12">
                                </path>
                            </svg>
                        </div>
                        <div class="w-full flex flex-col">
                            <app-ingredient-form [ingredient]="currentIngredient" (createOrUpdateList)="createOrUpdateList($event)"></app-ingredient-form>
                        </div>
                    </div>
                </article>
            </main>
            
            <aside *ngIf="!showForm" 
            [ngClass]="{'aside': !isToCreateRecipe, 'aside-half': isToCreateRecipe}"
            class="w-full flex-shrink-0 border-r border-gray-200 bg-white xl:order-first xl:flex xl:flex-col overflow-y-auto pb-8">
                <div class="px-6 pt-6 pb-4 flex items-center justify-between">
                    <h2 class="text-lg font-medium text-gray-900">{{ 'data.ingredients.list' | translate }}</h2>
                    <div class="flex -space-x-2" *ngIf="!isToCreateRecipe">
                        <button type="button" class="customer-info-button-white" (click)="changeView(true)">
                            <img class="customer-info-button-svg text-gray-500"
                                src="assets/images/svg/plus.svg" />
                                {{ 'data.createB' | translate }}
                        </button>
                    </div>
                </div>
                <div class="px-6">
                    <app-filter [filters]="filters" (sendFiltersEmitter)="filterChange($event)"></app-filter>
                </div>
                <!-- Directory list -->
                <nav class="min-h-0 flex-1 overflow-y-auto" #scroll (scroll)="onScroll($event)">
                    <div class="relative">
                        <ul role="list" class="relative z-0">
                            <li class="border m-3 rounded" *ngFor="let ingredient of ingredients">
                                <div (click)="clickIngredient(ingredient)" 
                                    class="relative px-6 py-5 
                                    flex items-center space-x-3
                                    focus-within:ring-2
                                    focus-within:ring-inset
                                    focus-within:ring-pink-500"
                                    [ngClass]="{ 'hover:bg-nutriwell-sansivera': !isToCreateRecipe}">
                                    <div class="flex-1 min-w-0">
                                        <a class="focus:outline-none">
                                            <p class="text-sm font-medium text-gray-900">
                                                {{ingredient.locales['0'].value}}    
                                            </p>
                                        </a>    
                                    </div>
                                    <div class="flex-1 min-w-0">
                                        <a class="focus:outline-none">
                                            <p class="text-sm font-medium text-gray-900">
                                                {{ingredient.calories}} Kcal  
                                            </p>
                                            <p class="text-sm text-gray-500 truncate">
                                                Energy
                                            </p>
                                        </a>    
                                    </div>
                                    <div class="flex-1 min-w-0">
                                        <a class="focus:outline-none">
                                            <p class="text-sm font-medium text-gray-900">
                                                {{ingredient.fat}} gr
                                            </p>
                                            <p class="text-sm text-gray-500 truncate">
                                                Fat
                                            </p>
                                        </a>    
                                    </div>
                                    <div class="flex-1 min-w-0">
                                        <a class="focus:outline-none">
                                            <p class="text-sm font-medium text-gray-900">
                                                {{ingredient.carbohydrates}} gr
                                            </p>
                                            <p class="text-sm text-gray-500 truncate">
                                                Carbohydrates
                                            </p>
                                        </a>    
                                    </div>
                                    <div class="flex-1 min-w-0">
                                        <a class="focus:outline-none">
                                            <p class="text-sm font-medium text-gray-900">
                                                {{ingredient.proteins}} gr
                                            </p>
                                            <p class="text-sm text-gray-500 truncate">
                                                Protein
                                            </p>
                                        </a>    
                                    </div>
                                    
                                    <img class="w-6 h-6" src="assets/images/svg/arrow-top-right-on-square.svg" *ngIf="!isToCreateRecipe; else inputIngredient"/>

                                    <ng-template #inputIngredient>
                                        <div class="flex flex-1 min-w-0 items-center">
                                            <input class="inputClass" type="number" step="1" [(ngModel)]="saveIngredient[ingredient.id]" />
                                            <img class="w-6 h-6" src="assets/images/svg/plus.svg" (click)="addIngredient(ingredient)"/>
                                        </div>
                                    </ng-template>
                                </div>
                            </li>
                        </ul>
                    </div>
                </nav>
            </aside>
        </div>
    </div>
</ng-container>

<ng-template #loading>
    <app-loading></app-loading>
</ng-template>