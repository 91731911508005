<ng-container *ngIf="isLoading ; else loading">
	<div class="px-4 py-6 sm:px-6 lg:px-8 ng-star-inserted">
		<div _ngcontent-exf-c104="" class="pb-5 border-b border-gray-200 space-y-3 sm:flex sm:items-center sm:justify-between sm:space-x-4 sm:space-y-0">
			<h3 _ngcontent-exf-c104="" class="text-lg leading-6 font-medium text-gray-900 float-left"> 
				Lista de chats 
			</h3>
		</div>
	</div>

	<div class="px-4 sm:px-6 lg:px-8 min-h-32" *ngIf="chatsExits ; else noChats">
		<div class="overflow-x-auto pt-6 pb-2 align-middle min-w-full">
			<div class="w-full border rounded lg:grid lg:grid-cols-3">
				<div class="border-r border-gray-300 lg:col-span-1">
					<div class="mx-3 my-3">
						<div class="relative text-gray-600">
							<span class="absolute inset-y-0 left-0 flex items-center pl-2">
								<svg fill="none" stroke="currentColor" stroke-linecap="round" stroke-linejoin="round" stroke-width="2"
								viewBox="0 0 24 24" class="w-6 h-6 text-gray-300">
								<path d="M21 21l-6-6m2-5a7 7 0 11-14 0 7 7 0 0114 0z"></path>
								</svg>
							</span>
							<input type="search" class="block w-full py-2 pl-10 bg-gray-100 rounded outline-none" name="search"
								placeholder="Search" required />
						</div>
					</div>

					<ul class="overflow-auto h-[32rem]">
						<h2 class="my-2 mb-2 ml-2 text-lg text-gray-600">Chats</h2>
						<li *ngFor="let chat of chats">
							<a (click)="changeChat(chat)" class="mb-2 flex items-center px-3 py-2 text-sm transition duration-150 ease-in-out border-b border-gray-300 cursor-pointer hover:bg-gray-100 focus:outline-none">
								<div class="w-full pb-2">
									<div class="flex justify-between">
										<span class="block ml-2 font-semibold text-gray-600">{{chat.customer_name}}</span>
										<span class="block ml-2 text-sm text-gray-600">{{chat.last_message_date | date:'short' }}</span>
									</div>
									<div class="flex justify-between">
										<span class="block ml-2 text-sm text-gray-600">{{chat.last_message_text}}</span>
										<span class="block ml-2 text-sm text-gray-600" *ngIf="chat.has_new_messages">
											<svg xmlns="http://www.w3.org/2000/svg" class="h-5 w-5 bg-red" viewBox="0 0 20 20" fill="bg-nutriwell">
												<path d="M10 2a6 6 0 00-6 6v3.586l-.707.707A1 1 0 004 14h12a1 1 0 00.707-1.707L16 11.586V8a6 6 0 00-6-6zM10 18a3 3 0 01-3-3h6a3 3 0 01-3 3z" />
											</svg>
										</span>
									</div>
								</div>
							</a>
						</li>
					</ul>
				</div>
				<div class="hidden lg:col-span-2 lg:block">
					<app-customer-chat [customer_id]="customer_id" [firstMessages]="firstMessages"></app-customer-chat>
				</div>
			</div>
		</div>
	</div>

	<ng-template #noChats>
		<h1>No chats active</h1>
	</ng-template>
</ng-container>


<ng-template #loading>
    <app-loading></app-loading>
</ng-template>
