import { Component, EventEmitter, Input, Output, OnInit } from '@angular/core';

@Component({
	selector: 'app-modal-data-min',
	templateUrl: './modal-data-min.component.html',
	//styleUrls: ['./modal.component.scss'],
})

export class ModalDataMinComponent implements OnInit {

	@Input() title: string;
    @Input() text: string;
    @Input() text_button_create: string;
    @Input() showCloseIcon: boolean = false;
    @Output() closeModal = new EventEmitter<void>();

    ngOnInit() {

    }

    closeModalF() {
        this.closeModal.emit();
    }
}