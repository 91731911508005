<div class="px-4 py-6 sm:px-6 lg:px-8" *ngIf="kindsAppointments; else loading;">
    <div
        class="pb-5 border-b border-gray-200 space-y-3 sm:flex sm:items-center sm:justify-between
        sm:space-x-4 sm:space-y-0">
        <h3 class="text-lg leading-6 font-medium text-gray-900">
            {{ 'settings.settings' | translate }}
        </h3>

        <span class="sm:ml-3 flex rounded-md">
            <button type="button" class="customer-info-button-reverse" (click)="submit()">
                <img src="assets/images/svg/check-white.svg" class="customer-info-button-svg"
                    alt="check">
                {{ 'customer_edit.save' | translate }}
            </button>
        </span>
    </div>

    <div class="mt-6 bg-white shadow px-4 py-5 sm:rounded-lg sm:p-6 mb-10">
        <div class="md:grid md:grid-cols-3 md:gap-6">
            <div class="md:col-span-1">
                <h3 class="text-lg font-medium leading-6 text-gray-900">{{ 'settings.change_team' |
                    translate }}</h3>
                <p class="mt-1 text-sm leading-5 text-gray-500">
                    {{ 'settings.change_team' | translate }}
                </p>
            </div>
            
        </div>
    </div>

    <div class="mt-6 bg-white shadow px-4 py-5 sm:rounded-lg sm:p-6 mb-10">
        <div class="md:grid md:grid-cols-3 md:gap-6">
            <div class="md:col-span-1">
                <h3 class="text-lg font-medium leading-6 text-gray-900">{{ 'settings.appointments' |
                    translate }}</h3>
                <p class="mt-1 text-sm leading-5 text-gray-500">
                    {{ 'settings.appointments_text' | translate }}
                </p>
            </div>
            <div class="mt-5 md:mt-0 md:col-span-2">
                <div class="grid grid-cols-6 gap-6">
                    <div class="col-span-6 sm:col-span-3">
                        <app-label-form for="name" name="{{'kind_appointments.name' | translate}}"></app-label-form>
                        <div class="relative">
                            <span class="inline-block w-full rounded-md shadow-sm">
                                <button type="button" (click)="showListKind= !showListKind"
                                    class="cursor-default relative w-full rounded-md border
                                    border-gray-300 bg-white pl-3 pr-10 py-2 text-left
                                    focus:outline-none transition ease-in-out duration-150
                                    sm:text-sm sm:leading-5">
                                    <div class="flex items-center space-x-3">
                                        <span aria-label="Online" class="flex-shrink-0 inline-block
                                            h-2 w-2 rounded-full"
                                            [ngStyle]="{'background-color':
                                            firstKindAppointment.color}">
                                        </span>
                                        <span class="block truncate">
                                            {{firstKindAppointment.name}}
                                        </span>
                                    </div>
                                    <span class="absolute inset-y-0 right-0 flex items-center pr-2
                                        pointer-events-none">
                                        <img src="assets/images/svg/selector.svg"
                                            class="h-5 w-5 text-gray-400" alt="check">
                                    </span>
                                </button>
                            </span>

                            <div class="absolute mt-1 w-full rounded-md bg-white shadow-lg"
                                *ngIf="showListKind">
                                <ul tabindex="-1" role="listbox" aria-labelledby="listbox-label"
                                    aria-activedescendant="listbox-item-3"
                                    class="max-h-60 rounded-md py-1 text-base leading-6 ring-1
                                    ring-black ring-opacity-5 overflow-auto focus:outline-none
                                    sm:text-sm sm:leading-5">

                                    <li id="listbox-item-0" *ngFor="let kinds of kindsAppointments"
                                        (click)="changeKind(kinds)"
                                        class="cursor-default select-none relative py-2 pl-3 pr-9">
                                        <div class="flex items-center space-x-3">
                                            <span [ngStyle]="{'background-color': kinds.color}"
                                                class="flex-shrink-0 inline-block h-2 w-2
                                                rounded-full"></span>
                                            <span class="font-normal block truncate">
                                                {{kinds.name}}
                                            </span>
                                        </div>
                                    </li>
                                </ul>
                            </div>
                        </div>
                    </div>
                    <div class="col-span-6 sm:col-span-3">
                        <app-label-form for="name" name="{{ 'settings.color' | translate }}"></app-label-form>
                        <input class="border h-10 rounded w-full" id="name" type="color"
                            [(ngModel)]="currentColor"
                            (ngModelChange)="changeColor($event)" />
                    </div>
                </div>
            </div>
        </div>
    </div>
</div>

<ng-template #loading>
    <app-loading></app-loading>
</ng-template>