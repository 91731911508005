
import { NgModule } from '@angular/core';
import { Routes, RouterModule } from '@angular/router';

import { HomeComponent } from './home/home.component';
import { CalendarComponent } from './calendar/calendar.component';
import { AuthGuard } from '../shared/guards/auth.guard';
import { NgxPermissionsGuard } from 'ngx-permissions';
import { SettingsComponent } from './settings/settings.component';
import { ProfileComponent } from './profile/profile.component';
import { ChatsComponent } from './chats/chats.component';
import { IngredientComponent } from './admin/data/ingredient/ingredient.component';

const routes: Routes = [
    {
        path: '',
        component: HomeComponent,
        canActivate: [AuthGuard],
        children: [
            { path: '', redirectTo: 'customers', pathMatch: 'full' },
            {
                path: 'calendar',
                component: CalendarComponent,
            },
            {
                path: 'customers',
                loadChildren: () => import('./customer/customer.module').then(m => m.CustomerModule)
            },
            {
                path: 'chats',
                component: ChatsComponent
            },
            {
                path: 'settings',
                component: SettingsComponent
            },
            {
                path: 'profile',
                component: ProfileComponent
            },
            {
                path: 'ingredients',
                component: IngredientComponent,
                canActivate: [NgxPermissionsGuard],
                data: {
                    permissions: {
                        only: 'ADMIN',
                        redirectTo: '/calendar'
                    }
                }
            },
            {
                path: 'recipes',
                loadChildren: () => import('./admin/data/recipe/recipe.module').then(m => m.RecipeModule),
                canActivate: [NgxPermissionsGuard],
                data: {
                    permissions: {
                        only: 'ADMIN',
                        redirectTo: '/calendar'
                    }
                }
            },
            {
                path: 'diets',
                loadChildren: () => import('./admin/data/diet/diet.module').then(m => m.DietModule),
                canActivate: [NgxPermissionsGuard],
                data: {
                    permissions: {
                        only: 'ADMIN',
                        redirectTo: '/calendar'
                    }
                }
            },
        ]
    }
];

@NgModule({
    imports: [RouterModule.forChild(routes)],
    exports: [RouterModule]
})
export class DashboardRoutingModule {

}