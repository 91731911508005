<app-snackbar></app-snackbar>
<div class="h-screen flex overflow-hidden bg-gray-100">

    <app-menu #menu></app-menu>

    <div class="flex flex-col w-0 flex-1 overflow-hidden">
        <div class="relative z-20 flex-shrink-0 flex h-16 bg-white shadow">

            <!-- short menu -->
            <div class="pl-4 lg:flex justify-between hidden">
                <button (click)="menu.smallNavBar()"
                    class="p-1 text-gray-400 rounded-full focus:outline-none
                    focus:text-gray-500">
                    <img src="assets/images/svg/menu.svg" class="w-6 h-6" />
                </button>
            </div>

            <!-- menu mobile -->
            <button (click)="menu.changeValue()"
                class="px-4 border-r border-gray-200 text-gray-500
                focus:outline-none focus:bg-gray-100 focus:text-gray-600
                lg:hidden"
                aria-label="Open sidebar">
                <img src="assets/images/svg/menu.svg" class="w-6 h-6" />
            </button>

            <div class="flex-1 flex justify-between">
                <div class="flex-1 flex">
                    <app-breadcrumb></app-breadcrumb>
                </div>
                <div class="ml-4 flex items-center md:ml-6">
                    <button class="icons" (click)="isOpen= !isOpen">
                            <img class="w-6 h-6 rounded-full"
                                [src]='(loginService.imgProfileSource$ | async)'
                                alt="">
                    </button>

                    <button *ngIf="isOpen || isCountry" (click)="disabled()"
                            tabindex="-1" class="fixed inset-0 h-full w-full bg-black
                            opacity-50 cursor-nutriwell">
                    </button>

                    <!-- dropdown -->
                    <div class="ml-3 relative">
                        <div class="div_dropdown" *ngIf="isOpen">
                            <div class="sub_div_dropdown" role="menu"
                                aria-orientation="vertical"
                                aria-labelledby="user-menu">
                                <a routerLink="profile" class="menu" (click)="disabled()" role="menuitem">
                                    {{ 'profile.profile' | translate }}
                                </a>

                                <a class="menu" (click)="logout()">
                                    {{ 'profile.logout' | translate }}
                                </a>
                            </div>
                        </div>

                       
                    </div>
                </div>
            </div>
        </div>

        <main class="main flex-1 relative overflow-y-auto focus:outline-none" tabindex="0">
            <div class="pt-2 pb-6 md:py-6">
                <div class="mx-auto px-4 sm:px-6 md:px-8">
                    <router-outlet></router-outlet>
                </div>
            </div>
        </main>
    </div>
</div>