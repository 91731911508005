import { Component, EventEmitter, Input, OnInit, Output, ViewChild } from '@angular/core';
import { FormBuilder, FormGroup, Validators } from '@angular/forms';
import { FlatpickrOptions } from 'ng2-flatpickr';
import { SettingGeneralService } from 'src/app/shared/services/settings-general.service';
import * as moment from 'moment';
import { CustomerCreated } from 'src/app/shared/interfaces/customers.interface';
import { UtilsService } from 'src/app/shared/services/util.service';
import { Validations } from 'src/app/shared/settings/validation';
import { Illnes } from 'src/app/shared/interfaces/illnes.interface';
import { OptionI } from 'src/app/shared/interfaces/option.interface';
import { AutocompleteMapService } from 'src/app/shared/services/autcomplete-maps.service';
import { Gender } from 'src/app/shared/interfaces/gender.interface';
import { ModalService } from '../../services/modal.service';
import { TermComponent } from 'src/app/dashboard/home/menu/term/term.component';
import { TagService } from '../../services/tag.service';
import { Tag } from '../../interfaces/tag.interface';
import { CustomerEditTagService } from 'src/app/dashboard/customer/customer-edit/tags/customer-edit-tags.service';


@Component({
    selector: 'app-customer-general',
    templateUrl: './customer.component.html',
    styleUrls: ['./customer.component.scss']
})

export class CustomerGenericComponent implements OnInit {

    @Input() term: boolean;
    @Input() customer: any;
    @Input() class: string;
    @Input() id_team: any;
    @Input() zipCode: string;
    @Input() zipcodesConfig: any;
    @Output() submit = new EventEmitter<any>();

    customerForm: FormGroup;
    submitted = false;

    under: boolean = false;
    legal_age: number;
    validationMaxString = Validations.validationMaxString;

    dateOptions: FlatpickrOptions;

    options: OptionI[] = [];
    illnesses: Illnes[] = [];
    genders: Gender[] = [];

    autocompleteOptions;
    noCpError: boolean = false;

    gender: number = 0;
    illness: number = 0;
    supplement: number = 0;
    type: number = 1;

    loading: boolean = true;
    settingsValue;
    prohibitedError: boolean = false;
    in_my_team: boolean = true;

    tags;
    customerTags = [2,3];

    constructor(
        private builder: FormBuilder,
        public settingGeneralService: SettingGeneralService,
        private utilService: UtilsService,
        private modalService: ModalService,
        private autocompleteService: AutocompleteMapService,
        private tagService: TagService,
        private customerTagService: CustomerEditTagService
    ) {
        this.options.push({ id: 1, text: this.settingGeneralService.getLangText("options.yes") });
        this.options.push({ id: 0, text: this.settingGeneralService.getLangText('options.no') });

        this.illnesses.push({ id: 1, text: this.settingGeneralService.getLangText('illnesses.diabetes') });
        this.illnesses.push({ id: 2, text: this.settingGeneralService.getLangText('illnesses.heart') });
        this.illnesses.push({ id: 3, text: this.settingGeneralService.getLangText('illnesses.cancer') });
        this.illnesses.push({ id: 4, text: this.settingGeneralService.getLangText('illnesses.none') });

        this.genders.push({ id: 2, text: this.settingGeneralService.getLangText("genders.male") });
        this.genders.push({ id: 1, text: this.settingGeneralService.getLangText('genders.female') });

        this.settingGeneralService.changeCountry$.subscribe(
            () => {
                this.settingsValue = this.settingGeneralService.getSettings();
            }
        );
        this.dateOptions = {
            locale: this.settingsValue.flatpickr,
            dateFormat: this.settingsValue.formatFlatpickr,
            maxDate: moment().format(this.settingsValue.formatMoment),
            disableMobile: true,
        };
    }

    ngOnInit(): void {
        console.log('customer-generic');
        const prefix = JSON.parse(localStorage.getItem('currentUser')) ?
        JSON.parse(localStorage.getItem('currentUser')).teams.config.prefix :
        this.settingsValue.prefix;

        if(!this.zipcodesConfig) {
            this.zipcodesConfig = {
                'zipcodes': [],
                'length': null,
                'acceptAlpha': 1
            };
        }
        let team_id = this.id_team ? this.id_team : (this.settingGeneralService.auth).getUserVariable('current_team_id');
        
        this.autocompleteService.renderExternalScript(this.settingsValue.locale).onload = () => {
            this.customerForm = this.builder.group({
                gender: [1, [Validators.required, Validators.min(1)]],
                name: ['', [Validators.required, Validators.maxLength(this.validationMaxString.short_string)]],
                surnames: ['', [Validators.required, Validators.maxLength(this.validationMaxString.long_string)]],
                prefix: [prefix, [Validators.required]],
                mobile: ['', [Validators.required]],
                email: ['', [Validators.required, Validators.email, Validators.maxLength(this.validationMaxString.long_string)]],
                team_id: [team_id, [Validators.required]],
                supplement: [1, [Validators.required, Validators.min(1)]],
                illness: [4, [Validators.required, Validators.min(1)]],
                zip: [this.zipCode, [Validators.required]],
                dob: [null, [Validators.required]],
                legal_checkbox: [null],
                legal_name: [null, [Validators.maxLength(this.validationMaxString.short_string)]],
                legal_surnames: [null, [Validators.maxLength(this.validationMaxString.long_string)]],
                legal_identity: [null, [Validators.maxLength(this.validationMaxString.short_string)]],
                type: [1, [Validators.required, Validators.min(1)]],
            });

            if(this.zipCode) {
                this.customerForm.get('zip').disable({ onlySelf: true });
            }

            if (this.term) {
                this.customerForm.addControl('terms', this.builder.control(null, [Validators.required]));
            }
            
            this.customerForm.get('illness').setValue(4);
            this.customerForm.get('supplement').setValue(1);

            if (this.customer) {
                this.in_my_team = this.customer.in_my_team;
                this.gender = this.customer.gender;
                this.type = this.customer.type;
                this.illness = this.customer.illness;
                this.supplement = this.customer.supplement;
                this.customerForm.patchValue(this.customer);
                this.customerForm.patchValue({ dob: { 0: this.customer.dob } })

                this.dateOptions.defaultDate = moment(this.customer.dob).format(this.settingsValue.formatMoment);
            } else {
                this.gender = 1;
                this.type = 1;
                this.illness = 4;
                this.supplement = 1;
            }
         
            this.autocompleteOptions = {
                types: ['(regions)'],
                componentRestrictions: { country: this.settingsValue.zip_maps }
            };

            this.loading = false;
        }

        if(this.customer) {
            this.tagService.getTagsByTeamId(team_id).subscribe(
                (response) => {
                    this.tags = response;

                    this.customerTagService.getTagsByCustomerId(this.customer.id).subscribe(
                        (response) => {
                            this.customerTags = response.map(tag => tag.id);
                            
                            this.tags.forEach(tag => {
                                const isInArray = this.customerTags.indexOf(tag.id);
                                if(isInArray >= 0) {
                                    tag.selected = true;
                                }
                            });
                        }
                    )
                }
            );
        }
    }

    handleAddressChange($event) {
        this.f['zip'].setValue(null);
        this.noCpError = false;

        const name = this.autocompleteService.autoComplete($event);

        this.f['zip'].setValue(name);

        if (null == this.f['zip'].value) {
            this.noCpError = true;
        }
    }

    public onChangeSelect(name, value) {
        this.customerForm.controls[name].setValue(parseInt(value));
    }

    get f() { return this.customerForm.controls; }

    onSubmit() {
        
        this.submitted = true;

        if (this.customerForm.invalid) {
            if (this.term) {
                for (const field in this.customerForm.controls) { // 'field' is a string
                    const control = this.customerForm.get(field);
                    if(!control.valid){
                        this.settingGeneralService.insertDataLayer('Form Error', field);
                        
                    } 
                } 
            } else {
                console.log(this.customerTags)
            }
            return;
        }

        let customer: CustomerCreated = this.utilService.clear(this.customerForm.value);
        
        if (customer.dob) {
            if(typeof customer.dob === 'object') {
                customer.dob = moment(customer.dob[0]).format('YYYY-MM-DD');
            }
        }

        if(this.zipCode){
            customer.zip = this.zipCode;
        }

        if (this.customerTags.length > 0) {
            customer.tags = this.customerTags;
        }
        
        this.customer = customer;
        
        this.submit.emit(customer);
    }

    showTerms() {
        this.modalService.init(TermComponent, this.settingGeneralService.getLangText('terms_modal'), { closeModal: this.closeModal.bind(this) });
    }

    closeModal() {
        this.modalService.destroy();
    }

    changeDob($event) {
        const dob = $event.target.value;
        const dobFormat = moment(dob, this.settingsValue.formatMoment);
        const last = moment().subtract(this.settingsValue.legal_age, 'years');

        const legalFields = ['legal_checkbox', 'legal_name', 'legal_surnames', 'legal_identity'];
        if (dobFormat.isAfter(last, 'day')) {
            legalFields.forEach(element => {
                this.customerForm.get(element).setValidators([Validators.required]);
            });

            this.under = true;
        } else {
            legalFields.forEach(element => {
                let currentElement = this.customerForm.get(element);
                currentElement.clearValidators();
                currentElement.setValue('');
            });

            this.under = false;
        }
        legalFields.forEach(element => {
            this.customerForm.get(element).updateValueAndValidity();
        });
    }

    findZipcode($event) {
        const zipcode = $event.target.value;
        this.prohibitedError = false;
        
        if(this.zipcodesConfig.zipcodes && this.zipcodesConfig.zipcodes.includes(zipcode)) {
            this.prohibitedError = true;
            console.log(1);
            this.customerForm.get('zip').setValue(null);
        } else {
            if(this.zipcodesConfig.length && zipcode.length > this.zipcodesConfig.length) {
                
                this.prohibitedError = true;
                this.customerForm.get('zip').setValue(null);
            } 
        }
    }

    addRemoveTagId(tag: Tag) {
        const id = tag.id;

        const isInArray = this.customerTags.indexOf(id);
        if(isInArray < 0) {
            this.customerTags.push(id);
            tag.selected = true;
        } else {
            this.customerTags = this.customerTags.filter(cTag => cTag != id)
            tag.selected = false;
        }
    }
}