<div class="w-full p-2">
    <div class="flex flex-wrap" [formGroup]="blockForm">
        <div class="w-full sm:w-1/3 p-2">
            <app-label-form for="start_date" name="{{ 'block.start_date' | translate
                }}">
            </app-label-form>
            <ng2-flatpickr formControlName="start_date" #calendar addClass="inputClass"
                [config]="dateOptions" (change)="changeDay()"
                placeholder="{{ 'block.start_date' | translate }}">
            </ng2-flatpickr>
            <ng-container *ngIf="submitted && f.start_date.errors">
                <app-error-form error="{{'errors.required' | translate}}">
                </app-error-form>
            </ng-container>
        </div>
        <div class="w-full sm:w-1/3 p-2">
            <app-label-form for="finish_date" name="{{ 'block.finish_date' | translate
                }}">
            </app-label-form>
            <ng2-flatpickr formControlName="finish_date" #calendar addClass="inputClass"
                [config]="dateOptions" (change)="changeDay()"
                placeholder="{{ 'block.finish_date' | translate }}">
            </ng2-flatpickr>
            <ng-container *ngIf="submitted">
                <app-error-form *ngIf="f.finish_date.errors" error="{{'errors.required' |
                    translate}}">
                </app-error-form>
                <app-error-form *ngIf="finish_date_error" error="{{'block.finish_date_error' |
                    translate}}">
                </app-error-form>
            </ng-container>
        </div>
        <div class="w-full sm:w-1/3 p-2 mt-8">
            <div class="ml-4 pb-2 flex">
                <span role="checkbox" tabindex="0" aria-checked="false" (click)="changeSelected()"
                    class="relative inline-flex flex-shrink-0 h-6 w-11 border-2
                    border-transparent rounded-full cursor-pointer transition-colors ease-in-out
                    duration-200 focus:outline-none"
                    [ngClass]="{'bg-gray-200': !selected, 'bg-nutriwell': selected}">
                    <span
                        class="translate-x-0 relative inline-block h-5 w-5 rounded-full bg-white
                        shadow transform transition ease-in-out duration-200"
                        [ngClass]="{'translate-x-0': !selected, 'translate-x-5': selected}">
                        <span
                            class="absolute inset-0 h-full w-full flex items-center
                            justify-center transition-opacity"
                            [ngClass]="{ 'off': selected, 'on': !selected }">
                            <img class="h-3 w-3 text-gray-400"
                                src="/assets/images/svg/check.svg" />
                        </span>
                        <span
                            class="absolute inset-0 h-full w-full flex items-center
                            justify-center transition-opacity"
                            [ngClass]="{ 'on': selected, 'off': !selected }">
                            <img class="h-3 w-3 text-gray-400"
                                src="/assets/images/svg/check.svg" />
                        </span>
                    </span>
                </span>
                <p class="ml-2" class="text-nutriwell">
                    {{ 'block.all_day' | translate }}
                </p>
            </div>
        </div>
        <div class="w-full sm:w-1/3 p-2" *ngIf="!selected">
            <app-label-form for="start_hour" name="{{ 'block.start_hour' | translate }}"></app-label-form>
            <ng2-flatpickr formControlName="start_hour" #calendar addClass="inputClass"
                [config]="dateOptionsTime"
                placeholder="{{ 'block.start_hour' | translate }}">
            </ng2-flatpickr>
            <ng-container *ngIf="submitted && f.start_hour.errors">
                <app-error-form error="{{'errors.required' | translate}}">
                </app-error-form>
            </ng-container>
        </div>
        <div class="w-full sm:w-1/3 p-2" *ngIf="!selected">
            <app-label-form for="finish_hour" name="{{ 'block.finish_hour' | translate }}"></app-label-form>
            <ng2-flatpickr formControlName="finish_hour" #calendar addClass="inputClass"
                [config]="dateOptionsTime"
                placeholder="{{ 'block.finish_hour' | translate }}">
            </ng2-flatpickr>
        
            <ng-container *ngIf="submitted && f.finish_hour.errors">
                <app-error-form error="{{'errors.required' | translate}}">
                </app-error-form>
            </ng-container>
        </div>
        <div class="w-full sm:w-1/3 p-2" *ngIf="!selected">
            <app-label-form for="period" name="{{ 'block.period_title' | translate }}">
            </app-label-form>
            <select formControlName="period" class="bg-white inputClass" id="build">
                <option value="{{ builds.id }}" *ngFor="let builds of periodValues">{{
                    builds.text }}
                </option>
            </select>
            <ng-container *ngIf="submitted && f.period.errors">
                <app-error-form error="{{'errors.required' | translate}}">
                </app-error-form>
            </ng-container>
        </div>
    </div>
    <div class="p-2 flex justify-end">
        <button type="submit" (click)="createBlock()"
            class="customer-info-button">
            {{text_button_create}}
        </button>
    </div>
</div>