import { ThisReceiver } from '@angular/compiler';
import { Component, OnDestroy, OnInit } from '@angular/core';
import { Subscription } from 'rxjs';
import { AppService } from 'src/app/app.service';
import { Chat, ChatMessage } from 'src/app/shared/interfaces/chat.interface';
import { ChatService } from 'src/app/shared/services/chats.service';

@Component({
    selector: 'app-chats',
    templateUrl: './chats.component.html',
})
export class ChatsComponent implements OnInit, OnDestroy {
    
    customer_id: number;

    firstMessages: ChatMessage[];

    chats: Chat[] = [];
    isLoading: boolean = false;
    chatsExits: boolean = true;

    subscription: Subscription = new Subscription();

    constructor(
        private chatService: ChatService,
        private appService: AppService
    ) {
    }

    ngOnInit() {
        this.appService.setHasNewMessage(false);

        this.subscription.add(
            this.chatService.getChats().subscribe(
                (response) => {
                    this.chats = response.chats;
                    
                    if(this.chats.length > 0) {
                        this.getChat();
                    } else {
                        this.chatsExits = false;
                        this.isLoading = true;
                    }
                }
            )
        )
     }

    private getChat() {
        this.customer_id = this.chats[0].customer_id;
        this.firstMessages = this.chats[0].messages.reverse();

        
        this.isLoading = true;
    }

    changeChat(chat: Chat) {
        if(chat.has_new_messages) {
            this.subscription.add(
                this.chatService.setHasNewMessages(chat.customer_id).subscribe(
                    () => chat.has_new_messages = false
                )
            );
        }
        this.customer_id = chat.customer_id;
        this.firstMessages = chat.messages.reverse();
    }

    ngOnDestroy(): void {
        this.subscription.unsubscribe();
    }
}