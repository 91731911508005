<app-appointments-header [slug]="slug.toLocaleLowerCase()"></app-appointments-header>

<div class="max-w-7xl mx-auto px-4 sm:px-6 lg:px-8">
    <div class="mx-auto">
        <div class="flex-1 flex flex-col p-8">
            <img class="w-32 h-32 flex-shrink-0 mx-auto bg-white rounded-full"
                [src]="srcImage"
                alt="">

            <div class="text-center">
                <p class="title pt-2">
                    {{ 'external_appointment_calendar.list.choose' | translate }}
                </p>
                
                <dl class="mt-5 grid grid-cols-1 gap-4 sm:grid-cols-2">
                    <div class="flex items-center rounded-3xl px-4 py-5 shadow rounded-lg overflow-hidden sm:p-6 cursor-pointer"  
                    (click)="calendar(user.slug)" 
                    *ngFor="let user of users">
                        <div class="grid grid-cols-1 sm:grid-cols-3 items-center">
                            <p class="mt-1 mr-7 text-3xl font-semibold text-white">
                                <img class="w-32 h-32 flex-shrink-0 mx-auto bg-white rounded-full" [src]="user.image" alt="" />
                            </p>
                            <div class="flex flex-col">
                                <p class="text" style="color: #5F5E5E !important">
                                    {{user.name}}
                                </p>
                                <p class="text-xs" style="color: #5F5E5E !important">
                                    {{ 'external_appointment_calendar.list.location' | translate }}: {{user.province}}
                                </p>
                                <p class="text-xs" style="color: #5F5E5E !important">
                                    {{ 'external_appointment_calendar.list.languages' | translate }}: {{user.languages}}
                                </p>
                            </div>
                            <div class="ml-3 rounded-lg border border-gray-300 bg-white p-3
                                    hover:border-gray-400 text-center text-sm">
                                <p class="font-medium text-gray-900">
                                    {{ 'external_appointment_calendar.list.schedule' | translate }}
                                </p>
                            </div>
                        </div>
                    </div>
                </dl>
            </div>
        </div>
    </div>
</div>

<ng-template #loading>
    <app-loading></app-loading>
</ng-template>