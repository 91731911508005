
import Spanish from 'flatpickr/dist/l10n/es.js';
import Polish from 'flatpickr/dist/l10n/pl.js';
import French from 'flatpickr/dist/l10n/fr.js';
import Italian from 'flatpickr/dist/l10n/it.js';
import German from 'flatpickr/dist/l10n/de.js';
import Croatian from 'flatpickr/dist/l10n/hr.js';
import english from 'flatpickr/dist/l10n/default.js';
import Portuguese from 'flatpickr/dist/l10n/pt.js';

export class Settings {

    public static langs = ['es', 'en', 'fr', 'it', 'pl', 'de', 'pt'];

    public static langSettings = {
        'de': {
            'locale': 'de',
            'flatpickr': German.de,
            'formatFlatpickr': 'd-m-Y',
            'formatMoment': 'DD-MM-YYYY',
            /* 'legal_age': 18,
            'weekStartsOn': DAYS_OF_WEEK.MONDAY,
            'weekendDays': [DAYS_OF_WEEK.SATURDAY, DAYS_OF_WEEK.SUNDAY],
            'formatMoment': 'DD-MM-YYYY',
            'formatFlatpickr': 'd-m-Y',
            'formatFlatpickrHour': 'd-m-Y H:i',
            'zip_maps': ['es'],
            'prefix': '+34', */
        },
        'es': {
            'locale': 'es',
            'flatpickr': Spanish.es,
            'formatFlatpickr': 'd-m-Y',
            'formatMoment': 'DD-MM-YYYY',
            /* 'legal_age': 18,
            'weekStartsOn': DAYS_OF_WEEK.MONDAY,
            'weekendDays': [DAYS_OF_WEEK.SATURDAY, DAYS_OF_WEEK.SUNDAY],
            'formatMoment': 'DD-MM-YYYY',
            'formatFlatpickr': 'd-m-Y',
            'formatFlatpickrHour': 'd-m-Y H:i',
            'zip_maps': ['es'],
            'prefix': '+34', */
        },
        'ie': {
            'locale': 'en',
            'flatpickr': english,
            'formatFlatpickr': 'm-d-Y',
            'formatMoment': 'MM-DD-YYYY',
            /* 'legal_age': 21,
            'weekStartsOn': DAYS_OF_WEEK.SUNDAY,
            'weekendDays': [DAYS_OF_WEEK.FRIDAY, DAYS_OF_WEEK.SATURDAY],
            'formatMoment': 'MM-DD-YYYY',
            'formatFlatpickr': 'm-d-Y',
            'formatFlatpickrHour': 'm-d-Y H:i',
            'zip_maps': ['gb', 'us'],
            'prefix': '+1' */
        },
        'en': {
            'locale': 'en',
            'flatpickr': english,
            'formatFlatpickr': 'm-d-Y',
            'formatMoment': 'MM-DD-YYYY',
            /* 'legal_age': 21,
            'weekStartsOn': DAYS_OF_WEEK.SUNDAY,
            'weekendDays': [DAYS_OF_WEEK.FRIDAY, DAYS_OF_WEEK.SATURDAY],
            'formatMoment': 'MM-DD-YYYY',
            'formatFlatpickr': 'm-d-Y',
            'formatFlatpickrHour': 'm-d-Y H:i',
            'zip_maps': ['gb', 'us'],
            'prefix': '+1' */
        },
        'fr': {
            'locale': 'fr',
            'flatpickr': French.fr,
            'formatFlatpickr': 'd-m-Y',
            'formatMoment': 'DD-MM-YYYY',
            /* 'legal_age': 18,
            'weekStartsOn': DAYS_OF_WEEK.MONDAY,
            'weekendDays': [DAYS_OF_WEEK.SATURDAY, DAYS_OF_WEEK.SUNDAY],
            'formatMoment': 'DD-MM-YYYY',
            'formatFlatpickr': 'd-m-Y',
            'formatFlatpickrHour': 'd-m-Y H:i',
            'zip_maps': ['fr'],
            'prefix': '+33' */
        },
        'it': {
            'locale': 'it',
            'flatpickr': Italian.it,
            'formatFlatpickr': 'd-m-Y',
            'formatMoment': 'DD-MM-YYYY',
            /*'legal_age': 18,
            'weekStartsOn': DAYS_OF_WEEK.MONDAY,
            'weekendDays': [DAYS_OF_WEEK.SATURDAY, DAYS_OF_WEEK.SUNDAY],
            'formatMoment': 'DD-MM-YYYY',
            'formatFlatpickr': 'd-m-Y',
            'formatFlatpickrHour': 'd-m-Y H:i',
            'zip_maps': ['it'],
            'prefix': '+39' */
        },
        'pl': {
            'locale': 'pl',
            'flatpickr': Polish.pl,
            'formatFlatpickr': 'd-m-Y',
            'formatMoment': 'DD-MM-YYYY',
            /* 'legal_age': 18,
            'weekStartsOn': DAYS_OF_WEEK.MONDAY,
            'weekendDays': [DAYS_OF_WEEK.SATURDAY, DAYS_OF_WEEK.SUNDAY],
            'formatMoment': 'DD-MM-YYYY',
            'formatFlatpickr': 'd-m-Y',
            'formatFlatpickrHour': 'd-m-Y H:i',
            'zip_maps': ['pl'],
            'prefix': '+48' */
        },
        'hr': {
            'locale': 'hr',
            'flatpickr': Croatian.hr,
            'formatFlatpickr': 'd-m-Y',
            'formatMoment': 'DD-MM-YYYY',
            /* 'legal_age': 18,
            'weekStartsOn': DAYS_OF_WEEK.MONDAY,
            'weekendDays': [DAYS_OF_WEEK.SATURDAY, DAYS_OF_WEEK.SUNDAY],
            'formatMoment': 'DD-MM-YYYY',
            'formatFlatpickr': 'd-m-Y',
            'formatFlatpickrHour': 'd-m-Y H:i',
            'zip_maps': ['pl'],
            'prefix': '+48' */
        },
        'pt': {
            'locale': 'pt',
            'flatpickr': Portuguese.pt,
            'formatFlatpickr': 'd-m-Y',
            'formatMoment': 'DD-MM-YYYY',
            /* 'legal_age': 18,
            'weekStartsOn': DAYS_OF_WEEK.MONDAY,
            'weekendDays': [DAYS_OF_WEEK.SATURDAY, DAYS_OF_WEEK.SUNDAY],
            'formatMoment': 'DD-MM-YYYY',
            'formatFlatpickr': 'd-m-Y',
            'formatFlatpickrHour': 'd-m-Y H:i',
            'zip_maps': ['pl'],
            'prefix': '+48' */
        },
    };
}