import { NgModule } from '@angular/core';
import { CommonModule, registerLocaleData } from '@angular/common';

import localeEs from '@angular/common/locales/es';
import localeEn from '@angular/common/locales/en';
import localeFr from '@angular/common/locales/it';
import localeIt from '@angular/common/locales/fr';
import localeDe from '@angular/common/locales/de';
import localePl from '@angular/common/locales/pl';
import localeHr from '@angular/common/locales/hr';
import localePt from '@angular/common/locales/pt';

import { DashboardRoutingModule } from './dashboard-routing.module';

import { RouterModule } from '@angular/router';
import { SharedModule } from '../shared/modules/shared.module';

import { HomeComponent } from './home/home.component';
import { CalendarComponent } from './calendar/calendar.component';
import { MenuComponent } from './home/menu/menu.component';
import { CustomerComponent } from './home/menu/customer/customer.component';

import { ModalDataComponent } from '../shared/components/modals/data/modal-data.component';
import { SettingsComponent } from './settings/settings.component';
import { AppointmentComponent } from './home/menu/calendar/appointment/appointment.component';
import { CustomerEditCrashDietComponent } from './home/menu/crashDiet/customer-edit-crash-diet.component';
import { ProfileComponent } from './profile/profile.component';
import { TermComponent } from './home/menu/term/term.component';
import { CalendarModalComponent } from './home/menu/calendar/calendar-modal.component';
import { BlockComponent } from './home/menu/calendar/block/block-modal.component';
import { NewAppointmentComponent } from './home/menu/appointment/new-appointment.component';
import { ShowOrderModalComponent } from './home/menu/order/show-order-modal.component';
import { ChatsComponent } from './chats/chats.component';
import { FindTeamByZipComponent } from './home/menu/external/findZip/find-team-zip.component';
import { ModalDataMinComponent } from '../shared/components/modals/data-min/modal-data-min.component';

registerLocaleData(localeEs);
registerLocaleData(localeEn);
registerLocaleData(localeFr);
registerLocaleData(localeIt);
registerLocaleData(localeDe);
registerLocaleData(localePl);
registerLocaleData(localeHr);
registerLocaleData(localePt);

@NgModule({
    declarations: [
        MenuComponent,
        HomeComponent,
        CalendarComponent,
        ModalDataComponent,
        ModalDataMinComponent,
        CustomerComponent,
        CalendarModalComponent,
        AppointmentComponent,
        SettingsComponent,
        ProfileComponent,
        CustomerEditCrashDietComponent,
        TermComponent,
        BlockComponent,
        NewAppointmentComponent,
        ShowOrderModalComponent,
        ChatsComponent,
        FindTeamByZipComponent
    ],
    imports: [
        CommonModule,
        SharedModule,
        // CalendarModule.forRoot({ provide: DateAdapter, useFactory: adapterFactory }),
        DashboardRoutingModule,
        RouterModule,
        // Ng2FlatpickrModule,
        // GooglePlaceModule
    ],
    exports: [
        SharedModule,
        MenuComponent
        // GooglePlaceModule
    ],
    providers: [],
})

export class DashboardModule {

}