<app-modal-data [title]="title" [text]="text" (closeModal)="closeModalF()">
    <div data class="w-full">
        <div class="grid grid-cols-1">
            <div class="mt-3 mx-4 text-left">
                <app-label-form for="name" name="{{
                    'customer_edit.nutritional_plan.shock.day' |
                    translate }}"></app-label-form>

                <select class="bg-white inputClass" multiple
                    [(ngModel)]="selectedValues">
                    <option hidden selected value="">{{
                        'customer_edit.nutritional_plan.shock.day' | translate
                        }}</option>
                    <option value="{{day.id}}" *ngFor="let day of days">{{day.text | translate}}</option>
                </select>
                <ng-container *ngIf="error">
                    <app-error-form error="{{'errors.required' | translate}}">
                    </app-error-form>
                </ng-container>
            </div>
        </div>
    </div>
    <div button>
        <button type="submit" (click)="onSubmit()"
            class="inline-flex justify-center w-full rounded-md border
            border-transparent px-4 py-2 bg-nutriwell text-base leading-6
            font-medium text-white shadow-sm focus:outline-none
            focus:border-nutriwell focus:ring-nutriwell transition ease-in-out
            duration-150 sm:text-sm sm:leading-5">
            {{text_button_register}}
        </button>
    </div>
</app-modal-data>