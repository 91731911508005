import { HttpClient } from "@angular/common/http";
import { Injectable } from "@angular/core";
import { SettingGeneralService } from "./settings-general.service";


@Injectable({
    providedIn: 'root'
})
export class NutriwellService {
    
    constructor(
        public http: HttpClient,
        public settingGeneralService: SettingGeneralService
    ) { }
}