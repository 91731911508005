import { HttpClient } from '@angular/common/http';
import { Injectable } from '@angular/core';
import * as internal from 'events';
import { Subject } from 'rxjs';
import { map } from 'rxjs/operators';
import { environment } from 'src/environments/environment';

@Injectable({
    providedIn: 'root'
})
export class TeamService {

    private userSource = new Subject<any>();
    public userSource$ = this.userSource.asObservable();

    constructor(
        private http: HttpClient
    ) { }


    /**
     * users
     */
     public listUsersNutriwell(team_id: number) {
        return this.http
            .get(`${environment.apiUrl}/teams/${team_id}/users/in-nutriwell`)
            .pipe(map((response: any) => {
                this.userSource.next(response.data);
                return response.data;
            }));
    }

    public listUsers(team_id: number) {
        return this.http
            .get(`${environment.apiUrl}/teams/${team_id}/users`)
            .pipe(map((response: any) => {
                this.userSource.next(response.data);
                return response.data;
            }));
    }

    public listUsersNotAdmin(team_id: number) {
        return this.http
            .get(`${environment.apiUrl}/teams/${team_id}/users/not-admin`)
            .pipe(map((response: any) => {
                this.userSource.next(response.data);
                return response.data;
            }));
    }

    public getConfigTeam(slug: string)
    {
        return this.http
            .get(`${environment.apiUrl}/external/teams/${slug}`)
            .pipe(map((response: any) => {
                return response;
            }));
    }

    public getTeamIdByZipcodeAndFatherId(team_id: number, zipcode: string)
    {
        return this.http
            .get(`${environment.apiUrl}/external/teams/${team_id}/zipcode/${zipcode}`)
            .pipe(map((response: any) => {
                return response.data;
            }));
    }

    public listFilters(teamId) {
        return this.http.get(`${environment.apiUrl}/teams/filters/${teamId}`).pipe(
            map(res =>  {
                return res['data'];
            })
        );
    }
}