import { Injectable } from "@angular/core";
import { BehaviorSubject } from "rxjs";


@Injectable({
    providedIn: 'root'
})
export class AppService {
    
    private hasChatService = new BehaviorSubject<boolean>(false);
    public hasChatService$ = this.hasChatService.asObservable();

    setHasNewMessage(value: boolean) {
        this.hasChatService.next(value);
    }

    hasNewMessage() {
        return this.hasChatService.getValue();
    }
}