<nav class="border-b border-gray-200">
    <div class="max-w-7xl mx-auto px-4 sm:px-6 lg:px-8">
        <div class="flex justify-between h-16">
            <div class="flex">
                <div class="flex-shrink-0 flex items-center">
                    <a href="{{this.urls[this.slug]}}" target="_blank">
                        <img src="https://naturhouse.es/media/logo/websites/1/Logo.png" class="block h-8
                            w-auto">
                    </a>
                </div>
            </div>
            <div class="-mr-2 flex items-center hidden">

                <button (click)="isOpenMobile= !isOpenMobile"
                    class="inline-flex items-center justify-center p-2 rounded-md text-gray-400
                    hover:text-gray-500 hover:bg-gray-100 focus:outline-none focus:bg-gray-100
                    focus:text-gray-500">

                    <img src="/assets/images/svg/menu.svg" class="block h-6 w-6" [ngClass]="{
                        hidden:!isOpenMobile}" />
                    <img src="/assets/images/svg/x.svg" class="block h-6 w-6" [ngClass]="{
                        hidden:isOpenMobile}" />

                </button>
            </div>
        </div>
    </div>
</nav>