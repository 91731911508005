import { Component, EventEmitter, Input, OnInit, Output } from "@angular/core";

@Component({
    selector: 'app-kbd',
    templateUrl: './kbd.component.html',
})
export class KbdComponent {

    @Input() value: string;
	
}