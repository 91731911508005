import { NgModule } from "@angular/core";
import { GoogleTagManagerModule } from "angular-google-tag-manager";
import { AppointmentsModule } from "./appointments/appointments.module";
import { CustomersModule } from "./customers/customers.module";
import { ExternalRoutingModule } from "./external.routing.module";

@NgModule({
    declarations: [

    ],
    imports: [
        ExternalRoutingModule,
        AppointmentsModule,
        CustomersModule,
        GoogleTagManagerModule.forRoot({
			id: 'GTM-PZPBLCG',
		  })
    ],
    exports: [

    ],
    providers: [
        
    ],
})

export class ExternalModule {

}