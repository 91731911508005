export class Roles {

    public static roles = [
        {
            id: 1,
            name: 'ADMIN',
            permissions: ['admin', 'admin2']
        },
        {
            id: 2,
            name: 'SECRETARY',
            permissions: ['secretary', 'secretary2']
        }
    ];
}