import { trigger, transition, style, animate } from "@angular/animations";
import { Component, OnDestroy } from "@angular/core";
import { ActivatedRoute, ParamMap, Router } from "@angular/router";
import { Subscription } from "rxjs";
import { SettingGeneralService } from "src/app/shared/services/settings-general.service";
import { TeamService } from "src/app/shared/services/team.service";
import { Slug } from "src/app/shared/settings/slug";
import { AppointmentsService } from "../appointments.service";


@Component({
    selector: 'app-appointments-schedule-confirm',
    templateUrl: './appointmentsScheduleConfirm.component.html',
    styleUrls: ['../appointments.component.scss', './appointmentsScheduleConfirm.component.scss'],
    animations: [
        trigger(
            'enterAnimation', [
            transition(':enter', [
                style({ transform: 'translateY(100%)', opacity: 0 }),
                animate('500ms', style({ transform: 'translateY(0)', opacity: 1 }))
            ]),
            transition(':leave', [
                style({ transform: 'translateY(100%)', opacity: 1 }),
                animate('500ms', style({ transform: 'translateY(0)', opacity: 0 }))
            ])
        ]
        )
    ],
})

export class AppointmentsScheduleConfirmComponent implements OnDestroy {
    
    slug: string;
    locale: string;
    srcImage: string = '/assets/images/flor-circle.png';
    showConfirmText: boolean = false;
    
    private subscription = new Subscription();

    paramMap: ParamMap;

    hasData: boolean = false;
    notValidHash: boolean = false;
    hash: string;

    nutricionistName: string;
    nutricionistSlug: string;
    appointmentDate: string;
    appointmentHour: string;
    localeCalendar: string;
    isConfirm:boolean = false;
    param = { nutricionistName: '', date: '', hour:'' };
   
    constructor(
        private route: ActivatedRoute,
        private router: Router,
        private appointmentService: AppointmentsService,
       
        private teamService: TeamService,
        private settingGeneralService: SettingGeneralService
    ) {
        this.paramMap = this.route.snapshot.paramMap;

        const slug = this.slug = this.locale = Slug.normalize(
            (this.router.url).split('/')[2]
        );
        this.localeCalendar = this.locale.includes('-') ? 'es': this.locale;
        
        this.subscription.add(this.teamService.getConfigTeam(slug).subscribe(
            (response) => {
                const settings = response.data;
                
                this.settingGeneralService.generateSettings(settings);
                this.getScheduleConfirmData();
            },
            () => {
                window.open('/citas/es/', "_self");
            }
        ));
    }
    
    getScheduleConfirmData(): void {
        this.hash = this.paramMap.get('hash');

        this.subscription.add(
            this.appointmentService.getScheduleConfirmData(this.hash).subscribe(
                (response) => {
                    this.hasData = true;
                    
                    this.nutricionistSlug = response.nutritionist_slug;
                    this.nutricionistName = response.nutritionist;
                    this.param.nutricionistName = this.nutricionistName;
                    this.param.date = response.date;
                    this.param.hour = response.hour;
                    this.appointmentDate = response.date;
                    this.appointmentHour = response.hour;
                    this.isConfirm = response.is_confirmed;
                },
                () => {
                    this.hasData = true
                    this.notValidHash = true;
                }
            )
        )
    }

    public confirmAppointment()
    {
        this.subscription.add(
            this.appointmentService.confirmAppointment(this.hash).subscribe(
                () => {this.showConfirmText = true; this.isConfirm = true},
                (error) => this.showConfirmText = true
            )
        )
    }

    public rescheduleAppointment()
    {
        let splitUrl = (this.router.url).split('/');
        splitUrl[3] = this.nutricionistSlug;
       
        window.open(splitUrl.join('/'), "_self");
    }

    ngOnDestroy(): void {
        this.subscription.unsubscribe();
    }
}